import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Icon3 from "./img/lock.png";

function ChangePass() {
  const [isLoading, setIsLoading] = useState(false);
  const baseURL = process.env.REACT_APP_BASE_URL;

  const formik = useFormik({
    initialValues: {
      password: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .required("Required password"),
    }),

    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      try {
        const response = await axios.post(
          `${baseURL}/auth/change-password`,  // Update endpoint here if needed
          { password: values.password },
          { headers: { "Content-Type": "application/json" } }
        );

        if (response?.status === 200) { // Ensure the response indicates success
          toast.success("Password changed successfully");
          resetForm();
        } else {
          toast.error("Password change failed. Please try again.");
        }
      } catch (error) {
        toast.error("Password change failed. Please try again.");
      } finally {
        setIsLoading(false);
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="input-group input-group-border mt-3">
        <span className="input-group-text">
          <img src={Icon3} alt="Lock Icon" className="icon-img" />
        </span>
        <input
          type="password"
          className="form-control no-border"
          id="password"
          name="password"
          placeholder="Enter new password"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.password}
          disabled={isLoading}
        />
      </div>
      {formik.touched.password && formik.errors.password ? (
        <div className="error text-danger">{formik.errors.password}</div>
      ) : null}

      <button
        type="submit"
        className="btn btn-connect full-width-btn"
        disabled={isLoading}
      >
        {isLoading ? (
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
        ) : (
          <span>Change Password</span>
        )}
      </button>
    </form>
  );
}

export default ChangePass;
