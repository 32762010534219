/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
// import { useParams } from "react-router-dom";
// import { IoArrowBackSharp } from "react-icons/io5";
import { LuMapPin } from "react-icons/lu";
import { CiHeart } from "react-icons/ci";
import PlayStore from "../../components/PlayStoreBanner/index";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { Button } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import axios from "axios";
import dummylogo from "../../assets/dummylogo.png";
import { toast } from "react-toastify";

function index() {
  const [isActive, setIsActive] = useState(false);
  const location = useLocation();
  const id = location?.state?.id;
  const [job, setJobDetails] = useState([]);
  const baseURLAPI = process.env.REACT_APP_BASE_URL;
  const baseURL = "https://secure-data-upload-new.s3.ap-south-1.amazonaws.com/";
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (id) {
      console.log(id, "about Job");
    }
  }, [id]);

  const jobDetails = async () => {
    try {
      const response = await axios.get(
        `${baseURLAPI}/company-profiles/${id}`
        // `http://98.81.204.247/api/company-profiles/${id}`
      );
      console.log(response?.data?.data, "Job Details");
      setJobDetails(response?.data?.data);
    } catch (error) {
      console.error(error);
    }
  };

  const jobId = job._id;
  const studentId = localStorage.getItem("id");
  const token = localStorage.getItem("authToken");

  const handlewishlist = async () => {
    if (!token) {
      window.location.href = "/studentlogin";
      return;
    }
    try {
      console.log("Wishlist", jobId);
      console.log(studentId, "Student ID");
      const response = await axios.post(`${baseURLAPI}/wishlists`, {
        studentId: studentId,
        jobId: jobId,
      });
      const message = response.data.message;
      if (
        message === "Wishlist can only contain up to 5 company profiles" ||
        message === "Job ID already exists in the wishlist"
      ) {
        toast.error(message);
      } else {
        toast.success("Job added to wishlist");
        setIsActive(true);
      }
      console.log(response, "Job Details");
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const checkIfJobInWishlist = async () => {
      if (!token) return;
      try {
        // Fetch the entire wishlist for the student
        const response = await axios.get(
          `${baseURLAPI}/wishlists/${studentId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // console.log('Job ID passed:', jobId);
        // console.log('Wishlist job IDs:', response.data.map((job) => job._id));
        const jobExists = response.data.some((job) => job._id === jobId);
        console.log("Job exists:", jobExists);
        if (jobExists) {
          setIsActive(true);
        }
      } catch (error) {
        console.error("Error checking wishlist:", error);
        toast.error("Error checking wishlist.");
      }
    };
    checkIfJobInWishlist();
  }, [studentId, jobId, token, baseURLAPI]);

  useEffect(() => {
    jobDetails();
  }, [id]);
  const logoURL =
    job.user && job.user?.logo ? `${baseURL}${job?.user?.logo?.path}` : null;

  console.log(logoURL, "logoURL");
  return (
    <>
      <section key={index} id="aboutjob" className="aboutjob mb-5">
        <div className="card card1">
          <div className="container">
            <div className="card-avatar">
              <div className="avatar">
                <img
                  // src={`${baseURL}${job.user?.logo?.path}`}
                  src={logoURL || dummylogo}
                  alt={job?.user?.companyName?.compname}
                />
              </div>
              {/* <div>
                {" "}
                <p className="about-description-text-sub">{job?.user?.about}</p>
              </div> */}
            </div>
            <div className="about-description">
              <div className="info">
                {/* <h4 className="about-description-text-sub">
                  {job?.user?.companyName?.compname}
                </h4>
                <h6 className="about-description-text-sub">
                  {job?.designation}
                </h6> */}
                <p className="about-description-text-sub">{job?.user?.about}</p>
                {/* <p className="about-description-text-sub">
                  <LuMapPin />
                  <span className="mx-1">{job?.location?.cityName}</span>
                </p> */}
              </div>
              <div className="info-btn">
                {/* <Button className="button2 mb-2">Apply</Button> */}
                <br />
                {/* <Button className="button2" onClick={() => {
                  handlewishlist();
                }}>
                  <CiHeart className="mx-1 icon" /> Wishlist
                </Button> */}
                <Button
                  className={`button2 ${isActive ? "active" : ""}`}
                  onClick={() => handlewishlist()}
                  disabled={isActive}
                >
                  <CiHeart
                    className={`mx-1 icon ${isActive ? "active-icon" : ""}`}
                  />
                  Wishlist
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="card card2">
            {/* <div className="about-comp mb-2">
              <h4>About Company:</h4>
              <p>{job?.about}</p>
            </div> */}
            <div className="about-profile mb-2">
              <h4>Designation:</h4>
              <p>{job?.designation}</p>
            </div>
            <div className="about-eligibility mb-2">
              <h4>Description:</h4>
              <ul>
                {/* {job.qualification.map((item, index) => (
                    <li key={index}>
                      <p>{item.qualification}</p>
                    </li>
                  ))} */}
                <li>
                  <p>{job?.experience}</p>
                </li>
                <li>
                  <p>{job?.qualification}</p>
                </li>
                <li>
                  <p>{job?.emp_type}</p>
                </li>
                <li>
                  <p>{job?.package}</p>
                </li>
              </ul>
            </div>
            <div className="about-location mb-2">
              <h4>Location:</h4>
              <p>
                {" "}
                <LuMapPin /> {job?.location?.cityName}
              </p>
            </div>
          </div>

          <div className="card card3">
            <div>
              <h4>Message from HR</h4>
              <p>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humour, or randomised words which don't look even
                slightly believable.
              </p>
            </div>
          </div>

          <div className="row skills-card">
            <div className="col-md-6 col-sm-12">
              <div className="card tech-card">
                <h6>Primary Skills:</h6>
                {/* <div className="button-container">
                  <Button className="button">{job?.primaryskills}</Button>
                </div> */}
                <div className="button-container">
                  {job?.primary_skill?.length > 0 &&
                    job.primary_skill.map((skill, index) => (
                      <Button key={index} className="button">
                        {skill?.skillname}
                      </Button>
                    ))}
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="card soft-card">
                <h6>Secondary Skill</h6>
                <div className="button-container">
                  {job?.secondary_skill?.length > 0 &&
                    job.secondary_skill.map((skill, index) => (
                      <Button key={index} className="button">
                        {skill?.skillname}
                      </Button>
                    ))}
                  {/* <Button className="button">Communication</Button> */}
                </div>
              </div>
            </div>
          </div>

          <div className="row skills-card">
            <div className="col-md-6 col-sm-12">
              <div className="card tech-card">
                <h6>Tertiary Skills:</h6>
                {/* <div className="button-container">
                  <Button className="button">{job?.primaryskills}</Button>
                </div> */}
                <div className="button-container">
                  {/* {(job?.primary_skill?.length > 0 ||
                    job?.secondary_skill?.length > 0 ||
                    job?.tertiary_skill?.length > 0) &&
                    [
                      ...(job.primary_skill || []),
                      ...(job.secondary_skill || []),
                      ...(job.tertiary_skill || []),
                    ].map((skill, index) => (
                      <Button key={index} className="button">
                        {skill?.skillname}
                      </Button>
                    ))} */}

                  {/* {job?.tertiary_skill?.length > 0 &&
                    job.tertiary_skill.map((skill, index) => (
                      <Button key={index} className="button">
                        {skill?.skillname }
                      </Button>
                    ))} */}

                  {job?.tertiary_skill?.length > 0 ? (
                    job.tertiary_skill.map((skill, index) => (
                      <Button key={index} className="button">
                        {skill?.skillname}
                      </Button>
                    ))
                  ) : (
                    <span>N/A</span>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="card soft-card">
                <h6>Addon Skill:</h6>
                <div className="button-container">
                  {/* {job?.addon_skill?.length > 0 &&
                    job.addon_skill.map((skill, index) => (
                      <Button key={index} className="button">
                        {skill?.skillname}
                      </Button>
                    ))} */}
                  {/* <Button className="button">Communication</Button> */}

                  {job?.addon_skill?.length > 0 ? (
                    job.addon_skill.map((skill, index) => (
                      <Button key={index} className="button">
                        {skill?.skillname}
                      </Button>
                    ))
                  ) : (
                    <span>N/A</span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <PlayStore />
    </>
  );
}

export default index;
