import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { persistStore, persistReducer } from "redux-persist";
import { configureStore, combineReducers } from "@reduxjs/toolkit";
// import all slices files
import userSlice from "./userslice";
import joblistingSlice from "./joblistingslice";
import studentSlice from "./studentslice";

// Create a persist configuration object
const persistConfig = {
  key: "root",
  storage,
};

// Combine all reducers into a root reducer
const rootReducer = combineReducers({
  user: userSlice,
  joblisting: joblistingSlice,
  student: studentSlice,
});

// Wrap the root reducer with persistReducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure the store with the persisted reducer
const store = configureStore({
  reducer: persistedReducer,
});

// Create a persistor
const persistor = persistStore(store);

export { store, persistor };
