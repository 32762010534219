// /* eslint-disable react-hooks/exhaustive-deps */
// import React, { useEffect, useState } from "react";
// import "./index.css";
// import { HashLink } from "react-router-hash-link";
// import img1 from "../../assets/usergrey.png";
// import axios from "axios";
// import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import Loader from "../../components/Loader";
// import { useDispatch } from "react-redux";
// import { saveStudentData } from "../../store/studentslice";
// import { OverlayTrigger, Tooltip } from "react-bootstrap";

// function Studentprofile() {
//   const [activeSection, setActiveSection] = useState("profile-overview");
//   const scrollToSection = (id) => {
//     const element = document.getElementById(id);
//     const offset = 100;
//     if (element) {
//       const top = element.getBoundingClientRect().top + window.scrollY - offset;
//       window.scrollTo({ top, behavior: "smooth" });
//       setActiveSection(id);
//     }
//   };

//   useEffect(() => {
//     const sections = [
//       "profile-overview",
//       "personal-details",
//       "education-details",
//       "skills-details",
//       "certification-details",
//       "socialmedia-details",
//     ];

//     const observer = new IntersectionObserver(
//       (entries) => {
//         entries.forEach((entry) => {
//           if (entry.isIntersecting) {
//             setActiveSection(entry.target.id);
//           }
//         });
//       },
//       {
//         rootMargin: "0px 0px -50% 0px", // Adjust as needed
//         threshold: 0.5,
//       } // Adjust threshold to control when the section is considered "active"
//     );

//     sections.forEach((id) => {
//       const section = document.getElementById(id);
//       if (section) observer.observe(section);
//     });

//     return () => observer.disconnect();
//   }, []);

//   const dispatch = useDispatch();
//   const [file, setFile] = useState(null);
//   const [setFileUrl] = useState(null);
//   const [uploadFile, setUploadFile] = useState([]);

//   const [studentData, setStudentData] = useState([]);
//   console.log(studentData, "Student Data");
//   const [isLoading, setIsLoading] = useState(false);
//   const [setLogoUrl] = useState("");
//   const [setId] = useState("");
//   const [showPersonalForm, setPersonalForm] = useState(false); // Personal Details
//   const [showEducationForm, setEducationForm] = useState(false); // Education Details
//   const [showEditEducationForm, closePersonlFormEdit] = useState(false); // Edit Education Details
//   const [showSkillsForm, setSkillsForm] = useState(false); // Skills Details
//   const [showCertificationForm, setCertificationForm] = useState(false); // Certification Details
//   const [showEditCertificationForm, setCertificationFormEdit] = useState(false); // Edit Certification Details
//   const [showSocialForm, setSocilaForm] = useState(false); // Social Media Details
//   const [showEditSocialForm, setSocialFormEdit] = useState(false); // Edit Social Media Details
//   const [showOverviewForm, setOverviewForm] = useState(false); // Overview Details
//   const [locations, setLocations] = useState([]);
//   const [skillsmain, setSkillsMain] = useState([]);
//   // const [educationDetails, setEducationDetails] = useState([]);
//   // const [edductId, setEductId] = useState("");
//   // console.log(edductId, "Education IdMain");

//   const [formData, setFormData] = useState({
//     dob: "",
//     gender: "",
//     location: "",
//     address: "",
//     profilePicture: "" || null,
//     profile_overview: "",
//     skills: [],
//     selectedSkill: "",
//     course: "",
//     education: "",
//     end_date: "" || null,
//     grading_system: "",
//     institute: "",
//     marks: "",
//     specialization: "",
//     start_date: "",
//     eduId: "",
//     attachment: "" || null,
//     smId: "",
//     name: "",
//     url: "",
//     description: "",
//     provider: "",
//     cID: "",
//     expiry: false,
//     cId: "",
//     id: localStorage.getItem("id") || "",
//     password: "",
//   });

//   // const validateName = (e) => {
//   //   const { id, value } = e.target;
//   //   const regex = /^[A-Za-z\s]+$/; // Allows letters and spaces only

//   //   if (!regex.test(value)) {
//   //     setFormErrors((prevErrors) => ({
//   //       ...prevErrors,
//   //       [id]: `${id.replace(/^\w/, (c) =>
//   //         c.toUpperCase()
//   //       )} should not contain numbers or special characters`,
//   //     }));
//   //   } else {
//   //     setFormErrors((prevErrors) => ({ ...prevErrors, [id]: "" }));
//   //   }
//   // };
//   const [formErrors, setFormErrors] = useState({
//     firstName: "",
//     lastName: "",
//     gender: "",
//     address: "",
//     location: "",
//     dob: "",
//   });

//   const validateName = (e) => {
//     const { id, value } = e.target;
//     const regex = /^[A-Za-z\s]+$/; // Allows letters and spaces only

//     // Validation for empty fields
//     if (value.trim() === "") {
//       setFormErrors((prevErrors) => ({
//         ...prevErrors,
//         [id]: `${id.replace(/^\w/, (c) => c.toUpperCase())} cannot be empty`,
//       }));
//     }
//     // Validation for name fields (firstName, lastName)
//     else if (id === "firstName" || id === "lastName") {
//       if (!regex.test(value)) {
//         setFormErrors((prevErrors) => ({
//           ...prevErrors,
//           [id]: `${id.replace(/^\w/, (c) =>
//             c.toUpperCase()
//           )} should not contain numbers or special characters`,
//         }));
//       } else {
//         setFormErrors((prevErrors) => ({ ...prevErrors, [id]: "" }));
//       }
//     }
//     // Validation for gender (assuming options 'Male', 'Female', etc.)
//     else if (id === "gender" && value === "") {
//       setFormErrors((prevErrors) => ({
//         ...prevErrors,
//         [id]: `${id.replace(/^\w/, (c) => c.toUpperCase())} is required`,
//       }));
//     }
//     // Validation for address and location
//     else if ((id === "address" || id === "location") && value.trim() === "") {
//       setFormErrors((prevErrors) => ({
//         ...prevErrors,
//         [id]: `${id.replace(/^\w/, (c) => c.toUpperCase())} is required`,
//       }));
//     }
//     // Validation for date of birth (checking if it's not empty)
//     else if (id === "dob" && value === "") {
//       setFormErrors((prevErrors) => ({
//         ...prevErrors,
//         [id]: `${id.replace(/^\w/, (c) => c.toUpperCase())} is required`,
//       }));
//     }
//     // If valid, clear the error message
//     else {
//       setFormErrors((prevErrors) => ({ ...prevErrors, [id]: "" }));
//     }
//   };

//   const validateForm = () => {
//     let errors = {};

//     // Check for password required
//     if (!formData.password) {
//       errors.password = "Password is required";
//     } else if (formData.password.length < 8) {
//       errors.password = "Password must be at least 8 characters";
//     } else if (formData.password.length > 8) {
//       errors.password = "Password must be exactly 8 characters";
//     } else if (!/^[A-Za-z0-9]+$/.test(formData.password)) {
//       errors.password = "Password should contain only letters and numbers";
//     }

//     // Check if passwords match
//     if (formData.password !== formData.confirmPassword) {
//       errors.confirmPassword = "Passwords do not match";
//     }

//     // Update form errors
//     setFormErrors(errors);

//     // Return true if no errors, otherwise false
//     return Object.keys(errors).length === 0;
//   };

//   //  skills search
//   // const [searchTerm, setSearchTerm] = useState("");
//   // const [filteredSkills, setFilteredSkills] = useState(skillsmain);

//   // useEffect(() => {
//   //   setFilteredSkills(skillsmain);
//   // }, [skillsmain]);

//   // const handleSearchChange = (e) => {
//   //   const value = e.target.value;
//   //   setSearchTerm(value);
//   //   setFilteredSkills(
//   //     skillsmain.filter((skill) =>
//   //       skill.skillname.toLowerCase().includes(value.toLowerCase())
//   //     )
//   //   );
//   // };

//   // const handleSearchChange = (e) => {
//   //   const value = e.target.value;
//   //   setSearchTerm(value);
//   //   setFilteredSkills(
//   //     skillsmain.filter((skill) =>
//   //       skill.skillname.toLowerCase().includes(value.toLowerCase())
//   //     )
//   //   );
//   // };

//   // Overview Details
//   const openOverviewForm = () => setOverviewForm(true);
//   const closeOverviewForm = () => {
//     setOverviewForm(false); // Close the form
//     // setFormData((prevData) => ({
//     //   ...prevData,
//     //   profile_overview: "", // Reset the profile overview if needed
//     // }));
//     setError(""); // Reset validation error
//   };

//   // Personal Details
//   const openPersonlForm = () => {
//     setFormData({
//       firstName: studentData?.data?.firstName,
//       lastName: studentData?.data?.lastName,
//       dob: studentData?.data?.dob,
//       gender: studentData?.data?.gender,
//       location: studentData?.data?.location?._id,
//       address: studentData?.data?.address,
//     });
//     setPersonalForm(true);
//   };

//   // const openPersonlForm = () => {
//   //   if (studentData?.data) {
//   //     const {
//   //       profilePicture,
//   //       firstName = "",
//   //       lastName = "",
//   //       dob = "",
//   //       gender = "",
//   //       location = {},
//   //       address = "",
//   //     } = studentData.data;

//   //     setFormData((prevFormData) => ({
//   //       ...prevFormData, // Retain existing form data if already set
//   //       profilePicture: profilePicture?.path
//   //         ? `${baseUrl}${profilePicture.path}`
//   //         : prevFormData.profilePicture || img1, // Use existing or default
//   //       firstName: firstName || prevFormData.firstName,
//   //       lastName: lastName || prevFormData.lastName,
//   //       dob: dob || prevFormData.dob,
//   //       gender: gender || prevFormData.gender,
//   //       location: location?._id || prevFormData.location,
//   //       address: address || prevFormData.address,
//   //     }));
//   //   }
//   //   setPersonalForm(true);
//   // };

//   const closePersonlForm = () => {
//     setFormData((prevState) => ({
//       ...prevState,
//       password: "",
//       confirmPassword: "",
//     }));
//     setImage(null);
//     setFormErrors({});
//     setPersonalForm(false);
//   };

//   // Education Details
//   const openPersonlForm1 = () => {
//     setFile(null);
//     setUploadFile(null);
//     setFormData({
//       education: "",
//       institute: "",
//       course: "",
//       specialization: "",
//       start_date: "",
//       end_date: "",
//       grading_system: "",
//       marks: "",
//       attachment: null,
//     });
//     setEducationForm(true);
//   };
//   // const closePersonlForm1 = () => setEducationForm(false);
//   // const closePersonlForm1 = () => {
//   //   // Locate the form element
//   //   const form = document.querySelector("form");
//   //   if (form) {
//   //     form.reset();
//   //   }
//   //   setEducationForm(false);
//   // };
//   const closePersonlForm1 = () => {
//     setFile(null);
//     setUploadFile(null);
//     setFormData({
//       course: "",
//       education: "",
//       end_date: "",
//       grading_system: "",
//       institute: "",
//       marks: "",
//       specialization: "",
//       start_date: "",
//       attachment: null,
//     });
//     // Clear validation errors
//     setErrors({});
//     // Close the modal
//     setEducationForm(false);
//   };

//   // Edit Education Details
//   const openPersonlFormEdit = () => closePersonlFormEdit(true);
//   const closePersonFormEdit = () => closePersonlFormEdit(false);
//   const handleOpenEducationForm = async (eductId) => {
//     await fetchEducationDetails(eductId);
//     openPersonlFormEdit(true);
//   };

//   // Skills Details
//   const openPersonlForm2 = () => setSkillsForm(true);
//   const closePersonlForm2 = () => {
//     setSkillsForm(false); // Close the form
//     setFormData((prevData) => ({
//       ...prevData,
//       selectedSkill: "", // Reset selected skill
//     }));
//     setError(""); // Reset validation error
//   };

//   // Certification Details
//   const openPersonlForm3 = () => {
//     setFile(null);
//     setUploadFile(null);
//     setFormData({
//       name: "",
//       url: "",
//       provider: "",
//       cID: "",
//       start_date: "",
//       end_date: "",
//       expiry: "",
//       attachment: null,
//     });
//     setErrors({});
//     setCertificationForm(true);
//   };
//   const closePersonlForm3 = () => {
//     setFile(null);
//     setUploadFile(null);
//     setFormData({
//       name: "",
//       url: "",
//       provider: "",
//       cID: "",
//       start_date: "",
//       end_date: "",
//       expiry: "",
//       attachment: null,
//     });
//     setErrors({});
//     setCertificationForm(false);
//   };

//   // Edit Certification Details
//   const openCertificateEdit = () => setCertificationFormEdit(true);
//   const closeCertificateEdit = () => setCertificationFormEdit(false);
//   const handleCertificateFormEdit = async (certiId) => {
//     await fetchCertificateDetails(certiId);
//     openCertificateEdit(true);
//   };

//   // Social Details
//   const openPersonlForm4 = () => {
//     setFormData({
//       name: "",
//       url: "",
//       description: "",
//     });
//     setErrors({});
//     setSocilaForm(true);
//   };
//   const closePersonlForm4 = () => {
//     setFormData({
//       name: "",
//       url: "",
//       description: "",
//     });
//     setErrors({});
//     setSocilaForm(false);
//   };

//   // Edit Social Details
//   const openSocialFormEdit = () => setSocialFormEdit(true);
//   const closeSocialFormEdit = () => setSocialFormEdit(false);
//   const handleSocialFormEdit = async (socId) => {
//     await fetchSocialDetails(socId);
//     openSocialFormEdit(true);
//   };

//   const [errors, setErrors] = useState({});

//   //Get Education Details
//   const fetchEducationDetails = async (eductId) => {
//     const id = localStorage.getItem("id");
//     const token = localStorage.getItem("authToken");
//     console.log(eductId, "Education Id");
//     try {
//       const response = await axios.get(
//         `${process.env.REACT_APP_BASE_URL}/students/edu-details/${id}/${eductId}`,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//             "Content-Type": "application/json",
//           },
//         }
//       );
//       const data = response?.data?.data;
//       console.log("Education Details:", data);
//       setFormData((prevFormData) => ({
//         ...prevFormData,
//         eduId: data._id,
//         course: data.course,
//         education: data.education,
//         end_date: data.end_date,
//         grading_system: data.grading_system,
//         institute: data.institute,
//         marks: data.marks,
//         specialization: data.specialization,
//         start_date: data.start_date,
//         attachment: data?.attachment?.path
//           ? {
//               path: `${baseUrl}${data.attachment.path}`,
//               _id: data.attachment._id,
//             }
//           : null,
//       }));
//       console.log("Education Details:", response?.data?.data);
//     } catch (error) {
//       console.error("Error fetching education details:", error);
//     }
//   };
//   useEffect(() => {
//     fetchEducationDetails();
//   }, []);

//   //Get Social Links Details
//   const fetchSocialDetails = async (socId) => {
//     const id = localStorage.getItem("id");
//     const token = localStorage.getItem("authToken");
//     console.log(socId, "Education Id");
//     try {
//       const response = await axios.get(
//         `${process.env.REACT_APP_BASE_URL}/students/soc-media/${id}/${socId}`,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//             "Content-Type": "application/json",
//           },
//         }
//       );
//       const data = response?.data?.data;
//       console.log("Social links Details:", data);
//       setFormData((prevFormData) => ({
//         ...prevFormData,
//         smId: data._id,
//         name: data.name,
//         url: data.url,
//         description: data.description,
//       }));
//       console.log("Social links Details:", response?.data?.data);
//     } catch (error) {
//       console.error("Error social links details:", error);
//     }
//   };
//   useEffect(() => {
//     fetchSocialDetails();
//   }, []);

//   // Get Certifications Details
//   const fetchCertificateDetails = async (certiId) => {
//     const id = localStorage.getItem("id");
//     const token = localStorage.getItem("authToken");
//     console.log(certiId, "Education Id");
//     try {
//       const response = await axios.get(
//         `${process.env.REACT_APP_BASE_URL}/students/certification/${id}/${certiId}`,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//             "Content-Type": "application/json",
//           },
//         }
//       );
//       const data = response?.data?.data;
//       console.log("Certification Details:", data);
//       setFormData((prevFormData) => ({
//         ...prevFormData,
//         cId: data._id,
//         name: data.name,
//         provider: data.provider,
//         cID: data.cID,
//         url: data.url,
//         start_date: data.start_date,
//         end_date: data.end_date,
//         expiry: data.expiry,
//         attachment: data?.attachment?.path
//           ? {
//               path: `${baseUrl}${data.attachment.path}`,
//               _id: data.attachment._id,
//             }
//           : null,
//       }));
//       console.log("Social links Details:", response?.data?.data);
//     } catch (error) {
//       console.error("Error social links details:", error);
//     }
//   };
//   useEffect(() => {
//     fetchCertificateDetails();
//   }, []);

//   // Student Details Api
//   // useEffect(() => {
//   //   const fetchData = async () => {
//   //     const id = localStorage.getItem("id");
//   //     console.log(id, "Student Id");
//   //     setLoading(true);
//   //     try {
//   //       const response = await axios.get(
//   //         `${process.env.REACT_APP_BASE_URL}/students/std/${id}`,
//   //         {
//   //           headers: {
//   //             Authorization: `Bearer ${localStorage.getItem("authToken")}`,
//   //           },
//   //         }
//   //       );
//   //       console.log("Student details:", response?.data);
//   //       setStudentData(response?.data);
//   //       const locationId = response?.data?.data?.location?._id;
//   //       const genderName = response?.data?.data?.gender;
//   //       const birthDate = response?.data?.data?.dob;
//   //       const addressName = response?.data?.data?.address;
//   //       const profilePictureStu = response?.data?.data?.profilePicture?._id;
//   //       const profileOverview = response?.data?.data?.profile_overview;
//   //       const skillsData = response?.data?.data?.skills;
//   //       // console.log(profilePictureStu, "Profile Picture");

//   //       setFormData((prevFormData) => ({
//   //         ...prevFormData,
//   //         location: locationId || "",
//   //         gender: genderName || "",
//   //         dob: birthDate || "",
//   //         address: addressName || "",
//   //         profilePicture: profilePictureStu || "",
//   //         profile_overview: profileOverview || "",
//   //         skills: skillsData || [],
//   //         education: studentData?.education || "",
//   //         institute: studentData?.institute || "",
//   //         course: studentData?.course || "",
//   //         specialization: studentData?.specialization || "",
//   //         start_date: studentData?.start_date || "",
//   //         end_date: studentData?.end_date || "",
//   //         grading_system: studentData?.grading_system || "",
//   //         marks: studentData?.marks || "",
//   //       }));
//   //     } catch (error) {
//   //       console.error("Failed to fetch student details:", error.message);
//   //     } finally {
//   //       setLoading(false);
//   //     }
//   //   };

//   //   fetchData();
//   // }, []);

//   // useEffect(() => {
//   //   const fetchData = async () => {
//   //     const id = localStorage.getItem("id");
//   //     console.log(id, "Student Id");
//   //     setLoading(true);
//   //     try {
//   //       const response = await axios.get(
//   //         `${process.env.REACT_APP_BASE_URL}/students/std/${id}`,
//   //         {
//   //           headers: {
//   //             Authorization: `Bearer ${localStorage.getItem("authToken")}`,
//   //           },
//   //         }
//   //       );
//   //       console.log("Student details:", response?.data);
//   //       const studentDetails = response?.data?.data || {};
//   //       setStudentData(response?.data);

//   //       setFormData((prevFormData) => ({
//   //         ...prevFormData,
//   //         location: studentDetails.location?._id || "",
//   //         gender: studentDetails.gender || "",
//   //         dob: studentDetails.dob || "",
//   //         address: studentDetails.address || "",
//   //         profilePicture: studentDetails.profilePicture?._id || "",
//   //         profile_overview: studentDetails.profile_overview || "",
//   //         skills: studentDetails.skills || [],
//   //         education: studentDetails.education || "",
//   //         institute: studentDetails.institute || "",
//   //         course: studentDetails.course || "",
//   //         specialization: studentDetails.specialization || "",
//   //         start_date: studentDetails.start_date || "",
//   //         end_date: studentDetails.end_date || "",
//   //         grading_system: studentDetails.grading_system || "",
//   //         marks: studentDetails.marks || "",
//   //       }));
//   //     } catch (error) {
//   //       console.error("Failed to fetch student details:", error.message);
//   //     } finally {
//   //       setLoading(false);
//   //     }
//   //   };

//   //   fetchData();
//   // }, []);

//   const fetchData = async () => {
//     const id = localStorage.getItem("id");
//     const token = localStorage.getItem("authToken");
//     console.log(id, "Student Id");
//     setLoading(true);
//     try {
//       const response = await axios.get(
//         `${process.env.REACT_APP_BASE_URL}/students/std/${id}`,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//             "Content-Type": "application/json",
//           },
//         }
//       );
//       console.log("Student details:", response?.data);
//       const studentDetails = response?.data?.data || {};
//       dispatch(saveStudentData(studentDetails));
//       setStudentData(response?.data);
//       setFormData((prevFormData) => ({
//         ...prevFormData,
//         firstName: studentDetails.firstName || "",
//         lastName: studentDetails.lastName || "",
//         location: studentDetails.location?._id || "",
//         gender: studentDetails.gender || "",
//         dob: studentDetails.dob || "",
//         address: studentDetails.address || "",
//         profilePicture: studentDetails.profilePicture?._id || "",
//         profile_overview: studentDetails.profile_overview || "",
//         skills: studentDetails.skills || [],
//         // education: studentDetails.education || "",
//         // institute: studentDetails.institute || "",
//         // course: studentDetails.course || "",
//         // specialization: studentDetails.specialization || "",
//         // start_date: studentDetails.start_date || "",
//         // end_date: studentDetails.end_date || "",
//         // grading_system: studentDetails.grading_system || "",
//         // marks: studentDetails.marks || "",
//       }));
//     } catch (error) {
//       console.error("Failed to fetch student details:", error.message);
//     } finally {
//       setLoading(false);
//     }
//   };
//   useEffect(() => {
//     fetchData();
//   }, []);

//   // Profile Img Upload
//   const [image, setImage] = useState(null);

//   const [loading, setLoading] = useState(false);
//   const [loadingpass, setLoadingPass] = useState(false);

//   //Image Upload
//   const handleImageUpload = (event) => {
//     const file = event.target.files[0];
//     if (file) {
//       if (file.size > 2 * 1024 * 1024) {
//         alert("File size should be less than 2 MB");
//       } else {
//         const imageUrl = window.URL.createObjectURL(file);
//         setImage(imageUrl);
//         const formData = new FormData();
//         formData.append("file", file);

//         const baseURL = process.env.REACT_APP_BASE_URL;
//         const token = localStorage.getItem("authToken");
//         console.log("studentToken", token);

//         if (!token) {
//           alert("Authentication token is missing. Please log in again.");
//           return;
//         }

//         const config = {
//           method: "post",
//           maxBodyLength: Infinity,
//           url: `${baseURL}/media`,
//           headers: {
//             Authorization: `Bearer ${token}`,
//             "Content-Type": "multipart/form-data",
//           },
//           data: formData,
//         };

//         console.log("Request Config:", config);

//         axios
//           .request(config)
//           .then((response) => {
//             console.log("Image uploaded successfully:", response?.data?.data);
//             const logoUrl = response?.data?.data?._id;
//             setFormData((prevValues) => ({
//               ...prevValues,
//               profilePicture: logoUrl,
//             }));
//           })
//           .catch((error) => {
//             console.error(
//               "Failed to upload image:",
//               error.response.data.message
//             );
//             console.error("Error Response:", error.response);
//           });
//       }
//     }
//   };
//   useEffect(() => {
//     const baseUrl =
//       "https://secure-data-upload-new.s3.ap-south-1.amazonaws.com/";
//     const data = localStorage.getItem("id");
//     if (data) {
//       try {
//         const parsedData = JSON.parse(data);
//         const id = parsedData?.data?.user?._id || "";
//         setId(id);
//         const pathlogoUrl = parsedData?.data?.user?.logo?.path || "";
//         const fullLogoUrl = `${baseUrl}${pathlogoUrl}`;
//         setLogoUrl(fullLogoUrl);
//         console.log("Full logo URL:", fullLogoUrl);
//       } catch (error) {
//         console.error("Failed to parse JSON data:", error.message);
//       }
//     } else {
//       console.error("No userResponse found in localStorage");
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, []);

//   //File Upload
//   const handleFileUpload = (event) => {
//     setIsLoading(true);
//     const file = event.target.files[0];
//     if (file) {
//       if (file.size > 2 * 1024 * 1024) {
//         // 2 MB in bytes
//         alert("File size must be less than 2 MB.");
//         setIsLoading(false); // Stop loading
//         return;
//       }
//       const fileUrl = window.URL.createObjectURL(file);
//       setFile(fileUrl);
//       const formData = new FormData();
//       formData.append("file", file);

//       const baseURL = process.env.REACT_APP_BASE_URL;
//       const token = localStorage.getItem("authToken");
//       console.log("studentToken", token);

//       if (!token) {
//         alert("Authentication token is missing. Please log in again.");
//         return;
//       }

//       const config = {
//         method: "post",
//         maxBodyLength: Infinity,
//         url: `${baseURL}/media`,
//         headers: {
//           Authorization: `Bearer ${token}`,
//           "Content-Type": "multipart/form-data",
//         },
//         data: formData,
//       };

//       console.log("Request Config:", config);

//       axios
//         .request(config)
//         .then((response) => {
//           console.log("File uploaded successfully:", response?.data?.data);
//           setUploadFile(response?.data?.data);
//           const fileUrl = response?.data?.data?._id;
//           setFormData((prevValues) => ({
//             ...prevValues,
//             attachment: fileUrl,
//           }));
//           setIsLoading(false);
//         })
//         .catch((error) => {
//           console.error("Failed to upload file:", error.response.data.message);
//           console.error("Error Response:", error.response);
//         });
//     }
//   };

//   const handleFileRemove = () => {
//     setFile(null);
//     setFileUrl(null);
//   };

//   useEffect(() => {
//     const baseUrl =
//       "https://secure-data-upload-new.s3.ap-south-1.amazonaws.com/";
//     const data = localStorage.getItem("id");
//     if (data) {
//       try {
//         const parsedData = JSON.parse(data);
//         const id = parsedData?.data?.user?._id || "";
//         setId(id);
//         const pathFileUrl = parsedData?.data?.user?.file?.path || "";
//         const fullFileUrl = `${baseUrl}${pathFileUrl}`;
//         setFileUrl(fullFileUrl);
//         console.log("Full file URL:", fullFileUrl);
//       } catch (error) {
//         console.error("Failed to parse JSON data:", error.message);
//       }
//     } else {
//       console.error("No userResponse found in localStorage");
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, []);

//   const triggerFileInput = () => {
//     document.getElementById("hiddenFileInput").click();
//   };

//   // Handle Submit Form For Personal Details
//   const handleSubmit = (event) => {
//     event.preventDefault();
//     const validate = () => {
//       const newErrors = {};
//       if (!formData.location) newErrors.location = "location is required";
//       return newErrors;
//     };
//     const validationErrors = validate();
//     if (Object.keys(validationErrors).length > 0) {
//       setErrors(validationErrors);
//       return;
//     }
//     setLoading(true);
//     console.log("Form Data:", formData);

//     const baseURL = process.env.REACT_APP_BASE_URL;
//     const token = localStorage.getItem("authToken");
//     const id = localStorage.getItem("id");

//     const {
//       address,
//       dob,
//       gender,
//       location,
//       profilePicture,
//       firstName,
//       lastName,
//     } = formData;
//     const filteredData = {
//       address,
//       dob,
//       gender,
//       location,
//       profilePicture: profilePicture || null,
//       firstName,
//       lastName,
//     };

//     const config = {
//       method: "put",
//       url: `${baseURL}/students/${id}`,
//       headers: {
//         Authorization: `Bearer ${token}`,
//         "Content-Type": "application/json",
//       },
//       data: filteredData,
//     };

//     console.log("Request Config Update:", config);

//     axios
//       .request(config)
//       .then((response) => {
//         toast.success("Personal details updated successfully");
//         // console.log("Personal details updated:", response?.data);
//         setPersonalForm(false);
//         setLoading(false);
//         fetchData();
//       })
//       .catch((error) => {
//         toast.error("Failed to personal details updated");
//         // console.error("Failed to personal details updated:", error.message);
//         if (error.response) {
//           console.error("Error Response Data:", error.response.data);
//           console.error("Error Response Status:", error.response.status);
//           console.error("Error Response Headers:", error.response.headers);
//         }
//         setLoading(false);
//       });
//   };

//   // Handle Submit Form For Profile Overview Details
//   const handleSubmitOver = async (event) => {
//     event.preventDefault();
//     setLoading(true);
//     console.log("Form Data:", formData);
//     const { profile_overview } = formData;

//     if (!profile_overview || profile_overview.trim() === "") {
//       setError("Profile Overview is required."); // Set error message
//       setLoading(false);
//       return; // Prevent form submission
//     }
//     setError("");

//     const baseURL = process.env.REACT_APP_BASE_URL;
//     const token = localStorage.getItem("authToken");
//     const id = localStorage.getItem("id");

//     const filteredData = { profile_overview };

//     const config = {
//       method: "put",
//       url: `${baseURL}/students/${id}`,
//       headers: {
//         Authorization: `Bearer ${token}`,
//         "Content-Type": "application/json",
//       },
//       data: filteredData,
//     };

//     console.log("Request Config Update:", config);

//     try {
//       const response = await axios.request(config);
//       if (response.status === 200) {
//         toast.success("Profile Overview successfully");
//         // console.log("Profile updated successfully:", response.data);
//         // Update student data with profile_overview
//         setStudentData((prevData) => ({
//           ...prevData,
//           data: {
//             ...prevData.data,
//             profile_overview: profile_overview,
//           },
//         }));
//         setOverviewForm(false);
//       }
//     } catch (error) {
//       toast.error("Failed to profile updated");
//       // console.error("Failed to profile updated:", error.message);
//       if (error.response) {
//         console.error("Error Response Data:", error.response.data);
//         console.error("Error Response Status:", error.response.status);
//         console.error("Error Response Headers:", error.response.headers);
//       }
//     } finally {
//       setOverviewForm(false);
//       setLoading(false);
//     }
//   };

//   // Get Location All API
//   useEffect(() => {
//     const baseURL = process.env.REACT_APP_BASE_URL;
//     // const token = localStorage.getItem("authToken");
//     const fetchLocations = async () => {
//       try {
//         const response = await axios.get(`${baseURL}/cities/getCity`);
//         setLocations(response?.data?.data);
//         // console.log("Locations:", response?.data?.data);
//       } catch (error) {
//         console.error("Error fetching locations:", error);
//       }
//     };

//     fetchLocations();
//   }, []);

//   // Get Skill Name
//   useEffect(() => {
//     const baseURL = process.env.REACT_APP_BASE_URL;
//     const fetchSkills = async () => {
//       try {
//         const response = await axios.get(`${baseURL}/skills-sets/getskills`);
//         setSkillsMain(response?.data?.data);
//         // console.log("skills:", response?.data?.data);
//       } catch (error) {
//         console.error("Error fetching skills:", error);
//       }
//     };

//     fetchSkills();
//   }, []);

//   const [error, setError] = useState("");

//   // Handle Input skill selection
//   // const handleInputChange1 = (e) => {
//   //   const { id, value } = e.target;
//   //   setFormData((prevData) => {
//   //     if (id === "skills") {
//   //       const newSkills = Array.isArray(prevData.skills)
//   //         ? [...prevData.skills]
//   //         : [];
//   //       if (newSkills.includes(value)) {
//   //         // Remove the skill if it's already selected
//   //         return {
//   //           ...prevData,
//   //           skills: newSkills.filter((skill) => skill !== value),
//   //         };
//   //       } else {
//   //         // Add the skill if it's not selected
//   //         return {
//   //           ...prevData,
//   //           skills: [...newSkills, value],
//   //         };
//   //       }
//   //     } else {
//   //       return {
//   //         ...prevData,
//   //         [id]: value,
//   //       };
//   //     }
//   //   });
//   // };

//   // const handleInputChange1 = (e) => {
//   //   const { id, value } = e.target;
//   //   setFormData((prevData) => {
//   //     if (id === "skills") {
//   //       return {
//   //         ...prevData,
//   //         selectedSkill: value,
//   //       };
//   //     } else {
//   //       return {
//   //         ...prevData,
//   //         [id]: value,
//   //       };
//   //     }
//   //   });
//   // };

//   const handleInputChange1 = (e) => {
//     const { id, value, type, checked } = e.target;

//     setFormData((prevData) => {
//       // Handle "skills" separately
//       if (id === "skills") {
//         return {
//           ...prevData,
//           selectedSkill: value,
//         };
//       }

//       // Handle checkbox for "expiry"
//       if (id === "expiry") {
//         return {
//           ...prevData,
//           expiry: checked, // true if checked, false if unchecked
//           end_date: checked ? "" : prevData.end_date, // Clear end_date if expiry is checked
//         };
//       }

//       // Handle other input fields
//       return {
//         ...prevData,
//         [id]: value,
//       };
//     });
//   };

//   // Handle Add Skill
//   const handleSubmitSkill = (event) => {
//     event.preventDefault();
//     const { selectedSkill, skills } = formData;

//     if (!selectedSkill) {
//       setError("Please select at least one skill.");
//       return;
//     } else {
//       setError("");
//     }

//     const updatedSkills = [...skills];
//     if (!updatedSkills.includes(selectedSkill)) {
//       updatedSkills.push(selectedSkill); // Add the selected skill to the array
//     }

//     setFormData((prevData) => ({
//       ...prevData,
//       skills: updatedSkills, // Update the skills array
//       selectedSkill: "", // Reset selected skill
//     }));

//     setLoading(true);
//     console.log("Form Data:", formData);

//     const baseURL = process.env.REACT_APP_BASE_URL;
//     const token = localStorage.getItem("authToken");
//     const id = localStorage.getItem("id");

//     const filteredData = { skills: updatedSkills };
//     setError("");

//     const config = {
//       method: "put",
//       url: `${baseURL}/students/${id}`,
//       headers: {
//         Authorization: `Bearer ${token}`,
//         "Content-Type": "application/json",
//       },
//       data: filteredData,
//     };
//     // console.log("Request Config Update:", config);
//     axios
//       .request(config)
//       .then((response) => {
//         toast.success("Skill added successfully");
//         // console.log("FoSkillsrm added successfully:", response.data);
//         setLoading(false);
//         setSkillsForm(false);
//         setFormData((prevData) => ({
//           ...prevData,
//           skills: [],
//           selectedSkill: "",
//         }));
//         fetchData();
//       })
//       .catch((error) => {
//         toast.error("Unable to add the skill. Please try again");
//         // console.error("Unable to delete the skill. Please try again", error.message);
//         if (error.response) {
//           console.error("Error Response Data:", error.response.data);
//           console.error("Error Response Status:", error.response.status);
//           console.error("Error Response Headers:", error.response.headers);
//         }
//         setLoading(false);
//       });
//   };

//   //Handle Delete Skill
//   const handleDeleteSkill = async (skillId) => {
//     const baseURL = process.env.REACT_APP_BASE_URL;
//     const id = localStorage.getItem("id");
//     const token = localStorage.getItem("authToken");
//     // console.log(`Deleting skill with ID: ${skillId}`);
//     try {
//       const response = await axios.put(`${baseURL}/students/${id}/${skillId}`, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//           "Content-Type": "application/json",
//         },
//       });
//       if (response.status === 200) {
//         toast.success("Skill deleted successfully");
//         setStudentData((prevData) => ({
//           ...prevData,
//           data: {
//             ...prevData.data,
//             skills: prevData.data.skills.filter(
//               (skill) => skill._id !== skillId
//             ),
//           },
//         }));
//       }
//     } catch (error) {
//       // console.error('Error deleting skill:', error);
//       toast.error("Unable to delete the skill.Please try again.");
//     }
//   };

//   //Handle Add Education
//   const handleSubmit1 = (event) => {
//     event.preventDefault();

//     const validate = () => {
//       const newErrors = {};
//       if (!formData.education) newErrors.education = "Education is required";
//       if (!formData.institute) newErrors.institute = "Institute is required";
//       if (!formData.course) newErrors.course = "Course is required";
//       if (!formData.specialization)
//         newErrors.specialization = "Specialization is required";
//       // if (!formData.start_date) {
//       //   newErrors.start_date = "Start date is required";
//       // } else if (formData.end_date && new Date(formData.start_date) >= new Date(formData.end_date)) {
//       //   newErrors.start_date = "Start date should be earlier than end date";
//       // }
//       // if (!formData.end_date) newErrors.end_date = "End date is required";
//       if (!formData.start_date) {
//         newErrors.start_date = "Please select a start date.";
//       } else if (!formData.end_date) {
//         newErrors.end_date = "Please select an end date.";
//       } else if (new Date(formData.start_date) > new Date(formData.end_date)) {
//         newErrors.start_date = "Start date should be less than the end date.";
//         newErrors.end_date = "End date should be greater than the start date.";
//       }
//       if (!formData.grading_system)
//         newErrors.grading_system = "Grading system is required";
//       if (!formData.marks) newErrors.marks = "Marks are required";
//       return newErrors;
//     };

//     const validationErrors = validate();
//     if (Object.keys(validationErrors).length > 0) {
//       setErrors(validationErrors);
//       return;
//     }
//     setLoading(true);
//     console.log("Form Data:", formData);

//     const baseURL = process.env.REACT_APP_BASE_URL;
//     const token = localStorage.getItem("authToken");
//     const id = localStorage.getItem("id");

//     if (!baseURL || !token || !id) {
//       console.error("Missing required configuration values");
//       return;
//     }
//     const {
//       course,
//       education,
//       end_date,
//       start_date,
//       marks,
//       specialization,
//       grading_system,
//       attachment,
//       institute,
//     } = formData;
//     const filteredData = {
//       course,
//       education,
//       end_date,
//       start_date,
//       marks,
//       specialization,
//       grading_system,
//       attachment: attachment || null,
//       institute,
//     };
//     filteredData.id = id;
//     const config = {
//       method: "post",
//       url: `${baseURL}/students/edu-details`,
//       headers: {
//         Authorization: `Bearer ${token}`,
//         "Content-Type": "application/json",
//       },
//       data: filteredData,
//     };

//     console.log("Request Config Update:", config);

//     axios
//       .request(config)
//       .then((response) => {
//         toast.success("Education added successfully");
//         // console.log("Education added successfully:", response.data);
//         setLoading(false);
//         setEducationForm(false);
//         fetchEducationDetails();
//         fetchData();
//         resetForm();
//         setFile(null);
//         setUploadFile(null);
//       })
//       .catch((error) => {
//         toast.error("Unable to add the education.Please try again.");
//         console.error("Failed to add education:", error.message);
//         if (error.response) {
//           console.error("Error Response Data:", error.response.data);
//           console.error("Error Response Status:", error.response.status);
//           console.error("Error Response Headers:", error.response.headers);
//         }
//         setLoading(false);
//         setEducationForm(false);
//       });
//     // setFormData({
//     //   course: "",
//     //   education: "",
//     //   end_date: "",
//     //   grading_system: "",
//     //   institute: "",
//     //   marks: "",
//     //   specialization: "",
//     //   start_date: "",
//     //   attachment: "",
//     // });
//     setErrors({});
//     setEducationForm(false);
//   };
//   const resetForm = () => {
//     setFormData({
//       course: "",
//       education: "",
//       end_date: "",
//       grading_system: "",
//       institute: "",
//       marks: "",
//       specialization: "",
//       start_date: "",
//       attachment: "",
//       name: "",
//       url: "",
//       description: "",
//       provider: "",
//       cID: "",
//       expiry: "",
//     });
//     setErrors({});
//     setEducationForm(false);
//     setSocilaForm(false);
//   };

//   //Handle Delete Education
//   const handleDeleteEducation = async (educationId) => {
//     const baseURL = process.env.REACT_APP_BASE_URL;
//     const id = localStorage.getItem("id");
//     const token = localStorage.getItem("authToken");
//     console.log(`Deleting education with ID: ${educationId}`);
//     try {
//       const response = await axios.post(
//         `${baseURL}/students/remove-edu-details`,
//         {
//           id: id,
//           eduId: educationId,
//         },
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//             "Content-Type": "application/json",
//           },
//         }
//       );
//       if (response.status === 200) {
//         toast.success("Education deleted successfully");
//         setStudentData((prevData) => ({
//           ...prevData,
//           data: {
//             ...prevData.data,
//             edu_details: prevData.data.edu_details.filter(
//               (education) => education._id !== educationId
//             ),
//           },
//         }));
//       }
//     } catch (error) {
//       // console.error('Error deleting education:', error);
//       toast.error("Unable to delete the education.Please try again");
//     }
//   };

//   //Handle Edit Education
//   const handleSubmit3 = (event, eductId) => {
//     console.log(eductId, "Education Id");
//     event.preventDefault();

//     const validate = () => {
//       const newErrors = {};
//       if (!formData.education) newErrors.education = "Education is required";
//       if (!formData.institute) newErrors.institute = "Institute is required";
//       if (!formData.course) newErrors.course = "Course is required";
//       if (!formData.specialization)
//         newErrors.specialization = "Specialization is required";
//       if (!formData.start_date) {
//         newErrors.start_date = "Please select a start date.";
//       } else if (!formData.end_date) {
//         newErrors.end_date = "Please select an end date.";
//       } else if (new Date(formData.start_date) > new Date(formData.end_date)) {
//         newErrors.start_date = "Start date should be less than the end date.";
//         newErrors.end_date = "End date should be greater than the start date.";
//       }
//       if (!formData.grading_system)
//         newErrors.grading_system = "Grading system is required";
//       if (!formData.marks) newErrors.marks = "Marks are required";
//       return newErrors;
//     };

//     const validationErrors = validate();
//     if (Object.keys(validationErrors).length > 0) {
//       setErrors(validationErrors);
//       return;
//     }
//     setLoading(true);
//     console.log("Form Data:", formData);

//     const baseURL = process.env.REACT_APP_BASE_URL;
//     const token = localStorage.getItem("authToken");
//     const id = localStorage.getItem("id");

//     const config = {
//       method: "post",
//       url: `${baseURL}/students/update-edu-details`,
//       headers: {
//         Authorization: `Bearer ${token}`,
//         "Content-Type": "application/json",
//       },
//       data: {
//         id: id,
//         eduId: eductId,
//         course: formData.course,
//         education: formData.education,
//         end_date: formData.end_date,
//         grading_system: formData.grading_system,
//         institute: formData.institute,
//         marks: formData.marks,
//         specialization: formData.specialization,
//         start_date: formData.start_date,
//         attachment: formData.attachment,
//       },
//     };

//     console.log("Request Config Update:", config);

//     axios
//       .request(config)
//       .then((response) => {
//         toast.success("Education details edited successfully");
//         // console.log("Education update successfully:", response.data);
//         setLoading(false);
//         closePersonlFormEdit(false);
//         setFile(null);
//         setUploadFile(null);
//         fetchData();
//         fetchEducationDetails();
//       })
//       .catch((error) => {
//         toast.error("Unable to edit the eduaction details. Please try again.");
//         // console.error("Failed to update education:", error.message);
//         if (error.response) {
//           console.error("Error Response Data:", error.response.data);
//           console.error("Error Response Status:", error.response.status);
//           console.error("Error Response Headers:", error.response.headers);
//         }
//         setLoading(false);
//         closePersonlFormEdit(false);
//       });
//   };

//   // Handle Add Social Links
//   const handlesociallinks = (event) => {
//     event.preventDefault();

//     const validate = () => {
//       const newErrors = {};
//       if (!formData.name) newErrors.name = "Social name is required";
//       if (!formData.url) newErrors.url = "Social Url is required";
//       return newErrors;
//     };

//     const validationErrors = validate();
//     if (Object.keys(validationErrors).length > 0) {
//       setErrors(validationErrors);
//       return;
//     }
//     setLoading(true);
//     console.log("Form Data:", formData);

//     const baseURL = process.env.REACT_APP_BASE_URL;
//     const token = localStorage.getItem("authToken");
//     const id = localStorage.getItem("id");

//     if (!baseURL || !token || !id) {
//       console.error("Missing required configuration values");
//       return;
//     }
//     const { name, url, description } = formData;
//     const filteredData = {
//       name,
//       url,
//       description,
//     };
//     filteredData.id = id;
//     const config = {
//       method: "post",
//       url: `${baseURL}/students/soc-media`,
//       headers: {
//         Authorization: `Bearer ${token}`,
//         "Content-Type": "application/json",
//       },
//       data: filteredData,
//     };
//     console.log("Request Config Update:", config);
//     axios
//       .request(config)
//       .then((response) => {
//         toast.success("Social link added successfully");
//         // console.log("Social link added successfully:", response.data);
//         setLoading(false);
//         setSocilaForm(false);
//         fetchData();
//         resetForm();
//       })
//       .catch((error) => {
//         toast.error("Unable to add the social link.Please try again");
//         // console.error("Failed to add Social links:", error.message);
//         if (error.response) {
//           console.error("Error Response Data:", error.response.data);
//           console.error("Error Response Status:", error.response.status);
//           console.error("Error Response Headers:", error.response.headers);
//         }
//         setLoading(false);
//         setSocilaForm(false);
//       });
//     setErrors({});
//     setSocilaForm(false);
//   };

//   // Handle Delete Social Links
//   const handleDeleteSocial = async (socialId) => {
//     const baseURL = process.env.REACT_APP_BASE_URL;
//     const id = localStorage.getItem("id");
//     const token = localStorage.getItem("authToken");
//     console.log(`Deleting social with ID: ${socialId}`);
//     try {
//       const response = await axios.post(
//         `${baseURL}/students/remove-sm`,
//         {
//           id: id,
//           smId: socialId,
//         },
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//             "Content-Type": "application/json",
//           },
//         }
//       );
//       if (response.status === 200) {
//         toast.success("Social link deleted successfully");
//         setStudentData((prevData) => ({
//           ...prevData,
//           data: {
//             ...prevData.data,
//             social_media: prevData.data.social_media.filter(
//               (social) => social._id !== socialId
//             ),
//           },
//         }));
//       }
//     } catch (error) {
//       // console.error("Unable to delete the social link. Please try again", errpr);
//       toast.error("Unable to delete the social link. Please try again");
//     }
//   };

//   // Handle Edit Social Links
//   const handlEditSocialLinks = (event, socId) => {
//     console.log(socId, "Social Id");
//     event.preventDefault();

//     const validate = () => {
//       const newErrors = {};
//       if (!formData.name) newErrors.name = "Social name is required";
//       if (!formData.url) newErrors.url = "Url is required";
//       return newErrors;
//     };

//     const validationErrors = validate();
//     if (Object.keys(validationErrors).length > 0) {
//       setErrors(validationErrors);
//       return;
//     }
//     setLoading(true);
//     console.log("Form Data:", formData);

//     const baseURL = process.env.REACT_APP_BASE_URL;
//     const token = localStorage.getItem("authToken");
//     const id = localStorage.getItem("id");

//     const config = {
//       method: "post",
//       url: `${baseURL}/students/update-sm`,
//       headers: {
//         Authorization: `Bearer ${token}`,
//         "Content-Type": "application/json",
//       },
//       data: {
//         id: id,
//         smId: socId,
//         name: formData.name,
//         url: formData.url,
//         description: formData.description,
//       },
//     };

//     console.log("Request Config Update:", config);

//     axios
//       .request(config)
//       .then((response) => {
//         toast.success("Social link edited successfully");
//         // console.log("Social link edited successfully:", response.data);
//         setLoading(false);
//         closeSocialFormEdit(false);
//         fetchData();
//         fetchSocialDetails();
//       })
//       .catch((error) => {
//         toast.error("Unable to edit the social link. Please try again");
//         // console.error("Unable to edit the social link. Please try again:", error.message);
//         if (error.response) {
//           console.error("Error Response Data:", error.response.data);
//           console.error("Error Response Status:", error.response.status);
//           console.error("Error Response Headers:", error.response.headers);
//         }
//         setLoading(false);
//         closeSocialFormEdit(false);
//       });
//   };

//   // Handle Add Certificate
//   const handleCertificate = (event) => {
//     event.preventDefault();

//     const validate = () => {
//       const newErrors = {};
//       if (!formData.name) newErrors.name = "certification name is required";
//       if (!formData.provider) newErrors.provider = "Provider is required";
//       if (!formData.start_date) {
//         newErrors.start_date = "Start date is required";
//         if (formData.end_date) {
//           newErrors.end_date = "Please add the start date first";
//         }
//       }
//       if (!formData.expiry) {
//         if (!formData.end_date) {
//           newErrors.end_date = "End date is required";
//         } else if (formData.start_date) {
//           const startDate = new Date(formData.start_date);
//           const endDate = new Date(formData.end_date);
//           // Check if start date is not less than end date
//           if (startDate >= endDate) {
//             newErrors.start_date = "Start date must be less than end date";
//             newErrors.end_date = "End date must be greater than start date";
//           }
//         }
//       }

//       return newErrors;
//     };

//     const validationErrors = validate();
//     if (Object.keys(validationErrors).length > 0) {
//       setErrors(validationErrors);
//       return;
//     }
//     setLoading(true);
//     console.log("Form Data:", formData);

//     const baseURL = process.env.REACT_APP_BASE_URL;
//     const token = localStorage.getItem("authToken");
//     const id = localStorage.getItem("id");

//     if (!baseURL || !token || !id) {
//       console.error("Missing required configuration values");
//       return;
//     }
//     const {
//       name,
//       url,
//       provider,
//       cID,
//       start_date,
//       end_date,
//       expiry,
//       attachment,
//     } = formData;
//     const filteredData = {
//       name,
//       provider,
//       cID,
//       url,
//       start_date,
//       end_date: expiry ? null : end_date,
//       expiry: !!expiry,
//       attachment: attachment || null,
//     };
//     filteredData.id = id;
//     const config = {
//       method: "post",
//       url: `${baseURL}/students/certification`,
//       headers: {
//         Authorization: `Bearer ${token}`,
//         "Content-Type": "application/json",
//       },
//       data: filteredData,
//     };
//     console.log("Request Config Update:", config);
//     axios
//       .request(config)
//       .then((response) => {
//         toast.success("Certificate added successfully");
//         // console.log("Certificate added successfully:", response.data);
//         setLoading(false);
//         setCertificationForm(false);
//         fetchData();
//         resetForm();
//         setFile(null);
//         setUploadFile(null);
//       })
//       .catch((error) => {
//         toast.error("Unable to add the certificate. Please try again");
//         // console.error("Unable to add the certificate. Please try again:", error.message);
//         if (error.response) {
//           console.error("Error Response Data:", error.response.data);
//           console.error("Error Response Status:", error.response.status);
//           console.error("Error Response Headers:", error.response.headers);
//         }
//         setLoading(false);
//       });
//     setErrors({});
//     setCertificationForm(false);
//     resetForm();
//   };

//   // Handle Delete Certification
//   const handleDeleteCertification = async (certificateId) => {
//     const baseURL = process.env.REACT_APP_BASE_URL;
//     const id = localStorage.getItem("id");
//     const token = localStorage.getItem("authToken");
//     console.log(`Deleting social with ID: ${certificateId}`);
//     try {
//       const response = await axios.post(
//         `${baseURL}/students/remove-cert`,
//         {
//           id: id,
//           cId: certificateId,
//         },
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//             "Content-Type": "application/json",
//           },
//         }
//       );
//       if (response.status === 200) {
//         toast.success("Certificate deleted successfully");
//         setStudentData((prevData) => ({
//           ...prevData,
//           data: {
//             ...prevData.data,
//             certifications: prevData.data.certifications.filter(
//               (certificate) => certificate._id !== certificateId
//             ),
//           },
//         }));
//       }
//     } catch (error) {
//       // console.error("Unable to delete the certificate. Please try again", error);
//       toast.error("Unable to delete the certificate. Please try again");
//     }
//   };

//   // Handle Edit Certification
//   const handleEditCertificate = (event, certiId) => {
//     console.log(certiId, "Social Id");
//     event.preventDefault();
//     if (formData.expiry) {
//       formData.end_date = null;
//     }
//     const validate = () => {
//       const newErrors = {};
//       if (!formData.name) newErrors.name = "certification name is required";
//       if (!formData.provider) newErrors.provider = "Provider is required";
//       if (!formData.start_date) {
//         newErrors.start_date = "Start date is required";
//         if (formData.end_date) {
//           newErrors.end_date = "Please add the start date first";
//         }
//       }
//       if (!formData.expiry) {
//         if (!formData.end_date) {
//           newErrors.end_date = "End date is required";
//         } else if (formData.start_date) {
//           const startDate = new Date(formData.start_date);
//           const endDate = new Date(formData.end_date);
//           // Check if start date is not less than end date
//           if (startDate >= endDate) {
//             newErrors.start_date = "Start date must be less than end date";
//             newErrors.end_date = "End date must be greater than start date";
//           }
//         }
//       }
//       return newErrors;
//     };

//     const validationErrors = validate();
//     if (Object.keys(validationErrors).length > 0) {
//       setErrors(validationErrors);
//       return;
//     }
//     setLoading(true);
//     console.log("Form Data:", formData);

//     const baseURL = process.env.REACT_APP_BASE_URL;
//     const token = localStorage.getItem("authToken");
//     const id = localStorage.getItem("id");

//     const config = {
//       method: "post",
//       url: `${baseURL}/students/update-cert`,
//       headers: {
//         Authorization: `Bearer ${token}`,
//         "Content-Type": "application/json",
//       },
//       data: {
//         id: id,
//         cId: certiId,
//         name: formData.name,
//         url: formData.url,
//         provider: formData.provider,
//         cID: formData.cID,
//         start_date: formData.start_date,
//         end_date: formData.expiry ? null : formData.end_date,
//         expiry: formData.expiry,
//         attachment: formData.attachment,
//       },
//     };

//     console.log("Request Config Update:", config);

//     axios
//       .request(config)
//       .then((response) => {
//         toast.success("Certificate details edited successfully");
//         console.log("Certificate details edited successfully:", response.data);
//         setLoading(false);
//         closeCertificateEdit(false);
//         fetchCertificateDetails();
//         fetchData();
//         setFile(null);
//         setUploadFile(null);
//       })
//       .catch((error) => {
//         toast.error("Unable to edit the certificate details. Please try again");
//         console.error("Failed to Update certificate:", error.message);
//         if (error.response) {
//           console.error("Error Response Data:", error.response.data);
//           console.error("Error Response Status:", error.response.status);
//           console.error("Error Response Headers:", error.response.headers);
//         }
//         setLoading(false);
//         closeCertificateEdit(false);
//       });
//   };

//   // Handle Change Password
//   const handleChangePass = async (e) => {
//     e.preventDefault();
//     if (!validateForm()) return;

//     const { password } = formData; // Assuming formData has a password and confirmPassword

//     // Create an object to send the password in the request body
//     const filteredData = {
//       password,
//     };

//     const baseURL = process.env.REACT_APP_BASE_URL;
//     const token = localStorage.getItem("authToken");
//     setLoadingPass(true);

//     try {
//       const response = await axios.post(
//         `${baseURL}/auth/student-change-password`,
//         filteredData,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//             "Content-Type": "application/json",
//           },
//         }
//       );
//       toast.success("Password changed successfully");
//       console.log("Password changed successfully:", response.data);
//       setFormData({
//         password: "",
//         confirmPassword: "",
//       });
//       setFormErrors({});
//     } catch (error) {
//       toast.error("Unable to change the password. Please try again");
//       console.error(
//         "Unable to change the password. Please try again:",
//         error.response?.data || error.message
//       );
//     } finally {
//       setLoadingPass(false);
//     }
//   };

//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);
//   const baseUrl = "https://secure-data-upload-new.s3.ap-south-1.amazonaws.com/";

//   const handleRemoveAttachment = () => {
//     setFormData({ ...formData, attachment: null });
//   };

//   return (
//     <>
//       <section id="student-profile" className="student-profile-sec">
//         <div className="container mt-4">
//           {loading ? (
//             <div className="col-md-12">
//               <div className="d-flex justify-content-center align-items-center">
//                 {" "}
//                 <Loader />
//               </div>
//             </div>
//           ) : (
//             <div className="row">
//               {/* Left Sidebar: Profile Card */}
//               <div className="col-md-4 sidebar">
//                 <div className="card text-center student-profile-card mb-3 student-profile-card-1">
//                   <div className="profile-sub">
//                     <div className="profile-sub-img">
//                       <img
//                         src={
//                           studentData?.data?.profilePicture?.path
//                             ? `${baseUrl}${studentData?.data?.profilePicture?.path}`
//                             : img1
//                         }
//                         className="card-img-top rounded-circle mx-auto mt-3"
//                         alt="img"
//                       />
//                     </div>
//                     <div className="card-body profile-sub-info mb-4">
//                       <h5 className="card-title">
//                         {studentData?.data?.firstName}{" "}
//                         {studentData?.data?.lastName}
//                       </h5>
//                       <p className="card-text1">
//                         <i className="bi bi-envelope mx-1"></i>{" "}
//                         {/* {studentData?.data?.email ? (
//                         <span className="wrap">{studentData.data.email}</span>
//                       ) : (
//                         <div className="d-flex justify-content-center align-items-center">
//                           <Loader />
//                         </div>
//                       )} */}
//                         <span className="wrap">{studentData?.data?.email}</span>
//                       </p>
//                       <p className="card-text1">
//                         {" "}
//                         <i className="bi bi-phone mx-1"></i>
//                         {studentData?.data?.phone}
//                       </p>
//                       {studentData?.data?.location?.cityName && (
//                         <p className="card-text1">
//                           <i className="bi bi-geo-alt mx-1"></i>
//                           {studentData.data.location.cityName}
//                         </p>
//                       )}
//                     </div>
//                   </div>
//                 </div>
//                 {/* Quick Links */}
//                 <div className="card quick-links-main mb-4 pb-2 student-profile-card-1">
//                   <h6 className="px-3 pt-3">
//                     <strong>Quick links</strong>
//                   </h6>
//                   {/* <ul className="list-group list-group-flush px-3">
//                     <li className="list-group-item">
//                       <HashLink to="#profile-overview" className="links">
//                         Profile Overview
//                       </HashLink>
//                     </li>
//                     <li className="list-group-item">
//                       <HashLink to="#personal-details" className="links">
//                         Personal Details
//                       </HashLink>
//                     </li>
//                     <li className="list-group-item">
//                       <HashLink to="#education-details" className="links">
//                         Education Details
//                       </HashLink>
//                     </li>
//                     <li className="list-group-item">
//                       <HashLink to="#skills-details" className="links">
//                         Skills
//                       </HashLink>
//                     </li>
//                     <li className="list-group-item">
//                     <HashLink to="#certification-details" className="links">
//                       Certification
//                     </HashLink>
//                   </li>
//                   <li className="list-group-item">
//                     <HashLink to="#socialmedia-details" className="links">
//                       Social media profile
//                     </HashLink>
//                   </li>
//                   </ul> */}
//                   <ul className="list-group list-group-flush px-3">
//                     <li
//                       className={`list-group-item ${
//                         activeSection === "profile-overview" ? "active" : ""
//                       }`}
//                       onClick={() => scrollToSection("profile-overview")}
//                     >
//                       <h6 className="links">Profile Overview</h6>
//                     </li>
//                     <li
//                       className={`list-group-item ${
//                         activeSection === "personal-details" ? "active" : ""
//                       }`}
//                       onClick={() => scrollToSection("personal-details")}
//                     >
//                       <h6 className="links">Personal Details</h6>
//                     </li>
//                     <li
//                       className={`list-group-item ${
//                         activeSection === "education-details" ? "active" : ""
//                       }`}
//                       onClick={() => scrollToSection("education-details")}
//                     >
//                       <h6 className="links">Education Details</h6>
//                     </li>
//                     <li
//                       className={`list-group-item ${
//                         activeSection === "skills-details" ? "active" : ""
//                       }`}
//                       onClick={() => scrollToSection("skills-details")}
//                     >
//                       <h6 className="links">Skills</h6>
//                     </li>
//                     <li
//                       className={`list-group-item ${
//                         activeSection === "certification-details"
//                           ? "active"
//                           : ""
//                       }`}
//                       onClick={() => scrollToSection("certification-details")}
//                     >
//                       <h6 className="links">Certification</h6>
//                     </li>
//                     <li
//                       className={`list-group-item ${
//                         activeSection === "socialmedia-details" ? "active" : ""
//                       }`}
//                       onClick={() => scrollToSection("socialmedia-details")}
//                     >
//                       <h6 className="links">Social Media Profile</h6>
//                     </li>
//                   </ul>
//                 </div>
//               </div>

//               {/* Right Side: Profile Details */}

//               <div className="col-md-8 right-side-sect">
//                 {/* Profile Overview */}
//                 <div
//                   id="profile-overview"
//                   className="card card-pro-details mb-4 student-profile-card-2"
//                 >
//                   <div className="card-body">
//                     <div className="card-pro-details-title">
//                       {" "}
//                       <h5 className="card-title">Profile Overview</h5>
//                       <OverlayTrigger
//                         placement="top"
//                         overlay={
//                           <Tooltip id="tooltip-top">
//                             Edit Profile Overview
//                           </Tooltip>
//                         }
//                       >
//                         <i
//                           className="bi bi-pencil-fill student-details-icon"
//                           onClick={openOverviewForm}
//                         ></i>
//                       </OverlayTrigger>
//                     </div>
//                     <p className="card-text">
//                       {studentData?.data?.profile_overview?.trim() ? (
//                         studentData.data.profile_overview
//                       ) : (
//                         <div className="card-text">
//                           No Profile Overview Added
//                         </div>
//                       )}
//                     </p>
//                   </div>
//                 </div>

//                 {/* Personal Details */}
//                 <div
//                   id="personal-details"
//                   className="card card-pro-details mb-4 student-profile-card-2"
//                 >
//                   <div className="card-body">
//                     <div className="card-pro-details-title">
//                       {" "}
//                       <h5 className="card-title">Personal Details</h5>
//                       <OverlayTrigger
//                         placement="top"
//                         overlay={
//                           <Tooltip id="tooltip-top">
//                             Edit Personal Details
//                           </Tooltip>
//                         }
//                       >
//                         <i
//                           class="bi bi-pencil-fill student-details-icon"
//                           onClick={openPersonlForm}
//                         ></i>
//                       </OverlayTrigger>
//                     </div>
//                     <p className="card-text">
//                       <div class="row personal-details-row">
//                         <div class="col-md-6">
//                           <span className="sub-mini-title">Date of Birth</span>
//                           <br />
//                           <strong className="personal-sub-details">
//                             {/* {studentData?.data?.dob || "N/A"} */}
//                             {studentData?.data?.dob
//                               ? new Date(
//                                   studentData.data.dob
//                                 ).toLocaleDateString("en-GB")
//                               : "N/A"}
//                           </strong>
//                         </div>
//                         <div class="col-md-6">
//                           <span className="sub-mini-title">Gender</span>
//                           <br />
//                           <strong className="personal-sub-details">
//                             {studentData?.data?.gender || "N/A"}
//                           </strong>
//                         </div>
//                       </div>
//                       <div className="profile-address">
//                         <span className="sub-mini-title">Address</span>
//                         <br />
//                         <strong className="personal-sub-details">
//                           {studentData?.data?.address || "N/A"}
//                         </strong>
//                       </div>
//                     </p>
//                   </div>
//                 </div>

//                 {/* Education Details */}
//                 <div
//                   id="education-details"
//                   className="card card-pro-details mb-4 student-profile-card-2"
//                 >
//                   <div className="card-body">
//                     <div className="card-pro-details-title">
//                       {" "}
//                       <h5 className="card-title">Education Details</h5>
//                       <div>
//                         {/* <i className="bi bi-paperclip student-details-icon mx-3"></i> */}
//                         <OverlayTrigger
//                           placement="top"
//                           overlay={
//                             <Tooltip id="tooltip-top">
//                               Add Education Details
//                             </Tooltip>
//                           }
//                         >
//                           <i
//                             class="bi bi-plus-circle-fill student-details-icon"
//                             onClick={openPersonlForm1}
//                           ></i>
//                         </OverlayTrigger>
//                       </div>
//                     </div>

//                     <div className="card-text">
//                       {studentData?.data?.edu_details?.length > 0 ? (
//                         studentData.data.edu_details.map((education, index) => (
//                           <div
//                             className="mb-2 d-flex justify-content-between"
//                             key={index}
//                           >
//                             <div>
//                               <p className="sub-mini-title">
//                                 {education?.education} | {education?.course}
//                               </p>
//                               <h6 className="personal-sub-details">
//                                 {education?.institute}
//                               </h6>
//                               <p className="personal-edu-sect certi-date">
//                                 {new Date(
//                                   education?.start_date
//                                 ).getFullYear() ||
//                                   new Date().getFullYear()}{" "}
//                                 to{" "}
//                                 {new Date(education?.end_date).getFullYear() ||
//                                   new Date().getFullYear() + 4}{" "}
//                                 | {education?.marks} {education?.grading_system}
//                               </p>
//                             </div>
//                             <div>
//                               <OverlayTrigger
//                                 placement="top"
//                                 overlay={
//                                   <Tooltip id="tooltip-top">
//                                     Edit Education Details
//                                   </Tooltip>
//                                 }
//                               >
//                                 <i
//                                   className="bi bi-pencil-fill student-details-icon mx-3"
//                                   onClick={() =>
//                                     handleOpenEducationForm(education._id)
//                                   }
//                                 ></i>
//                               </OverlayTrigger>

//                               <OverlayTrigger
//                                 placement="top"
//                                 overlay={
//                                   <Tooltip id="tooltip-top">
//                                     Delete Education Details
//                                   </Tooltip>
//                                 }
//                               >
//                                 <i
//                                   className="bi bi-trash student-details-icon"
//                                   onClick={() =>
//                                     handleDeleteEducation(education._id)
//                                   }
//                                 ></i>
//                               </OverlayTrigger>
//                             </div>
//                           </div>
//                         ))
//                       ) : (
//                         <div>No Education Added</div>
//                       )}
//                     </div>
//                   </div>
//                 </div>

//                 {/* Skills */}
//                 <div
//                   id="skills-details"
//                   className="card card-pro-details mb-4 student-profile-card-2"
//                 >
//                   <div className="card-body">
//                     <div className="card-pro-details-title">
//                       <h5 className="card-title">Skills</h5>
//                       <OverlayTrigger
//                         placement="top"
//                         overlay={<Tooltip id="tooltip-top">Add Skills</Tooltip>}
//                       >
//                         <i
//                           className="bi bi-plus-circle-fill student-details-icon"
//                           onClick={openPersonlForm2}
//                         ></i>
//                       </OverlayTrigger>
//                     </div>
//                     <div className="card-text">
//                       <div>
//                         {studentData?.data?.skills?.length > 0 ? (
//                           <ul className="card-unorder-list">
//                             {studentData.data.skills.map((skill, index) => (
//                               <div
//                                 className="d-flex justify-content-between"
//                                 key={index}
//                               >
//                                 <li>
//                                   <p className="skill-list-text">
//                                     {skill?.skillname}
//                                   </p>
//                                 </li>
//                                 <OverlayTrigger
//                                   placement="top"
//                                   overlay={
//                                     <Tooltip id="tooltip-top">
//                                       Delete Skill
//                                     </Tooltip>
//                                   }
//                                 >
//                                   <i
//                                     className="bi bi-trash student-details-icon"
//                                     onClick={() => handleDeleteSkill(skill._id)}
//                                   ></i>
//                                 </OverlayTrigger>
//                               </div>
//                             ))}
//                           </ul>
//                         ) : (
//                           <div>No Skill Added</div>
//                         )}
//                       </div>
//                     </div>
//                   </div>
//                 </div>

//                 {/* Projects */}
//                 {/* <div id="Projects-details" className="card card-pro-details ">
//                  <div className="card-body">
//                    <h5 className="card-title">Projects</h5>
//                    <p className="card-text">
//                       <span className="sub-mini-title">Title-1</span>
//                      <div>
//                        <strong className="personal-sub-details">
//                          www.project-1.com
//                        </strong>
//                        <p>
//                          Finished | Dec 2023-Feb 2024 <br />
//                          <span>
//                            Lorem ipsum dolor sit amet, consectetur adipiscing
//                            elit. Vestibulum mollis nunc a molestie dictum.
//                          </span>
//                        </p>
//                      </div>
//                    </p>
//                  </div>
//                </div> */}

//                 {/* Certification */}
//                 <div
//                   id="certification-details"
//                   className="card card-pro-details mb-4 student-profile-card-2"
//                 >
//                   <div className="card-body">
//                     <div className="card-pro-details-title">
//                       {" "}
//                       <h5 className="card-title">Certification</h5>
//                       <OverlayTrigger
//                         placement="top"
//                         overlay={
//                           <Tooltip id="tooltip-top">Add Certificate</Tooltip>
//                         }
//                       >
//                         <i
//                           className="bi bi-plus-circle-fill student-details-icon"
//                           onClick={openPersonlForm3}
//                         ></i>
//                       </OverlayTrigger>
//                     </div>

//                     {studentData?.data?.certifications?.length > 0 ? (
//                       studentData.data.certifications.map((certn, index) => (
//                         <div
//                           className="card-text certificate-sub-div"
//                           key={index}
//                         >
//                           <div>
//                             <div className="d-flex justify-content-end">
//                               <span>
//                                 <OverlayTrigger
//                                   placement="top"
//                                   overlay={
//                                     <Tooltip id="tooltip-top">
//                                       Edit Certificate
//                                     </Tooltip>
//                                   }
//                                 >
//                                   <i
//                                     className="bi bi-pencil-fill student-details-icon mx-3"
//                                     onClick={() =>
//                                       handleCertificateFormEdit(certn._id)
//                                     }
//                                   ></i>
//                                 </OverlayTrigger>
//                                 <OverlayTrigger
//                                   placement="top"
//                                   overlay={
//                                     <Tooltip id="tooltip-top">
//                                       Delete certificate
//                                     </Tooltip>
//                                   }
//                                 >
//                                   <i
//                                     className="bi bi-trash student-details-icon"
//                                     onClick={() =>
//                                       handleDeleteCertification(certn._id)
//                                     }
//                                   ></i>
//                                 </OverlayTrigger>
//                               </span>
//                             </div>
//                             <h6 className="certi-name">{certn?.name}</h6>
//                             <p className="certi-sub-name">{certn?.provider}</p>
//                             <div>
//                               {certn?.cID && (
//                                 <p className="certi-date">
//                                   Certification ID: {certn.cID}
//                                 </p>
//                               )}
//                             </div>
//                             <p className="certi-link">
//                               <a
//                                 href={certn?.url}
//                                 target="_blank"
//                                 rel="noopener noreferrer"
//                               >
//                                 {certn?.url}
//                               </a>
//                             </p>
//                             {certn?.start_date && (
//                               <p className="certi-date">
//                                 {certn?.expiry
//                                   ? `Valid from ${new Date(
//                                       certn.start_date
//                                     ).toLocaleString("default", {
//                                       month: "short",
//                                     })} '${new Date(certn.start_date)
//                                       .getFullYear()
//                                       .toString()
//                                       .slice(-2)}'. Does not expire`
//                                   : `Validity: ${new Date(
//                                       certn.start_date
//                                     ).toLocaleString("default", {
//                                       month: "short",
//                                     })} '${new Date(certn.start_date)
//                                       .getFullYear()
//                                       .toString()
//                                       .slice(-2)}' to ${new Date(
//                                       certn.end_date
//                                     ).toLocaleString("default", {
//                                       month: "short",
//                                     })} '${new Date(certn.end_date)
//                                       .getFullYear()
//                                       .toString()
//                                       .slice(-2)}'`}
//                               </p>
//                             )}
//                           </div>
//                         </div>
//                       ))
//                     ) : (
//                       <div className="card-text">No Certificate Added</div>
//                     )}
//                   </div>
//                 </div>

//                 {/* SocialMedia */}
//                 <div
//                   id="socialmedia-details"
//                   className="card card-pro-details mb-4 student-profile-card-2"
//                 >
//                   <div className="card-body">
//                     <div className="card-pro-details-title">
//                       {" "}
//                       <h5 className="card-title">Social media profile</h5>
//                       <OverlayTrigger
//                         placement="top"
//                         overlay={
//                           <Tooltip id="tooltip-top">Add social links</Tooltip>
//                         }
//                       >
//                         <i
//                           class="bi bi-plus-circle-fill student-details-icon"
//                           onClick={openPersonlForm4}
//                         ></i>
//                       </OverlayTrigger>
//                     </div>
//                     {studentData?.data?.social_media?.length > 0 ? (
//                       studentData.data.social_media.map((socl, index) => (
//                         <div className="card-text" key={index}>
//                           <div>
//                             <div className="d-flex justify-content-end">
//                               <span>
//                                 <OverlayTrigger
//                                   placement="top"
//                                   overlay={
//                                     <Tooltip id="tooltip-top">
//                                       Edit social links
//                                     </Tooltip>
//                                   }
//                                 >
//                                   <i
//                                     className="bi bi-pencil-fill student-details-icon mx-3"
//                                     onClick={() =>
//                                       handleSocialFormEdit(socl._id)
//                                     }
//                                   ></i>
//                                 </OverlayTrigger>
//                                 <OverlayTrigger
//                                   placement="top"
//                                   overlay={
//                                     <Tooltip id="tooltip-top">
//                                       Delete social links
//                                     </Tooltip>
//                                   }
//                                 >
//                                   <i
//                                     className="bi bi-trash student-details-icon"
//                                     onClick={() => handleDeleteSocial(socl._id)}
//                                   ></i>
//                                 </OverlayTrigger>
//                               </span>
//                             </div>
//                             <p className="certi-sub-name">{socl?.name}</p>
//                             <p className="certi-link">
//                               <a
//                                 href={socl?.url}
//                                 target="_blank"
//                                 rel="noopener noreferrer"
//                               >
//                                 {socl?.url}
//                               </a>
//                             </p>
//                             <p className="socl-desc">{socl?.description}</p>
//                           </div>
//                         </div>
//                       ))
//                     ) : (
//                       <div className="card-text">No Social Links Added</div>
//                     )}
//                   </div>
//                 </div>

//                 {/* Languages */}
//                 {/* <div
//                  id="languages-details"
//                  className="card card-pro-details mb-4"
//                >
//                  <div className="card-body">
//                    <h5 className="card-title">Languages</h5>
//                    <p className="card-text">
//                      <div>
//                        <ul>
//                          <li>
//                            <strong className="personal-sub-details">
//                              English
//                            </strong>
//                          </li>
//                          <li>
//                            <strong className="personal-sub-details">
//                              Hindi
//                            </strong>
//                          </li>
//                          <li>
//                            <strong className="personal-sub-details">
//                              Marathi
//                            </strong>
//                          </li>
//                        </ul>
//                      </div>
//                    </p>
//                  </div>
//                </div> */}
//               </div>
//             </div>
//           )}
//         </div>
//       </section>

//       {/* Modals Form */}

//       {/* OverviewForm */}
//       {showOverviewForm && (
//         <div
//           className="modal show d-block"
//           tabIndex="-1"
//           style={{ backdropFilter: "brightness(0.5)" }}
//         >
//           <div className="modal-dialog">
//             <div className="modal-content">
//               <div className="modal-header">
//                 <h5 className="modal-title">Overview</h5>
//                 <button
//                   type="button"
//                   className="btn-close"
//                   aria-label="Close"
//                   onClick={closeOverviewForm}
//                 ></button>
//               </div>
//               <div className="modal-body">
//                 <form onSubmit={handleSubmitOver}>
//                   <div className="mb-3">
//                     <label htmlFor="overview" className="form-label">
//                       Profile Overview
//                     </label>
//                     <textarea
//                       className="form-control form-modal"
//                       id="profile_overview"
//                       rows="4"
//                       value={formData.profile_overview}
//                       onChange={handleInputChange1}
//                     ></textarea>
//                     {error && (
//                       <div className="error-message text-danger">{error}</div>
//                     )}{" "}
//                     {/* Error message */}
//                   </div>
//                 </form>
//               </div>
//               <div className="modal-footer">
//                 <button
//                   type="button"
//                   className="btn btn-light student-profile-btn2"
//                   onClick={closeOverviewForm}
//                 >
//                   Cancel
//                 </button>
//                 <button
//                   type="submit"
//                   className="btn btn-primary student-profile-btn1"
//                   onClick={handleSubmitOver}
//                   disabled={loading}
//                 >
//                   {loading ? (
//                     <span
//                       className="spinner-border spinner-border-sm"
//                       role="status"
//                       aria-hidden="true"
//                     ></span>
//                   ) : (
//                     "Save changes"
//                   )}
//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}

//       {/* PersonalDetailsForm */}
//       {showPersonalForm && (
//         <div
//           className="modal show d-block bd-example-modal-lg"
//           tabIndex="-1"
//           style={{ backdropFilter: "brightness(0.5)" }}
//         >
//           <div className="modal-dialog modal-lg">
//             <div className="modal-content">
//               <div className="modal-header">
//                 <h5 className="modal-title">Personal Details</h5>
//                 <button
//                   type="button"
//                   className="btn-close"
//                   aria-label="Close"
//                   onClick={closePersonlForm}
//                 ></button>
//               </div>
//               <div className="modal-body">
//                 <form onSubmit={handleSubmit}>
//                   <div className="row">
//                     <div className="col-md-6 col-sm-12">
//                       <div className="profile-upload-sect">
//                         {image ? (
//                           <img
//                             src={image}
//                             alt="Uploaded"
//                             className="img-profile-upload"
//                           />
//                         ) : (
//                           <img
//                             src={
//                               studentData?.data?.profilePicture?.path
//                                 ? `${baseUrl}${studentData?.data?.profilePicture?.path}`
//                                 : img1
//                             }
//                             alt="Default"
//                             className="img-profile-upload"
//                           />
//                         )}
//                         <div className="custom-btn-upload">
//                           <input
//                             type="file"
//                             id="hiddenFileInput"
//                             style={{ display: "none" }}
//                             onChange={handleImageUpload}
//                           />
//                           <button
//                             type="button"
//                             className="btn btn-primary"
//                             onClick={triggerFileInput}
//                           >
//                             Upload Image
//                           </button>
//                         </div>
//                       </div>
//                     </div>
//                     <div className="col-md-6 col-sm-12">
//                       <div className="mb-3">
//                         <label htmlFor="firstName" className="form-label">
//                           First Name
//                         </label>
//                         <input
//                           type="text"
//                           className="form-control form-modal"
//                           id="firstName"
//                           value={formData.firstName}
//                           onChange={handleInputChange1}
//                           onBlur={validateName}
//                         />
//                         {formErrors.firstName && (
//                           <small className="text-danger">
//                             {formErrors.firstName}
//                           </small>
//                         )}
//                       </div>
//                       <div className="mb-3">
//                         <label htmlFor="lastName" className="form-label">
//                           Last Name
//                         </label>
//                         <input
//                           type="text"
//                           className="form-control form-modal"
//                           id="lastName"
//                           value={formData.lastName}
//                           onChange={handleInputChange1}
//                           onBlur={validateName}
//                         />
//                         {formErrors.lastName && (
//                           <small className="text-danger">
//                             {formErrors.lastName}
//                           </small>
//                         )}
//                       </div>
//                       <div className="mb-3">
//                         <label htmlFor="dob" className="form-label">
//                           Date of Birth
//                         </label>
//                         <input
//                           type="date"
//                           className="form-control form-modal"
//                           id="dob"
//                           value={formData.dob}
//                           onChange={handleInputChange1}
//                           max={new Date().toISOString().split("T")[0]}
//                           // max={new Date(Date.now() - 86400000).toISOString().split("T")[0]}
//                         />
//                         {formErrors.dob && (
//                           <small className="text-danger">
//                             {formErrors.dob}
//                           </small>
//                         )}
//                       </div>
//                     </div>
//                     <div className="col-md-6 col-sm-12">
//                       {" "}
//                       <div className="mb-3">
//                         <label htmlFor="gender" className="form-label">
//                           Gender
//                         </label>
//                         <select
//                           className="form-select"
//                           id="gender"
//                           value={formData.gender}
//                           onChange={handleInputChange1}
//                         >
//                           <option value="None">Select Gender</option>
//                           <option value="Male">Male</option>
//                           <option value="Female">Female</option>
//                           <option value="Other">Other</option>
//                         </select>

//                         {formErrors.gender && (
//                           <small className="text-danger">
//                             {formErrors.gender}
//                           </small>
//                         )}
//                       </div>
//                     </div>
//                     <div className="col-md-6 col-sm-12">
//                       {" "}
//                       <div className="mb-3">
//                         <label htmlFor="location" className="form-label">
//                           Location
//                         </label>
//                         <select
//                           className="form-select"
//                           id="location"
//                           name="location"
//                           value={formData.location || ""}
//                           onChange={handleInputChange1}
//                         >
//                           <option value="">
//                             Select Location
//                           </option>
//                           {locations.map((location) => (
//                             <option key={location.id} value={location?._id}>
//                               {location?.cityName}
//                             </option>
//                           ))}
//                         </select>
//                         {formErrors.location && (
//                           <small className="text-danger">
//                             {formErrors.location}
//                           </small>
//                         )}
//                       </div>
//                     </div>
//                     <div className="col-md-12">
//                       <div className="mb-3">
//                         <label htmlFor="address" className="form-label">
//                           Address
//                         </label>
//                         <textarea
//                           className="form-control form-modal"
//                           id="address"
//                           rows="3"
//                           value={formData.address}
//                           onChange={handleInputChange1}
//                         ></textarea>

//                         {formErrors.address && (
//                           <small className="text-danger">
//                             {formErrors.address}
//                           </small>
//                         )}
//                       </div>
//                     </div>
//                   </div>
//                 </form>
//                 <hr />
//                 <h5 className="mb-3">Change Password</h5>
//                 <form onSubmit={handleChangePass}>
//                   <div className="row">
//                     <div className="col-md-6 col-sm-12">
//                       <div className="mb-3">
//                         <label htmlFor="password" className="form-label">
//                           New Password
//                         </label>
//                         <input
//                           type="password"
//                           className="form-control form-modal"
//                           id="password"
//                           value={formData.password}
//                           onChange={handleInputChange1}
//                         />
//                         {formErrors.password && (
//                           <small className="text-danger">
//                             {formErrors.password}
//                           </small>
//                         )}
//                       </div>
//                     </div>
//                     <div className="col-md-6 col-sm-12">
//                       <div className="mb-3">
//                         <label htmlFor="confirmPassword" className="form-label">
//                           Confirm Password
//                         </label>
//                         <input
//                           type="password"
//                           className="form-control form-modal"
//                           id="confirmPassword"
//                           value={formData.confirmPassword}
//                           onChange={handleInputChange1}
//                         />
//                         {formErrors.confirmPassword && (
//                           <small className="text-danger">
//                             {formErrors.confirmPassword}
//                           </small>
//                         )}
//                       </div>
//                     </div>
//                   </div>
//                   <button
//                     type="submit"
//                     className="btn btn-primary student-profile-btn1"
//                     disabled={loadingpass}
//                     onClick={handleChangePass}
//                   >
//                     {loadingpass ? (
//                       <span
//                         className="spinner-border spinner-border-sm"
//                         role="status"
//                         aria-hidden="true"
//                       ></span>
//                     ) : (
//                       "Change Password"
//                     )}
//                   </button>
//                 </form>
//               </div>
//               <div className="modal-footer">
//                 <button
//                   type="button"
//                   className="btn btn-light student-profile-btn2"
//                   onClick={closePersonlForm}
//                 >
//                   Cancel
//                 </button>
//                 <button
//                   type="submit"
//                   className="btn btn-primary student-profile-btn1"
//                   onClick={handleSubmit}
//                   disabled={loading}
//                 >
//                   {loading ? (
//                     <span
//                       className="spinner-border spinner-border-sm"
//                       role="status"
//                       aria-hidden="true"
//                     ></span>
//                   ) : (
//                     "Save changes"
//                   )}
//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}

//       {/* EducationDetailsForm */}
//       {showEducationForm && (
//         <div
//           className="modal show d-block bd-example-modal-lg"
//           tabIndex="-1"
//           style={{ backdropFilter: "brightness(0.5)" }}
//         >
//           <div className="modal-dialog modal-lg">
//             <div className="modal-content">
//               <div className="modal-header custom-modal-header">
//                 <h5 className="modal-title">Education Details</h5>
//                 <button
//                   type="button"
//                   className="btn-close"
//                   aria-label="Close"
//                   onClick={closePersonlForm1}
//                 ></button>
//                 {/* <p>
//                   Add your education details like course ,university and more
//                 </p> */}
//               </div>
//               <div className="modal-body">
//                 <form onSubmit={handleSubmit1}>
//                   {/* <div className="mb-3">
//                     <label htmlFor="education" className="form-label">
//                       Education
//                     </label>
//                     <input
//                       type="text"
//                       className="form-control form-modal"
//                       id="education"
//                     />
//                   </div> */}
//                   <div className="mb-3">
//                     <label htmlFor="education" className="form-label">
//                       Education
//                     </label>
//                     <select
//                       className="form-control form-modal"
//                       id="education"
//                       value={formData.education}
//                       onChange={handleInputChange1}
//                       required
//                     >
//                       <option value="">Select Education</option>
//                       <option value="Graduation">Graduation</option>
//                       <option value="12th Board">12th Board</option>
//                       <option value="10th Board">10th Board</option>
//                     </select>
//                     {errors.education && (
//                       <div className="error text-danger">
//                         {errors.education}
//                       </div>
//                     )}
//                   </div>

//                   <div className="mb-3">
//                     <label htmlFor="university" className="form-label">
//                       University/Institute
//                     </label>
//                     <select
//                       className="form-control form-modal"
//                       id="institute"
//                       value={formData.institute}
//                       onChange={handleInputChange1}
//                       required
//                     >
//                       <option value="">Select University</option>
//                       <option value="Rashtrasant Tukadoji Maharaj Nagpur University">
//                         Rashtrasant Tukadoji Maharaj Nagpur University
//                       </option>
//                       <option value="Sant Gadge Baba Amravati University (SGBAU)">
//                         Sant Gadge Baba Amravati University (SGBAU)
//                       </option>
//                       <option value="other">Other</option>
//                     </select>
//                     {errors.institute && (
//                       <div className="error text-danger">
//                         {errors.institute}
//                       </div>
//                     )}
//                   </div>

//                   <div className="mb-3">
//                     <label htmlFor="course" className="form-label">
//                       Course
//                     </label>
//                     <select
//                       className="form-control form-modal"
//                       id="course"
//                       value={formData.course}
//                       onChange={handleInputChange1}
//                       required
//                     >
//                       <option value="">Select Course</option>
//                       <option value="Bachelor of Science (B.Sc)">
//                         Bachelor of Science (B.Sc)
//                       </option>
//                       <option value="Bachelor of Arts (B.A)">
//                         Bachelor of Arts (B.A)
//                       </option>
//                       <option value="Bachelor of Commerce (B.Com)">
//                         Bachelor of Commerce (B.Com)
//                       </option>
//                       <option value="other">Other</option>
//                     </select>
//                     {errors.course && (
//                       <div className="error text-danger">{errors.course}</div>
//                     )}
//                   </div>

//                   <div className="mb-3">
//                     <label htmlFor="specialization" className="form-label">
//                       Specialization
//                     </label>
//                     <select
//                       className="form-control form-modal"
//                       id="specialization"
//                       value={formData.specialization}
//                       onChange={handleInputChange1}
//                       required
//                     >
//                       <option value="">Select Specialization</option>
//                       <option value="CS">Computer Science</option>
//                       <option value="math">Information Technology</option>
//                       <option value="physics">Physics</option>
//                       <option value="other">Other</option>
//                     </select>
//                     {errors.specialization && (
//                       <div className="error text-danger">
//                         {errors.specialization}
//                       </div>
//                     )}
//                   </div>

//                   <div className="mb-3">
//                     <label htmlFor="courseDuration" className="form-label">
//                       Course Duration
//                     </label>
//                     <div className="row">
//                       <div className="col">
//                         <input
//                           type="date"
//                           className="form-control form-modal"
//                           id="start_date"
//                           value={formData.start_date}
//                           onChange={handleInputChange1}
//                           placeholder="Start Date"
//                           required
//                         />
//                         {errors.start_date && (
//                           <div className="error text-danger">
//                             {errors.start_date}
//                           </div>
//                         )}
//                       </div>
//                       <div className="col">
//                         <input
//                           type="date"
//                           className="form-control form-modal"
//                           id="end_date"
//                           value={formData.end_date}
//                           onChange={handleInputChange1}
//                           placeholder="End Date"
//                           required
//                         />
//                         {errors.end_date && (
//                           <div className="error text-danger">
//                             {errors.end_date}
//                           </div>
//                         )}
//                       </div>
//                     </div>
//                   </div>

//                   <div className="mb-3">
//                     <label htmlFor="gradingSystem" className="form-label">
//                       Grading System
//                     </label>
//                     <select
//                       className="form-control form-modal"
//                       id="grading_system"
//                       value={formData.grading_system}
//                       onChange={handleInputChange1}
//                       required
//                     >
//                       <option value="">Select Grading System</option>
//                       <option value="Percentage">Percentage</option>
//                       <option value="CGPA">CGPA</option>
//                       <option value="Pass/Fail">Pass/Fail</option>
//                     </select>
//                     {errors.grading_system && (
//                       <div className="error text-danger">
//                         {errors.grading_system}
//                       </div>
//                     )}
//                   </div>

//                   <div className="mb-3">
//                     <label htmlFor="marks" className="form-label">
//                       Marks
//                     </label>
//                     <input
//                       type="text"
//                       className="form-control form-modal"
//                       id="marks"
//                       value={formData.marks}
//                       onChange={handleInputChange1}
//                       placeholder="Enter Marks"
//                       required
//                     />
//                     {errors.marks && (
//                       <div className="error text-danger">{errors.marks}</div>
//                     )}
//                   </div>

//                   <div className="mb-3">
//                     {/* <div className="upload-btn-edu">
//                       <input
//                         type="file"
//                         id="hiddenFileInput"
//                         style={{ display: "none" }}
//                         onChange={handleImageUpload}
//                       />
//                       <button
//                         type="button"
//                         className="btn upload-btn-file"
//                         onClick={triggerFileInput}
//                       >
//                         Upload
//                       </button>

//                       <p>Upload hard copy of marksheet here</p>
//                     </div> */}
//                     <div>
//                       <label htmlFor="attachment" className="form-label">
//                         <h5>Documents</h5>
//                       </label>
//                       {isLoading ? (
//                         <div>Loading...</div>
//                       ) : (
//                         file && (
//                           <>
//                             <div className="pb-2">
//                               <a
//                                 href={file}
//                                 className="file-link text-decoration-none text-dark"
//                                 target="_blank"
//                                 rel="noopener noreferrer"
//                               >
//                                 <h5 className="docul-name">
//                                   {uploadFile?.originalname}
//                                 </h5>
//                               </a>
//                             </div>
//                           </>
//                         )
//                       )}

//                       <div className="upload-btn-edu">
//                         <input
//                           type="file"
//                           id="hiddenFileInput"
//                           style={{ display: "none" }}
//                           onChange={handleFileUpload}
//                         />
//                         <button
//                           type="button"
//                           className="btn upload-btn-file"
//                           onClick={triggerFileInput}
//                         >
//                           Upload Document
//                         </button>

//                         <p className="upl-text">
//                           Supported Formats: doc, pdf, upto 2 MB
//                         </p>
//                       </div>
//                     </div>
//                   </div>
//                 </form>
//               </div>
//               <div className="modal-footer">
//                 <button
//                   type="button"
//                   className="btn btn-light student-profile-btn2"
//                   onClick={closePersonlForm1}
//                 >
//                   Cancel
//                 </button>
//                 <button
//                   type="submit"
//                   className="btn btn-primary student-profile-btn1"
//                   onClick={handleSubmit1}
//                   disabled={loading}
//                 >
//                   {loading ? (
//                     <span
//                       className="spinner-border spinner-border-sm"
//                       role="status"
//                       aria-hidden="true"
//                     ></span>
//                   ) : (
//                     "Add"
//                   )}
//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}

//       {/* showSkillsForm */}
//       {showSkillsForm && (
//         <div
//           className="modal show d-block"
//           tabIndex="-1"
//           style={{ backdropFilter: "brightness(0.5)" }}
//         >
//           <div className="modal-dialog">
//             <div className="modal-content">
//               <div className="modal-header">
//                 <h5 className="modal-title">Skills Details</h5>
//                 <button
//                   type="button"
//                   className="btn-close"
//                   aria-label="Close"
//                   onClick={closePersonlForm2}
//                 ></button>
//               </div>
//               <div className="modal-body">
//                 <form onSubmit={handleSubmitSkill}>

//                   <div className="mb-3">
//                     <label htmlFor="location" className="form-label">
//                       Select Skills
//                     </label>
//                     <select
//                       className="form-select"
//                       id="skills"
//                       name="skills"
//                       value={formData.selectedSkill}
//                       onChange={handleInputChange1}
//                     >
//                       <option value="">Select Skills</option>
//                       {skillsmain.map((skill) => (
//                         <option key={skill.id} value={skill._id}>
//                           {skill.skillname}
//                         </option>
//                       ))}
//                     </select>
//                   </div>
//                   {error && (
//                     <div className="error-message text-danger">{error}</div>
//                   )}

//                 </form>
//               </div>
//               <div className="modal-footer">
//                 <button
//                   type="button"
//                   className="btn btn-light student-profile-btn2"
//                   onClick={closePersonlForm2}
//                 >
//                   Cancel
//                 </button>
//                 <button
//                   type="submit"
//                   className="btn btn-primary student-profile-btn1"
//                   onClick={handleSubmitSkill}
//                   disabled={loading}
//                 >
//                   {loading ? (
//                     <span
//                       className="spinner-border spinner-border-sm"
//                       role="status"
//                       aria-hidden="true"
//                     ></span>
//                   ) : (
//                     "Add"
//                   )}
//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}

//       {/* showCertificationForm */}
//       {showCertificationForm && (
//         <div
//           className="modal show d-block"
//           tabIndex="-1"
//           aria-labelledby="myLargeModalLabel"
//           style={{ backdropFilter: "brightness(0.5)" }}
//         >
//           <div className="modal-dialog modal-lg">
//             <div className="modal-content">
//               <div className="modal-header custom-modal-header">
//                 <h5 className="modal-title">Certification</h5>
//                 <button
//                   type="button"
//                   className="btn-close"
//                   aria-label="Close"
//                   onClick={closePersonlForm3}
//                 ></button>
//                 <p>Add details of Certifications you have achieved/completed</p>
//               </div>
//               <div className="modal-body">
//                 <form>
//                   <div className="mb-3">
//                     <label htmlFor="name" className="form-label">
//                       Certification name
//                     </label>
//                     <input
//                       type="text"
//                       className="form-control form-modal"
//                       id="name"
//                       value={formData.name}
//                       onChange={handleInputChange1}
//                       required
//                     />
//                     {errors.name && (
//                       <div className="error text-danger">{errors.name}</div>
//                     )}
//                   </div>
//                   <div className="mb-3">
//                     <label htmlFor="provider" className="form-label">
//                       Certification provider
//                     </label>
//                     <input
//                       type="text"
//                       className="form-control form-modal"
//                       id="provider"
//                       value={formData.provider}
//                       onChange={handleInputChange1}
//                       required
//                     />
//                     {errors.provider && (
//                       <div className="error text-danger">{errors.provider}</div>
//                     )}
//                   </div>

//                   <div className="mb-3">
//                     <label htmlFor="cID" className="form-label">
//                       Certification completion ID{" "}
//                       <span className="opt">
//                         <i>(optional)</i>
//                       </span>
//                     </label>
//                     <input
//                       type="text"
//                       className="form-control form-modal"
//                       id="cID"
//                       value={formData.cID}
//                       onChange={handleInputChange1}
//                     />
//                   </div>

//                   <div className="mb-3">
//                     <label htmlFor="url" className="form-label">
//                       Certification URL
//                     </label>
//                     <input
//                       type="text"
//                       className="form-control form-modal"
//                       id="url"
//                       value={formData.url}
//                       onChange={handleInputChange1}
//                     />
//                   </div>

//                   <div className="mb-3">
//                     <label htmlFor="courseDuration" className="form-label">
//                       Certification validity
//                     </label>
//                     <div className="row">
//                       <div className="col">
//                         <input
//                           type="date"
//                           className="form-control form-modal"
//                           id="start_date"
//                           value={formData.start_date}
//                           onChange={handleInputChange1}
//                           placeholder="Start Date"
//                         />
//                         {errors.start_date && (
//                           <div className="error text-danger">
//                             {errors.start_date}
//                           </div>
//                         )}
//                       </div>
//                       <div className="col">
//                         {!formData.expiry && (
//                           <input
//                             type="date"
//                             className="form-control form-modal"
//                             id="end_date"
//                             value={formData.end_date}
//                             onChange={handleInputChange1}
//                             placeholder="End Date"
//                           />
//                         )}
//                         {errors.end_date && (
//                           <div className="error text-danger">
//                             {errors.end_date}
//                           </div>
//                         )}
//                       </div>
//                     </div>
//                   </div>
//                   <div className="mb-3 form-check">
//                     <input
//                       type="checkbox"
//                       className="form-check-input"
//                       id="expiry"
//                       checked={!!formData.expiry}
//                       onChange={handleInputChange1}
//                     />
//                     <label className="form-check-label" htmlFor="expiry">
//                       This certification does not expire
//                     </label>
//                   </div>

//                   <div>
//                     <label htmlFor="attachment" className="form-label">
//                       <h5>Documents</h5>
//                     </label>
//                     {isLoading ? (
//                       <div>Loading...</div>
//                     ) : (
//                       file && (
//                         <>
//                           <div className="pb-2">
//                             <a
//                               href={file}
//                               className="file-link text-decoration-none text-dark"
//                               target="_blank"
//                               rel="noopener noreferrer"
//                             >
//                               <h5 className="docul-name">
//                                 {uploadFile?.originalname}
//                               </h5>
//                             </a>
//                           </div>
//                         </>
//                       )
//                     )}

//                     <div className="upload-btn-edu">
//                       <input
//                         type="file"
//                         id="hiddenFileInput"
//                         style={{ display: "none" }}
//                         onChange={handleFileUpload}
//                       />
//                       <button
//                         type="button"
//                         className="btn upload-btn-file"
//                         onClick={triggerFileInput}
//                       >
//                         Upload Document
//                       </button>

//                       <p className="upl-text">
//                         Supported Formats: doc, pdf, upto 2 MB
//                       </p>
//                     </div>
//                   </div>

//                   {/* <div className="mb-3">
//                     <label htmlFor="description" className="form-label">
//                       Certificate Description
//                     </label>
//                     <textarea
//                       className="form-control form-modal"
//                       id="description"
//                       rows="3"
//                     ></textarea>
//                   </div> */}
//                 </form>
//               </div>
//               <div className="modal-footer">
//                 <button
//                   type="button"
//                   className="btn btn-light student-profile-btn2"
//                   onClick={closePersonlForm3}
//                 >
//                   Cancel
//                 </button>
//                 <button
//                   type="submit"
//                   className="btn btn-primary student-profile-btn1"
//                   onClick={handleCertificate}
//                   disabled={loading}
//                 >
//                   {loading ? (
//                     <span
//                       className="spinner-border spinner-border-sm"
//                       role="status"
//                       aria-hidden="true"
//                     ></span>
//                   ) : (
//                     "Add"
//                   )}
//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}

//       {/* showSocialForm */}
//       {showSocialForm && (
//         <div
//           className="modal show d-block"
//           tabIndex="-1"
//           style={{ backdropFilter: "brightness(0.5)" }}
//         >
//           <div className="modal-dialog modal-lg">
//             <div className="modal-content">
//               <div className="modal-header custom-modal-header">
//                 <h5 className="modal-title">Online profile</h5>
//                 <button
//                   type="button"
//                   className="btn-close"
//                   aria-label="Close"
//                   onClick={closePersonlForm4}
//                 ></button>
//                 <p>
//                   Add link to online professional profile (e.g LinkedIn, etc.)
//                 </p>
//               </div>
//               <div className="modal-body">
//                 <form>
//                   <div className="mb-3">
//                     <label htmlFor="name" className="form-label">
//                       Social profile name
//                     </label>
//                     <input
//                       type="text"
//                       className="form-control form-modal"
//                       id="name"
//                       value={formData.name}
//                       onChange={handleInputChange1}
//                       required
//                     />
//                     {errors.name && (
//                       <div className="error text-danger">{errors.name}</div>
//                     )}
//                   </div>

//                   <div className="mb-3">
//                     <label htmlFor="url" className="form-label">
//                       URL
//                     </label>
//                     <input
//                       type="text"
//                       className="form-control form-modal"
//                       id="url"
//                       value={formData.url}
//                       onChange={handleInputChange1}
//                       required
//                     />
//                     {errors.url && (
//                       <div className="error text-danger">{errors.url}</div>
//                     )}
//                   </div>
//                   <div className="mb-3">
//                     <label htmlFor="description" className="form-label">
//                       Description{" "}
//                       <span className="opt">
//                         <i>(optional)</i>
//                       </span>
//                     </label>
//                     <textarea
//                       className="form-control form-modal"
//                       id="description"
//                       rows="4"
//                       value={formData.description}
//                       onChange={handleInputChange1}
//                     ></textarea>
//                   </div>
//                 </form>
//               </div>
//               <div className="modal-footer">
//                 <button
//                   type="button"
//                   className="btn btn-light student-profile-btn2"
//                   onClick={closePersonlForm4}
//                 >
//                   Cancel
//                 </button>
//                 <button
//                   type="submit"
//                   className="btn btn-primary student-profile-btn1"
//                   onClick={handlesociallinks}
//                   disabled={loading}
//                 >
//                   {loading ? (
//                     <span
//                       className="spinner-border spinner-border-sm"
//                       role="status"
//                       aria-hidden="true"
//                     ></span>
//                   ) : (
//                     "Add"
//                   )}
//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}

//       {/* For Edit Modal*/}

//       {/* Edit Certification */}
//       {showEditCertificationForm && (
//         <div
//           className="modal show d-block"
//           tabIndex="-1"
//           style={{ backdropFilter: "brightness(0.5)" }}
//         >
//           <div className="modal-dialog modal-lg">
//             <div className="modal-content">
//               <div className="modal-header">
//                 <h5 className="modal-title">Certification</h5>
//                 <button
//                   type="button"
//                   className="btn-close"
//                   aria-label="Close"
//                   onClick={closeCertificateEdit}
//                 ></button>
//               </div>
//               <div className="modal-body">
//                 <form
//                   onSubmit={(event) =>
//                     handleEditCertificate(event, formData.cId)
//                   }
//                 >
//                   <div className="mb-3">
//                     <label htmlFor="name" className="form-label">
//                       Certification name
//                     </label>
//                     <input
//                       type="text"
//                       className="form-control form-modal"
//                       id="name"
//                       value={formData.name}
//                       onChange={handleInputChange1}
//                       required
//                     />
//                     {errors.name && (
//                       <div className="error text-danger">{errors.name}</div>
//                     )}
//                   </div>
//                   <div className="mb-3">
//                     <label htmlFor="provider" className="form-label">
//                       Certification provider
//                     </label>
//                     <input
//                       type="text"
//                       className="form-control form-modal"
//                       id="provider"
//                       value={formData.provider}
//                       onChange={handleInputChange1}
//                       required
//                     />
//                     {errors.provider && (
//                       <div className="error text-danger">{errors.provider}</div>
//                     )}
//                   </div>

//                   <div className="mb-3">
//                     <label htmlFor="cID" className="form-label">
//                       Certification completion ID{" "}
//                       <span className="opt">
//                         <i>(optional)</i>
//                       </span>
//                     </label>
//                     <input
//                       type="text"
//                       className="form-control form-modal"
//                       id="cID"
//                       value={formData.cID}
//                       onChange={handleInputChange1}
//                     />
//                   </div>

//                   <div className="mb-3">
//                     <label htmlFor="url" className="form-label">
//                       Certification URL
//                     </label>
//                     <input
//                       type="text"
//                       className="form-control form-modal"
//                       id="url"
//                       value={formData.url}
//                       onChange={handleInputChange1}
//                     />
//                   </div>

//                   <div className="mb-3">
//                     <label htmlFor="courseDuration" className="form-label">
//                       Certification validity
//                     </label>
//                     <div className="row">
//                       <div className="col">
//                         <input
//                           type="date"
//                           className="form-control form-modal"
//                           id="start_date"
//                           value={formData.start_date}
//                           onChange={handleInputChange1}
//                           placeholder="Start Date"
//                         />
//                       </div>
//                       <div className="col">
//                         {!formData.expiry && (
//                           <input
//                             type="date"
//                             className="form-control form-modal"
//                             id="end_date"
//                             value={formData.end_date || null}
//                             onChange={handleInputChange1}
//                             placeholder="End Date"
//                           />
//                         )}
//                         {/* {errors.end_date && (
//                           <div className="error text-danger">
//                             {errors.end_date}
//                           </div>
//                         )} */}
//                       </div>
//                     </div>
//                   </div>
//                   <div className="mb-3 form-check">
//                     <input
//                       type="checkbox"
//                       className="form-check-input"
//                       id="expiry"
//                       checked={!!formData.expiry}
//                       onChange={handleInputChange1}
//                     />
//                     <label className="form-check-label" htmlFor="expiry">
//                       This certification does not expire
//                     </label>
//                   </div>

//                   {/* <div className="mb-3">
//                     <label htmlFor="description" className="form-label">
//                       Certificate Description
//                     </label>
//                     <textarea
//                       className="form-control form-modal"
//                       id="description"
//                       rows="3"
//                     ></textarea>
//                   </div> */}

//                   <div className="mb-3">
//                     <label htmlFor="attachment" className="form-label">
//                       <h5>Documents</h5>
//                     </label>
//                     <div>
//                       {formData.attachment &&
//                       formData.attachment !== "No Data" &&
//                       !file ? (
//                         <div className="mb-3">
//                           <img
//                             src={formData.attachment.path}
//                             className="mx-auto square-doc-img"
//                             alt="No Attachment"
//                           />
//                           <button
//                             className="btn mt-5 mx-2"
//                             onClick={handleRemoveAttachment}
//                           >
//                             <i className="bi bi-trash icn"></i>
//                           </button>
//                         </div>
//                       ) : (
//                         <p></p>
//                       )}
//                     </div>
//                     {isLoading ? (
//                       <div>Loading...</div>
//                     ) : (
//                       file && (
//                         <>
//                           <div className="pb-2">
//                             <a
//                               href={file}
//                               className="file-link text-decoration-none text-dark"
//                               target="_blank"
//                               rel="noopener noreferrer"
//                             >
//                               <div className="editEdu-filediv-icon">
//                                 <h5 className="docul-name">
//                                   {uploadFile?.originalname}
//                                 </h5>
//                                 <div>
//                                   {/* <button className="btn">
//                             <i className="bi bi-download icn"></i>
//                           </button> */}
//                                   {/* <button
//                             className="btn"
//                             onClick={handleFileRemove}
//                           >
//                             <i className="bi bi-trash icn"></i>
//                           </button> */}
//                                 </div>
//                               </div>
//                             </a>
//                           </div>
//                         </>
//                       )
//                     )}

//                     <div className="upload-btn-edu">
//                       <input
//                         type="file"
//                         id="hiddenFileInput"
//                         style={{ display: "none" }}
//                         onChange={handleFileUpload}
//                       />
//                       <button
//                         type="button"
//                         className="btn upload-btn-file"
//                         onClick={triggerFileInput}
//                       >
//                         Update document
//                       </button>

//                       <p className="upl-text">
//                         Supported Formats: doc, pdf, up to 2 MB
//                       </p>
//                     </div>
//                   </div>
//                 </form>
//               </div>
//               <div className="modal-footer">
//                 <button
//                   type="button"
//                   className="btn btn-light student-profile-btn2"
//                   onClick={closeCertificateEdit}
//                 >
//                   Cancel
//                 </button>
//                 <button
//                   type="submit"
//                   className="btn btn-primary student-profile-btn1"
//                   onClick={(event) =>
//                     handleEditCertificate(event, formData.cId)
//                   }
//                   disabled={loading}
//                 >
//                   {loading ? (
//                     <span
//                       className="spinner-border spinner-border-sm"
//                       role="status"
//                       aria-hidden="true"
//                     ></span>
//                   ) : (
//                     "Save changes"
//                   )}
//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}

//       {/* Edit Social Media */}
//       {showEditSocialForm && (
//         <div
//           className="modal show d-block"
//           tabIndex="-1"
//           style={{ backdropFilter: "brightness(0.5)" }}
//         >
//           <div className="modal-dialog modal-lg">
//             <div className="modal-content">
//               <div className="modal-header custom-modal-header">
//                 <h5 className="modal-title">Online profile</h5>
//                 <button
//                   type="button"
//                   className="btn-close"
//                   aria-label="Close"
//                   onClick={closeSocialFormEdit}
//                 ></button>
//                 <p>
//                   Add link to online professional profile (e.g LinkedIn, etc.)
//                 </p>
//               </div>
//               <div className="modal-body">
//                 <form
//                   onSubmit={(event) =>
//                     handlEditSocialLinks(event, formData.smId)
//                   }
//                 >
//                   <div className="mb-3">
//                     <label htmlFor="name" className="form-label">
//                       Social profile name
//                     </label>
//                     <input
//                       type="text"
//                       className="form-control form-modal"
//                       id="name"
//                       value={formData.name}
//                       onChange={handleInputChange1}
//                       required
//                     />
//                     {errors.name && (
//                       <div className="error text-danger">{errors.name}</div>
//                     )}
//                   </div>

//                   <div className="mb-3">
//                     <label htmlFor="url" className="form-label">
//                       URL
//                     </label>
//                     <input
//                       type="text"
//                       className="form-control form-modal"
//                       id="url"
//                       value={formData.url}
//                       onChange={handleInputChange1}
//                       required
//                     />
//                     {errors.url && (
//                       <div className="error text-danger">{errors.url}</div>
//                     )}
//                   </div>
//                   <div className="mb-3">
//                     <label htmlFor="description" className="form-label">
//                       Description{" "}
//                       <span className="opt">
//                         <i>(optional)</i>
//                       </span>
//                     </label>
//                     <textarea
//                       className="form-control form-modal"
//                       id="description"
//                       rows="4"
//                       value={formData.description}
//                       onChange={handleInputChange1}
//                     ></textarea>
//                   </div>
//                 </form>
//               </div>
//               <div className="modal-footer">
//                 <button
//                   type="button"
//                   className="btn btn-light student-profile-btn2"
//                   onClick={closeSocialFormEdit}
//                 >
//                   Cancel
//                 </button>
//                 <button
//                   type="submit"
//                   className="btn btn-primary student-profile-btn1"
//                   onClick={(event) =>
//                     handlEditSocialLinks(event, formData.smId)
//                   }
//                   disabled={loading}
//                 >
//                   {loading ? (
//                     <span
//                       className="spinner-border spinner-border-sm"
//                       role="status"
//                       aria-hidden="true"
//                     ></span>
//                   ) : (
//                     "Save changes"
//                   )}
//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}

//       {/* EducationDetailsForm */}
//       {showEditEducationForm && (
//         <div
//           className="modal show d-block bd-example-modal-lg"
//           tabIndex="-1"
//           style={{ backdropFilter: "brightness(0.5)" }}
//         >
//           <div className="modal-dialog modal-lg">
//             <div className="modal-content">
//               <div className="modal-header custom-modal-header">
//                 <h5 className="modal-title">Education Details</h5>
//                 <button
//                   type="button"
//                   className="btn-close"
//                   aria-label="Close"
//                   onClick={closePersonFormEdit}
//                 ></button>
//                 {/* <p>
//                   Edit your education details like course, university, and more
//                 </p> */}
//               </div>
//               <div className="modal-body">
//                 <form
//                   onSubmit={(event) => handleSubmit3(event, formData.eduId)}
//                 >
//                   <div className="mb-3">
//                     <label htmlFor="education" className="form-label">
//                       Education
//                     </label>
//                     <select
//                       className="form-control form-modal"
//                       id="education"
//                       value={formData.education}
//                       onChange={handleInputChange1}
//                       required
//                     >
//                       <option value="">Select Education</option>
//                       <option value="Graduation">Graduation</option>
//                       <option value="12th Board">12th Board</option>
//                       <option value="10th Board">10th Board</option>
//                     </select>
//                     {errors.education && (
//                       <div className="error text-danger">
//                         {errors.education}
//                       </div>
//                     )}
//                   </div>

//                   <div className="mb-3">
//                     <label htmlFor="university" className="form-label">
//                       University/Institute
//                     </label>
//                     <select
//                       className="form-control form-modal"
//                       id="institute"
//                       value={formData.institute}
//                       onChange={handleInputChange1}
//                       required
//                     >
//                       <option value="">Select University</option>
//                       <option value="Rashtrasant Tukadoji Maharaj Nagpur University">
//                         Rashtrasant Tukadoji Maharaj Nagpur University
//                       </option>
//                       <option value="Sant Gadge Baba Amravati University (SGBAU)">
//                         Sant Gadge Baba Amravati University (SGBAU)
//                       </option>
//                       <option value="other">Other</option>
//                     </select>
//                     {errors.institute && (
//                       <div className="error text-danger">
//                         {errors.institute}
//                       </div>
//                     )}
//                   </div>

//                   <div className="mb-3">
//                     <label htmlFor="course" className="form-label">
//                       Course
//                     </label>
//                     <select
//                       className="form-control form-modal"
//                       id="course"
//                       value={formData.course}
//                       onChange={handleInputChange1}
//                       required
//                     >
//                       <option value="">Select Course</option>
//                       <option value="Bachelor of Science (B.Sc)">
//                         Bachelor of Science (B.Sc)
//                       </option>
//                       <option value="Bachelor of Arts (B.A)">
//                         Bachelor of Arts (B.A)
//                       </option>
//                       <option value="Bachelor of Commerce (B.Com)">
//                         Bachelor of Commerce (B.Com)
//                       </option>
//                       <option value="other">Other</option>
//                     </select>
//                     {errors.course && (
//                       <div className="error text-danger">{errors.course}</div>
//                     )}
//                   </div>

//                   <div className="mb-3">
//                     <label htmlFor="specialization" className="form-label">
//                       Specialization
//                     </label>
//                     <select
//                       className="form-control form-modal"
//                       id="specialization"
//                       value={formData.specialization}
//                       onChange={handleInputChange1}
//                       required
//                     >
//                       <option value="">Select Specialization</option>
//                       <option value="CS">Computer Science</option>
//                       <option value="math">Information Technology</option>
//                       <option value="physics">Physics</option>
//                       <option value="other">Other</option>
//                     </select>
//                     {errors.specialization && (
//                       <div className="error text-danger">
//                         {errors.specialization}
//                       </div>
//                     )}
//                   </div>

//                   <div className="mb-3">
//                     <label htmlFor="courseDuration" className="form-label">
//                       Course Duration
//                     </label>
//                     <div className="row">
//                       <div className="col">
//                         <input
//                           type="date"
//                           className="form-control form-modal"
//                           id="start_date"
//                           value={formData.start_date}
//                           onChange={handleInputChange1}
//                           placeholder="Start Date"
//                           required
//                         />
//                         {errors.start_date && (
//                           <div className="error text-danger">
//                             {errors.start_date}
//                           </div>
//                         )}
//                       </div>
//                       <div className="col">
//                         <input
//                           type="date"
//                           className="form-control form-modal"
//                           id="end_date"
//                           value={formData.end_date}
//                           onChange={handleInputChange1}
//                           placeholder="End Date"
//                           required
//                         />
//                         {errors.end_date && (
//                           <div className="error text-danger">
//                             {errors.end_date}
//                           </div>
//                         )}
//                       </div>
//                     </div>
//                   </div>

//                   <div className="mb-3">
//                     <label htmlFor="gradingSystem" className="form-label">
//                       Grading System
//                     </label>
//                     <select
//                       className="form-control form-modal"
//                       id="grading_system"
//                       value={formData.grading_system}
//                       onChange={handleInputChange1}
//                       required
//                     >
//                       <option value="">Select Grading System</option>
//                       <option value="Percentage">Percentage</option>
//                       <option value="CGPA">CGPA</option>
//                       <option value="Pass/Fail">Pass/Fail</option>
//                     </select>
//                     {errors.grading_system && (
//                       <div className="error text-danger">
//                         {errors.grading_system}
//                       </div>
//                     )}
//                   </div>

//                   <div className="mb-3">
//                     <label htmlFor="marks" className="form-label">
//                       Marks
//                     </label>
//                     <input
//                       type="text"
//                       className="form-control form-modal"
//                       id="marks"
//                       value={formData.marks}
//                       onChange={handleInputChange1}
//                       placeholder="Enter Marks"
//                       required
//                     />
//                     {errors.marks && (
//                       <div className="error text-danger">{errors.marks}</div>
//                     )}
//                   </div>

//                   <div className="mb-3">
//                     <label htmlFor="attachment" className="form-label">
//                       <h5>Documents</h5>
//                     </label>
//                     <div>
//                       {formData.attachment &&
//                       formData.attachment !== "No Data" &&
//                       !file ? (
//                         <div className="mb-3">
//                           <img
//                             src={formData.attachment.path}
//                             className="mx-auto square-doc-img"
//                             alt="No Attachment"
//                           />
//                           <button
//                             className="btn mt-5 mx-2"
//                             onClick={handleRemoveAttachment}
//                           >
//                             <i className="bi bi-trash icn"></i>
//                           </button>
//                         </div>
//                       ) : (
//                         <p></p>
//                       )}
//                     </div>
//                     {isLoading ? (
//                       <div>Loading...</div>
//                     ) : (
//                       file && (
//                         <>
//                           <div className="pb-2">
//                             <a
//                               href={file}
//                               className="file-link text-decoration-none text-dark"
//                               target="_blank"
//                               rel="noopener noreferrer"
//                             >
//                               <div className="editEdu-filediv-icon">
//                                 <h5 className="docul-name">
//                                   {uploadFile?.originalname}
//                                 </h5>
//                                 <div>
//                                   {/* <button className="btn">
//                             <i className="bi bi-download icn"></i>
//                           </button> */}
//                                   {/* <button
//                             className="btn"
//                             onClick={handleFileRemove}
//                           >
//                             <i className="bi bi-trash icn"></i>
//                           </button> */}
//                                 </div>
//                               </div>
//                             </a>
//                           </div>
//                         </>
//                       )
//                     )}

//                     <div className="upload-btn-edu">
//                       <input
//                         type="file"
//                         id="hiddenFileInput"
//                         style={{ display: "none" }}
//                         onChange={handleFileUpload}
//                       />
//                       <button
//                         type="button"
//                         className="btn upload-btn-file"
//                         onClick={triggerFileInput}
//                       >
//                         Update document
//                       </button>

//                       <p className="upl-text">
//                         Supported Formats: doc, pdf, up to 2 MB
//                       </p>
//                     </div>
//                   </div>
//                 </form>
//               </div>
//               <div className="modal-footer">
//                 <button
//                   type="button"
//                   className="btn btn-light student-profile-btn2"
//                   onClick={closePersonFormEdit}
//                 >
//                   Cancel
//                 </button>
//                 <button
//                   type="submit"
//                   className="btn btn-primary student-profile-btn1"
//                   onClick={(event) => handleSubmit3(event, formData.eduId)}
//                   disabled={loading}
//                 >
//                   {loading ? (
//                     <span
//                       className="spinner-border spinner-border-sm"
//                       role="status"
//                       aria-hidden="true"
//                     ></span>
//                   ) : (
//                     "Save changes"
//                   )}
//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}
//     </>
//   );
// }

// export default Studentprofile;

import React, { useEffect, useState } from "react";
import "./index.css";
import { HashLink } from "react-router-hash-link";
import img1 from "../../assets/usergrey.png";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../components/Loader";
import { useDispatch } from "react-redux";
import { saveStudentData } from "../../store/studentslice";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

function Studentprofile() {
  const [activeSection, setActiveSection] = useState("profile-overview");
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    const offset = 100;
    if (element) {
      const top = element.getBoundingClientRect().top + window.scrollY - offset;
      window.scrollTo({ top, behavior: "smooth" });
      setActiveSection(id);
    }
  };

  useEffect(() => {
    const sections = [
      "profile-overview",
      "personal-details",
      "education-details",
      "skills-details",
      "certification-details",
      "socialmedia-details",
    ];

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id);
          }
        });
      },
      {
        rootMargin: "0px 0px -50% 0px", // Adjust as needed
        threshold: 0.5,
      } // Adjust threshold to control when the section is considered "active"
    );

    sections.forEach((id) => {
      const section = document.getElementById(id);
      if (section) observer.observe(section);
    });

    return () => observer.disconnect();
  }, []);

  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const [setFileUrl] = useState(null);
  const [uploadFile, setUploadFile] = useState([]);

  const [studentData, setStudentData] = useState([]);
  console.log(studentData, "Student Data");
  const [isLoading, setIsLoading] = useState(false);
  const [setLogoUrl] = useState("");
  const [setId] = useState("");
  const [showPersonalForm, setPersonalForm] = useState(false); // Personal Details
  const [showEducationForm, setEducationForm] = useState(false); // Education Details
  const [showEditEducationForm, closePersonlFormEdit] = useState(false); // Edit Education Details
  const [showSkillsForm, setSkillsForm] = useState(false); // Skills Details
  const [showCertificationForm, setCertificationForm] = useState(false); // Certification Details
  const [showEditCertificationForm, setCertificationFormEdit] = useState(false); // Edit Certification Details
  const [showSocialForm, setSocialForm] = useState(false); // Social Media Details
  const [showEditSocialForm, setSocialFormEdit] = useState(false); // Edit Social Media Details
  const [showOverviewForm, setOverviewForm] = useState(false); // Overview Details
  const [locations, setLocations] = useState([]);
  const [skillsmain, setSkillsMain] = useState([]);
  // const [educationDetails, setEducationDetails] = useState([]);
  // const [edductId, setEductId] = useState("");
  // console.log(edductId, "Education IdMain");

  const [formData, setFormData] = useState({
    dob: "",
    gender: "",
    location: "",
    address: "",
    profilePicture: "" || null,
    profile_overview: "",
    skills: [],
    selectedSkills: [], // Array to store selected skills
    course: "",
    education: "",
    end_date: "" || null,
    grading_system: "",
    institute: "",
    marks: "",
    specialization: "",
    start_date: "",
    eduId: "",
    attachment: "" || null,
    smId: "",
    name: "",
    url: "",
    description: "",
    provider: "",
    cID: "",
    expiry: false,
    cId: "",
    id: localStorage.getItem("id") || "",
    password: "",
  });

  const [formErrors, setFormErrors] = useState({
    firstName: "",
    lastName: "",
    gender: "",
    address: "",
    location: "",
    dob: "",
  });

  const validateName = (e) => {
    const { id, value } = e.target;
    const regex = /^[A-Za-z\s]+$/; // Allows letters and spaces only

    // Validation for empty fields
    if (value.trim() === "") {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [id]: `${id.replace(/^\w/, (c) => c.toUpperCase())} cannot be empty`,
      }));
    }
    // Validation for name fields (firstName, lastName)
    else if (id === "firstName" || id === "lastName") {
      if (!regex.test(value)) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          [id]: `${id.replace(/^\w/, (c) =>
            c.toUpperCase()
          )} should not contain numbers or special characters`,
        }));
      } else {
        setFormErrors((prevErrors) => ({ ...prevErrors, [id]: "" }));
      }
    }
    // Validation for gender (assuming options 'Male', 'Female', etc.)
    else if (id === "gender" && value === "") {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [id]: `${id.replace(/^\w/, (c) => c.toUpperCase())} is required`,
      }));
    }
    // Validation for address and location
    else if ((id === "address" || id === "location") && value.trim() === "") {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [id]: `${id.replace(/^\w/, (c) => c.toUpperCase())} is required`,
      }));
    }
    // Validation for date of birth (checking if it's not empty)
    else if (id === "dob" && value === "") {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [id]: `${id.replace(/^\w/, (c) => c.toUpperCase())} is required`,
      }));
    }
    // If valid, clear the error message
    else {
      setFormErrors((prevErrors) => ({ ...prevErrors, [id]: "" }));
    }
  };

  const validateForm = () => {
    let errors = {};

    // Check for password required
    if (!formData.password) {
      errors.password = "Password is required";
    } else if (formData.password.length < 8) {
      errors.password = "Password must be at least 8 characters";
    } else if (formData.password.length > 8) {
      errors.password = "Password must be exactly 8 characters";
    } else if (!/^[A-Za-z0-9]+$/.test(formData.password)) {
      errors.password = "Password should contain only letters and numbers";
    }

    // Check if passwords match
    if (formData.password !== formData.confirmPassword) {
      errors.confirmPassword = "Passwords do not match";
    }

    // Update form errors
    setFormErrors(errors);

    // Return true if no errors, otherwise false
    return Object.keys(errors).length === 0;
  };

  //  skills search
  // const [searchTerm, setSearchTerm] = useState("");
  // const [filteredSkills, setFilteredSkills] = useState(skillsmain);

  // useEffect(() => {
  //   setFilteredSkills(skillsmain);
  // }, [skillsmain]);

  // const handleSearchChange = (e) => {
  //   const value = e.target.value;
  //   setSearchTerm(value);
  //   setFilteredSkills(
  //     skillsmain.filter((skill) =>
  //       skill.skillname.toLowerCase().includes(value.toLowerCase())
  //     )
  //   );
  // };

  // const handleSearchChange = (e) => {
  //   const value = e.target.value;
  //   setSearchTerm(value);
  //   setFilteredSkills(
  //     skillsmain.filter((skill) =>
  //       skill.skillname.toLowerCase().includes(value.toLowerCase())
  //     )
  //   );
  // };

  // Overview Details
  const openOverviewForm = () => setOverviewForm(true);
  const closeOverviewForm = () => {
    setOverviewForm(false); // Close the form
    // setFormData((prevData) => ({
    //   ...prevData,
    //   profile_overview: "", // Reset the profile overview if needed
    // }));
    setError(""); // Reset validation error
  };

  // Personal Details
  const openPersonlForm = () => {
    setFormData({
      firstName: studentData?.data?.firstName,
      lastName: studentData?.data?.lastName,
      dob: studentData?.data?.dob,
      gender: studentData?.data?.gender,
      location: studentData?.data?.location?._id,
      address: studentData?.data?.address,
    });
    setPersonalForm(true);
  };

  // const openPersonlForm = () => {
  //   if (studentData?.data) {
  //     const {
  //       profilePicture,
  //       firstName = "",
  //       lastName = "",
  //       dob = "",
  //       gender = "",
  //       location = {},
  //       address = "",
  //     } = studentData.data;

  //     setFormData((prevFormData) => ({
  //       ...prevFormData, // Retain existing form data if already set
  //       profilePicture: profilePicture?.path
  //         ? `${baseUrl}${profilePicture.path}`
  //         : prevFormData.profilePicture || img1, // Use existing or default
  //       firstName: firstName || prevFormData.firstName,
  //       lastName: lastName || prevFormData.lastName,
  //       dob: dob || prevFormData.dob,
  //       gender: gender || prevFormData.gender,
  //       location: location?._id || prevFormData.location,
  //       address: address || prevFormData.address,
  //     }));
  //   }
  //   setPersonalForm(true);
  // };

  const closePersonlForm = () => {
    setFormData((prevState) => ({
      ...prevState,
      password: "",
      confirmPassword: "",
    }));
    setImage(null);
    setFormErrors({});
    setPersonalForm(false);
  };

  // Education Details
  const openPersonlForm1 = () => {
    setFile(null);
    setUploadFile(null);
    setFormData({
      education: "",
      institute: "",
      course: "",
      specialization: "",
      start_date: "",
      end_date: "",
      grading_system: "",
      marks: "",
      attachment: null,
    });
    setEducationForm(true);
  };
  // const closePersonlForm1 = () => setEducationForm(false);
  // const closePersonlForm1 = () => {
  //   // Locate the form element
  //   const form = document.querySelector("form");
  //   if (form) {
  //     form.reset();
  //   }
  //   setEducationForm(false);
  // };
  const closePersonlForm1 = () => {
    setFile(null);
    setUploadFile(null);
    setFormData({
      course: "",
      education: "",
      end_date: "",
      grading_system: "",
      institute: "",
      marks: "",
      specialization: "",
      start_date: "",
      attachment: null,
    });
    // Clear validation errors
    setErrors({});
    // Close the modal
    setEducationForm(false);
  };

  // Edit Education Details
  const openPersonlFormEdit = () => closePersonlFormEdit(true);
  const closePersonFormEdit = () => closePersonlFormEdit(false);
  const handleOpenEducationForm = async (eductId) => {
    await fetchEducationDetails(eductId);
    openPersonlFormEdit(true);
  };

  // Skills Details
  const openPersonlForm2 = () => setSkillsForm(true);
  const closePersonlForm2 = () => {
    setSkillsForm(false); // Close the form
    setFormData((prevData) => ({
      ...prevData,
      selectedSkills: [], // Reset selected skills
    }));
    setError(""); // Reset validation error
  };

  // Certification Details
  const openPersonlForm3 = () => {
    setFile(null);
    setUploadFile(null);
    setFormData({
      name: "",
      url: "",
      provider: "",
      cID: "",
      start_date: "",
      end_date: "",
      expiry: "",
      attachment: null,
    });
    setErrors({});
    setCertificationForm(true);
  };
  const closePersonlForm3 = () => {
    setFile(null);
    setUploadFile(null);
    setFormData({
      name: "",
      url: "",
      provider: "",
      cID: "",
      start_date: "",
      end_date: "",
      expiry: "",
      attachment: null,
    });
    setErrors({});
    setCertificationForm(false);
  };

  // Edit Certification Details
  const openCertificateEdit = () => setCertificationFormEdit(true);
  const closeCertificateEdit = () => setCertificationFormEdit(false);
  const handleCertificateFormEdit = async (certiId) => {
    await fetchCertificateDetails(certiId);
    openCertificateEdit(true);
  };

  // Social Details
  const openPersonlForm4 = () => {
    setFormData({
      name: "",
      url: "",
      description: "",
    });
    setErrors({});
    setSocialForm(true);
  };
  const closePersonlForm4 = () => {
    setFormData({
      name: "",
      url: "",
      description: "",
    });
    setErrors({});
    setSocialForm(false);
  };

  // Edit Social Details
  const openSocialFormEdit = () => setSocialFormEdit(true);
  const closeSocialFormEdit = () => setSocialFormEdit(false);
  const handleSocialFormEdit = async (socId) => {
    await fetchSocialDetails(socId);
    openSocialFormEdit(true);
  };

  const [errors, setErrors] = useState({});

  //Get Education Details
  const fetchEducationDetails = async (eductId) => {
    const id = localStorage.getItem("id");
    const token = localStorage.getItem("authToken");
    console.log(eductId, "Education Id");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/students/edu-details/${id}/${eductId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const data = response?.data?.data;
      console.log("Education Details:", data);
      setFormData((prevFormData) => ({
        ...prevFormData,
        eduId: data._id,
        course: data.course,
        education: data.education,
        end_date: data.end_date,
        grading_system: data.grading_system,
        institute: data.institute,
        marks: data.marks,
        specialization: data.specialization,
        start_date: data.start_date,
        attachment: data?.attachment?.path
          ? {
              path: `${baseUrl}${data.attachment.path}`,
              _id: data.attachment._id,
            }
          : null,
      }));
      console.log("Education Details:", response?.data?.data);
    } catch (error) {
      console.error("Error fetching education details:", error);
    }
  };
  useEffect(() => {
    fetchEducationDetails();
  }, []);

  //Get Social Links Details
  const fetchSocialDetails = async (socId) => {
    const id = localStorage.getItem("id");
    const token = localStorage.getItem("authToken");
    console.log(socId, "Education Id");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/students/soc-media/${id}/${socId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const data = response?.data?.data;
      console.log("Social links Details:", data);
      setFormData((prevFormData) => ({
        ...prevFormData,
        smId: data._id,
        name: data.name,
        url: data.url,
        description: data.description,
      }));
      console.log("Social links Details:", response?.data?.data);
    } catch (error) {
      console.error("Error social links details:", error);
    }
  };
  useEffect(() => {
    fetchSocialDetails();
  }, []);

  // Get Certifications Details
  const fetchCertificateDetails = async (certiId) => {
    const id = localStorage.getItem("id");
    const token = localStorage.getItem("authToken");
    console.log(certiId, "Education Id");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/students/certification/${id}/${certiId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const data = response?.data?.data;
      console.log("Certification Details:", data);
      setFormData((prevFormData) => ({
        ...prevFormData,
        cId: data._id,
        name: data.name,
        provider: data.provider,
        cID: data.cID,
        url: data.url,
        start_date: data.start_date,
        end_date: data.end_date,
        expiry: data.expiry,
        attachment: data?.attachment?.path
          ? {
              path: `${baseUrl}${data.attachment.path}`,
              _id: data.attachment._id,
            }
          : null,
      }));
      console.log("Social links Details:", response?.data?.data);
    } catch (error) {
      console.error("Error social links details:", error);
    }
  };
  useEffect(() => {
    fetchCertificateDetails();
  }, []);

  // Student Details Api
  // useEffect(() => {
  //   const fetchData = async () => {
  //     const id = localStorage.getItem("id");
  //     console.log(id, "Student Id");
  //     setLoading(true);
  //     try {
  //       const response = await axios.get(
  //         `${process.env.REACT_APP_BASE_URL}/students/std/${id}`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${localStorage.getItem("authToken")}`,
  //           },
  //         }
  //       );
  //       console.log("Student details:", response?.data);
  //       setStudentData(response?.data);
  //       const locationId = response?.data?.data?.location?._id;
  //       const genderName = response?.data?.data?.gender;
  //       const birthDate = response?.data?.data?.dob;
  //       const addressName = response?.data?.data?.address;
  //       const profilePictureStu = response?.data?.data?.profilePicture?._id;
  //       const profileOverview = response?.data?.data?.profile_overview;
  //       const skillsData = response?.data?.data?.skills;
  //       // console.log(profilePictureStu, "Profile Picture");

  //       setFormData((prevFormData) => ({
  //         ...prevFormData,
  //         location: locationId || "",
  //         gender: genderName || "",
  //         dob: birthDate || "",
  //         address: addressName || "",
  //         profilePicture: profilePictureStu || "",
  //         profile_overview: profileOverview || "",
  //         skills: skillsData || [],
  //         education: studentData?.education || "",
  //         institute: studentData?.institute || "",
  //         course: studentData?.course || "",
  //         specialization: studentData?.specialization || "",
  //         start_date: studentData?.start_date || "",
  //         end_date: studentData?.end_date || "",
  //         grading_system: studentData?.grading_system || "",
  //         marks: studentData?.marks || "",
  //       }));
  //     } catch (error) {
  //       console.error("Failed to fetch student details:", error.message);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchData();
  // }, []);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const id = localStorage.getItem("id");
  //     console.log(id, "Student Id");
  //     setLoading(true);
  //     try {
  //       const response = await axios.get(
  //         `${process.env.REACT_APP_BASE_URL}/students/std/${id}`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${localStorage.getItem("authToken")}`,
  //           },
  //         }
  //       );
  //       console.log("Student details:", response?.data);
  //       const studentDetails = response?.data?.data || {};
  //       setStudentData(response?.data);

  //       setFormData((prevFormData) => ({
  //         ...prevFormData,
  //         location: studentDetails.location?._id || "",
  //         gender: studentDetails.gender || "",
  //         dob: studentDetails.dob || "",
  //         address: studentDetails.address || "",
  //         profilePicture: studentDetails.profilePicture?._id || "",
  //         profile_overview: studentDetails.profile_overview || "",
  //         skills: studentDetails.skills || [],
  //         education: studentDetails.education || "",
  //         institute: studentDetails.institute || "",
  //         course: studentDetails.course || "",
  //         specialization: studentDetails.specialization || "",
  //         start_date: studentDetails.start_date || "",
  //         end_date: studentDetails.end_date || "",
  //         grading_system: studentDetails.grading_system || "",
  //         marks: studentDetails.marks || "",
  //       }));
  //     } catch (error) {
  //       console.error("Failed to fetch student details:", error.message);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchData();
  // }, []);

  const fetchData = async () => {
    const id = localStorage.getItem("id");
    const token = localStorage.getItem("authToken");
    console.log(id, "Student Id");
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/students/std/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Student details:", response?.data);
      const studentDetails = response?.data?.data || {};
      dispatch(saveStudentData(studentDetails));
      setStudentData(response?.data);
      setFormData((prevFormData) => ({
        ...prevFormData,
        firstName: studentDetails.firstName || "",
        lastName: studentDetails.lastName || "",
        location: studentDetails.location?._id || "",
        gender: studentDetails.gender || "",
        dob: studentDetails.dob || "",
        address: studentDetails.address || "",
        profilePicture: studentDetails.profilePicture?._id || "",
        profile_overview: studentDetails.profile_overview || "",
        skills: studentDetails.skills || [],
        // education: studentDetails.education || "",
        // institute: studentDetails.institute || "",
        // course: studentDetails.course || "",
        // specialization: studentDetails.specialization || "",
        // start_date: studentDetails.start_date || "",
        // end_date: studentDetails.end_date || "",
        // grading_system: studentDetails.grading_system || "",
        // marks: studentDetails.marks || "",
      }));
    } catch (error) {
      console.error("Failed to fetch student details:", error.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  // Profile Img Upload
  const [image, setImage] = useState(null);

  const [loading, setLoading] = useState(false);
  const [loadingpass, setLoadingPass] = useState(false);

  //Image Upload
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 2 * 1024 * 1024) {
        alert("File size should be less than 2 MB");
      } else {
        const imageUrl = window.URL.createObjectURL(file);
        setImage(imageUrl);
        const formData = new FormData();
        formData.append("file", file);

        const baseURL = process.env.REACT_APP_BASE_URL;
        const token = localStorage.getItem("authToken");
        console.log("studentToken", token);

        if (!token) {
          alert("Authentication token is missing. Please log in again.");
          return;
        }

        const config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${baseURL}/media`,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        };

        console.log("Request Config:", config);

        axios
          .request(config)
          .then((response) => {
            console.log("Image uploaded successfully:", response?.data?.data);
            const logoUrl = response?.data?.data?._id;
            setFormData((prevValues) => ({
              ...prevValues,
              profilePicture: logoUrl,
            }));
          })
          .catch((error) => {
            console.error(
              "Failed to upload image:",
              error.response.data.message
            );
            console.error("Error Response:", error.response);
          });
      }
    }
  };
  useEffect(() => {
    const baseUrl =
      "https://secure-data-upload-new.s3.ap-south-1.amazonaws.com/";
    const data = localStorage.getItem("id");
    if (data) {
      try {
        const parsedData = JSON.parse(data);
        const id = parsedData?.data?.user?._id || "";
        setId(id);
        const pathlogoUrl = parsedData?.data?.user?.logo?.path || "";
        const fullLogoUrl = `${baseUrl}${pathlogoUrl}`;
        setLogoUrl(fullLogoUrl);
        console.log("Full logo URL:", fullLogoUrl);
      } catch (error) {
        console.error("Failed to parse JSON data:", error.message);
      }
    } else {
      console.error("No userResponse found in localStorage");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //File Upload
  const handleFileUpload = (event) => {
    setIsLoading(true);
    const file = event.target.files[0];
    if (file) {
      if (file.size > 2 * 1024 * 1024) {
        // 2 MB in bytes
        alert("File size must be less than 2 MB.");
        setIsLoading(false); // Stop loading
        return;
      }
      const fileUrl = window.URL.createObjectURL(file);
      setFile(fileUrl);
      const formData = new FormData();
      formData.append("file", file);

      const baseURL = process.env.REACT_APP_BASE_URL;
      const token = localStorage.getItem("authToken");
      console.log("studentToken", token);

      if (!token) {
        alert("Authentication token is missing. Please log in again.");
        return;
      }

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${baseURL}/media`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      console.log("Request Config:", config);

      axios
        .request(config)
        .then((response) => {
          console.log("File uploaded successfully:", response?.data?.data);
          setUploadFile(response?.data?.data);
          const fileUrl = response?.data?.data?._id;
          setFormData((prevValues) => ({
            ...prevValues,
            attachment: fileUrl,
          }));
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Failed to upload file:", error.response.data.message);
          console.error("Error Response:", error.response);
        });
    }
  };

  const handleFileRemove = () => {
    setFile(null);
    setFileUrl(null);
  };

  useEffect(() => {
    const baseUrl =
      "https://secure-data-upload-new.s3.ap-south-1.amazonaws.com/";
    const data = localStorage.getItem("id");
    if (data) {
      try {
        const parsedData = JSON.parse(data);
        const id = parsedData?.data?.user?._id || "";
        setId(id);
        const pathFileUrl = parsedData?.data?.user?.file?.path || "";
        const fullFileUrl = `${baseUrl}${pathFileUrl}`;
        setFileUrl(fullFileUrl);
        console.log("Full file URL:", fullFileUrl);
      } catch (error) {
        console.error("Failed to parse JSON data:", error.message);
      }
    } else {
      console.error("No userResponse found in localStorage");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const triggerFileInput = () => {
    document.getElementById("hiddenFileInput").click();
  };

  // Handle Submit Form For Personal Details
  const handleSubmit = (event) => {
    event.preventDefault();
    const validate = () => {
      const newErrors = {};
      if (!formData.location) newErrors.location = "location is required";
      return newErrors;
    };
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    setLoading(true);
    console.log("Form Data:", formData);

    const baseURL = process.env.REACT_APP_BASE_URL;
    const token = localStorage.getItem("authToken");
    const id = localStorage.getItem("id");

    const {
      address,
      dob,
      gender,
      location,
      profilePicture,
      firstName,
      lastName,
    } = formData;
    const filteredData = {
      address,
      dob,
      gender,
      location,
      profilePicture: profilePicture || null,
      firstName,
      lastName,
    };

    const config = {
      method: "put",
      url: `${baseURL}/students/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: filteredData,
    };

    console.log("Request Config Update:", config);

    axios
      .request(config)
      .then((response) => {
        toast.success("Personal details updated successfully");
        // console.log("Personal details updated:", response?.data);
        setPersonalForm(false);
        setLoading(false);
        fetchData();
      })
      .catch((error) => {
        toast.error("Failed to personal details updated");
        // console.error("Failed to personal details updated:", error.message);
        if (error.response) {
          console.error("Error Response Data:", error.response.data);
          console.error("Error Response Status:", error.response.status);
          console.error("Error Response Headers:", error.response.headers);
        }
        setLoading(false);
      });
  };

  // Handle Submit Form For Profile Overview Details
  const handleSubmitOver = async (event) => {
    event.preventDefault();
    setLoading(true);
    console.log("Form Data:", formData);
    const { profile_overview } = formData;

    if (!profile_overview || profile_overview.trim() === "") {
      setError("Profile Overview is required."); // Set error message
      setLoading(false);
      return; // Prevent form submission
    }
    setError("");

    const baseURL = process.env.REACT_APP_BASE_URL;
    const token = localStorage.getItem("authToken");
    const id = localStorage.getItem("id");

    const filteredData = { profile_overview };

    const config = {
      method: "put",
      url: `${baseURL}/students/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: filteredData,
    };

    console.log("Request Config Update:", config);

    try {
      const response = await axios.request(config);
      if (response.status === 200) {
        toast.success("Profile Overview successfully");
        // console.log("Profile updated successfully:", response.data);
        // Update student data with profile_overview
        setStudentData((prevData) => ({
          ...prevData,
          data: {
            ...prevData.data,
            profile_overview: profile_overview,
          },
        }));
        setOverviewForm(false);
      }
    } catch (error) {
      toast.error("Failed to profile updated");
      // console.error("Failed to profile updated:", error.message);
      if (error.response) {
        console.error("Error Response Data:", error.response.data);
        console.error("Error Response Status:", error.response.status);
        console.error("Error Response Headers:", error.response.headers);
      }
    } finally {
      setOverviewForm(false);
      setLoading(false);
    }
  };

  // Get Location All API
  useEffect(() => {
    const baseURL = process.env.REACT_APP_BASE_URL;
    // const token = localStorage.getItem("authToken");
    const fetchLocations = async () => {
      try {
        const response = await axios.get(`${baseURL}/cities/getCity`);
        setLocations(response?.data?.data);
        // console.log("Locations:", response?.data?.data);
      } catch (error) {
        console.error("Error fetching locations:", error);
      }
    };

    fetchLocations();
  }, []);

  // Get Skill Name
  useEffect(() => {
    const baseURL = process.env.REACT_APP_BASE_URL;
    const fetchSkills = async () => {
      try {
        const response = await axios.get(`${baseURL}/skills-sets/getskills`);
        setSkillsMain(response?.data?.data);
        // console.log("skills:", response?.data?.data);
      } catch (error) {
        console.error("Error fetching skills:", error);
      }
    };

    fetchSkills();
  }, []);

  const [error, setError] = useState("");

  // Handle Input skill selection
  // const handleInputChange1 = (e) => {
  //   const { id, value } = e.target;
  //   setFormData((prevData) => {
  //     if (id === "skills") {
  //       const newSkills = Array.isArray(prevData.skills)
  //         ? [...prevData.skills]
  //         : [];
  //       if (newSkills.includes(value)) {
  //         // Remove the skill if it's already selected
  //         return {
  //           ...prevData,
  //           skills: newSkills.filter((skill) => skill !== value),
  //         };
  //       } else {
  //         // Add the skill if it's not selected
  //         return {
  //           ...prevData,
  //           skills: [...newSkills, value],
  //         };
  //       }
  //     } else {
  //       return {
  //         ...prevData,
  //         [id]: value,
  //       };
  //     }
  //   });
  // };

  // const handleInputChange1 = (e) => {
  //   const { id, value } = e.target;
  //   setFormData((prevData) => {
  //     if (id === "skills") {
  //       return {
  //         ...prevData,
  //         selectedSkill: value,
  //       };
  //     } else {
  //       return {
  //         ...prevData,
  //         [id]: value,
  //       };
  //     }
  //   });
  // };

  const handleInputChange1 = (e) => {
    const { id, value, type, checked } = e.target;

    setFormData((prevData) => {
      // Handle "skills" separately
      if (id === "skills") {
        const selectedSkill = JSON.parse(value);
        return {
          ...prevData,
          selectedSkills: [...prevData.selectedSkills, selectedSkill],
        };
      }

      // Handle checkbox for "expiry"
      if (id === "expiry") {
        return {
          ...prevData,
          expiry: checked, // true if checked, false if unchecked
          end_date: checked ? "" : prevData.end_date, // Clear end_date if expiry is checked
        };
      }

      // Handle other input fields
      return {
        ...prevData,
        [id]: value,
      };
    });
  };

  const handleRemoveSkill = (skillToRemove) => {
    setFormData((prevData) => ({
      ...prevData,
      selectedSkills: prevData.selectedSkills.filter(
        (skill) => skill._id !== skillToRemove._id
      ),
    }));
  };

  // Handle Add Skill
  const handleSubmitSkill = (event) => {
    event.preventDefault();
    const { selectedSkills, skills } = formData;

    if (!selectedSkills.length) {
      setError("Please select at least one skill.");
      return;
    } else {
      setError("");
    }

    const updatedSkills = [...skills, ...selectedSkills];

    setFormData((prevData) => ({
      ...prevData,
      skills: updatedSkills, // Update the skills array
      selectedSkills: [], // Reset selected skills
    }));

    setLoading(true);
    console.log("Form Data:", formData);

    const baseURL = process.env.REACT_APP_BASE_URL;
    const token = localStorage.getItem("authToken");
    const id = localStorage.getItem("id");

    const filteredData = { skills: updatedSkills };
    setError("");

    const config = {
      method: "put",
      url: `${baseURL}/students/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: filteredData,
    };
    // console.log("Request Config Update:", config);
    axios
      .request(config)
      .then((response) => {
        toast.success("Skill added successfully");
        // console.log("FoSkillsrm added successfully:", response.data);
        setLoading(false);
        setSkillsForm(false);
        setFormData((prevData) => ({
          ...prevData,
          skills: [],
          selectedSkills: [],
        }));
        fetchData();
      })
      .catch((error) => {
        toast.error("Unable to add the skill. Please try again");
        // console.error("Unable to delete the skill. Please try again", error.message);
        if (error.response) {
          console.error("Error Response Data:", error.response.data);
          console.error("Error Response Status:", error.response.status);
          console.error("Error Response Headers:", error.response.headers);
        }
        setLoading(false);
      });
  };

  //Handle Delete Skill
  const handleDeleteSkill = async (skillId) => {
    const baseURL = process.env.REACT_APP_BASE_URL;
    const id = localStorage.getItem("id");
    const token = localStorage.getItem("authToken");
    // console.log(`Deleting skill with ID: ${skillId}`);
    try {
      const response = await axios.put(`${baseURL}/students/${id}/${skillId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        toast.success("Skill deleted successfully");
        setStudentData((prevData) => ({
          ...prevData,
          data: {
            ...prevData.data,
            skills: prevData.data.skills.filter(
              (skill) => skill._id !== skillId
            ),
          },
        }));
      }
    } catch (error) {
      // console.error('Error deleting skill:', error);
      toast.error("Unable to delete the skill.Please try again.");
    }
  };

  //Handle Add Education
  const handleSubmit1 = (event) => {
    event.preventDefault();

    const validate = () => {
      const newErrors = {};
      if (!formData.education) newErrors.education = "Education is required";
      if (!formData.institute) newErrors.institute = "Institute is required";
      if (!formData.course) newErrors.course = "Course is required";
      if (!formData.specialization)
        newErrors.specialization = "Specialization is required";
      // if (!formData.start_date) {
      //   newErrors.start_date = "Start date is required";
      // } else if (formData.end_date && new Date(formData.start_date) >= new Date(formData.end_date)) {
      //   newErrors.start_date = "Start date should be earlier than end date";
      // }
      // if (!formData.end_date) newErrors.end_date = "End date is required";
      if (!formData.start_date) {
        newErrors.start_date = "Please select a start date.";
      } else if (!formData.end_date) {
        newErrors.end_date = "Please select an end date.";
      } else if (new Date(formData.start_date) > new Date(formData.end_date)) {
        newErrors.start_date = "Start date should be less than the end date.";
        newErrors.end_date = "End date should be greater than the start date.";
      }
      if (!formData.grading_system)
        newErrors.grading_system = "Grading system is required";
      if (!formData.marks) newErrors.marks = "Marks are required";
      return newErrors;
    };

    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    setLoading(true);
    console.log("Form Data:", formData);

    const baseURL = process.env.REACT_APP_BASE_URL;
    const token = localStorage.getItem("authToken");
    const id = localStorage.getItem("id");

    if (!baseURL || !token || !id) {
      console.error("Missing required configuration values");
      return;
    }
    const {
      course,
      education,
      end_date,
      start_date,
      marks,
      specialization,
      grading_system,
      attachment,
      institute,
    } = formData;
    const filteredData = {
      course,
      education,
      end_date,
      start_date,
      marks,
      specialization,
      grading_system,
      attachment: attachment || null,
      institute,
    };
    filteredData.id = id;
    const config = {
      method: "post",
      url: `${baseURL}/students/edu-details`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: filteredData,
    };

    console.log("Request Config Update:", config);

    axios
      .request(config)
      .then((response) => {
        toast.success("Education added successfully");
        // console.log("Education added successfully:", response.data);
        setLoading(false);
        setEducationForm(false);
        fetchEducationDetails();
        fetchData();
        resetForm();
        setFile(null);
        setUploadFile(null);
      })
      .catch((error) => {
        toast.error("Unable to add the education.Please try again.");
        console.error("Failed to add education:", error.message);
        if (error.response) {
          console.error("Error Response Data:", error.response.data);
          console.error("Error Response Status:", error.response.status);
          console.error("Error Response Headers:", error.response.headers);
        }
        setLoading(false);
        setEducationForm(false);
      });
    setFormData({
      course: "",
      education: "",
      end_date: "",
      grading_system: "",
      institute: "",
      marks: "",
      specialization: "",
      start_date: "",
      attachment: "",
      name: "",
      url: "",
      description: "",
      provider: "",
      cID: "",
      expiry: "",
    });
    setErrors({});
    setEducationForm(false);
    setSocialForm(false);
  };
  const resetForm = () => {
    setFormData({
      course: "",
      education: "",
      end_date: "",
      grading_system: "",
      institute: "",
      marks: "",
      specialization: "",
      start_date: "",
      attachment: "",
      name: "",
      url: "",
      description: "",
      provider: "",
      cID: "",
      expiry: "",
    });
    setErrors({});
    setEducationForm(false);
    setSocialForm(false);
  };

  //Handle Delete Education
  const handleDeleteEducation = async (educationId) => {
    const baseURL = process.env.REACT_APP_BASE_URL;
    const id = localStorage.getItem("id");
    const token = localStorage.getItem("authToken");
    console.log(`Deleting education with ID: ${educationId}`);
    try {
      const response = await axios.post(
        `${baseURL}/students/remove-edu-details`,
        {
          id: id,
          eduId: educationId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        toast.success("Education deleted successfully");
        setStudentData((prevData) => ({
          ...prevData,
          data: {
            ...prevData.data,
            edu_details: prevData.data.edu_details.filter(
              (education) => education._id !== educationId
            ),
          },
        }));
      }
    } catch (error) {
      // console.error('Error deleting education:', error);
      toast.error("Unable to delete the education.Please try again");
    }
  };

  //Handle Edit Education
  const handleSubmit3 = (event, eductId) => {
    console.log(eductId, "Education Id");
    event.preventDefault();

    const validate = () => {
      const newErrors = {};
      if (!formData.education) newErrors.education = "Education is required";
      if (!formData.institute) newErrors.institute = "Institute is required";
      if (!formData.course) newErrors.course = "Course is required";
      if (!formData.specialization)
        newErrors.specialization = "Specialization is required";
      if (!formData.start_date) {
        newErrors.start_date = "Please select a start date.";
      } else if (!formData.end_date) {
        newErrors.end_date = "Please select an end date.";
      } else if (new Date(formData.start_date) > new Date(formData.end_date)) {
        newErrors.start_date = "Start date should be less than the end date.";
        newErrors.end_date = "End date should be greater than the start date.";
      }
      if (!formData.grading_system)
        newErrors.grading_system = "Grading system is required";
      if (!formData.marks) newErrors.marks = "Marks are required";
      return newErrors;
    };

    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    setLoading(true);
    console.log("Form Data:", formData);

    const baseURL = process.env.REACT_APP_BASE_URL;
    const token = localStorage.getItem("authToken");
    const id = localStorage.getItem("id");

    const config = {
      method: "post",
      url: `${baseURL}/students/update-edu-details`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        id: id,
        eduId: eductId,
        course: formData.course,
        education: formData.education,
        end_date: formData.end_date,
        grading_system: formData.grading_system,
        institute: formData.institute,
        marks: formData.marks,
        specialization: formData.specialization,
        start_date: formData.start_date,
        attachment: formData.attachment,
      },
    };

    console.log("Request Config Update:", config);

    axios
      .request(config)
      .then((response) => {
        toast.success("Education details edited successfully");
        // console.log("Education update successfully:", response.data);
        setLoading(false);
        closePersonFormEdit(false);
        setFile(null);
        setUploadFile(null);
        fetchData();
        fetchEducationDetails();
      })
      .catch((error) => {
        toast.error("Unable to edit the eduaction details. Please try again.");
        // console.error("Failed to update education:", error.message);
        if (error.response) {
          console.error("Error Response Data:", error.response.data);
          console.error("Error Response Status:", error.response.status);
          console.error("Error Response Headers:", error.response.headers);
        }
        setLoading(false);
        closePersonFormEdit(false);
      });
  };

  // Handle Add Social Links
  const handlesociallinks = (event) => {
    event.preventDefault();

    const validate = () => {
      const newErrors = {};
      if (!formData.name) newErrors.name = "Social name is required";
      if (!formData.url) newErrors.url = "Social Url is required";
      return newErrors;
    };

    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    setLoading(true);
    console.log("Form Data:", formData);

    const baseURL = process.env.REACT_APP_BASE_URL;
    const token = localStorage.getItem("authToken");
    const id = localStorage.getItem("id");

    if (!baseURL || !token || !id) {
      console.error("Missing required configuration values");
      return;
    }
    const { name, url, description } = formData;
    const filteredData = {
      name,
      url,
      description,
    };
    filteredData.id = id;
    const config = {
      method: "post",
      url: `${baseURL}/students/soc-media`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: filteredData,
    };
    console.log("Request Config Update:", config);
    axios
      .request(config)
      .then((response) => {
        toast.success("Social link added successfully");
        // console.log("Social link added successfully:", response.data);
        setLoading(false);
        setSocialForm(false);
        fetchData();
        resetForm();
      })
      .catch((error) => {
        toast.error("Unable to add the social link.Please try again");
        // console.error("Failed to add Social links:", error.message);
        if (error.response) {
          console.error("Error Response Data:", error.response.data);
          console.error("Error Response Status:", error.response.status);
          console.error("Error Response Headers:", error.response.headers);
        }
        setLoading(false);
        setSocialForm(false);
      });
    setErrors({});
    setSocialForm(false);
  };

  // Handle Delete Social Links
  const handleDeleteSocial = async (socialId) => {
    const baseURL = process.env.REACT_APP_BASE_URL;
    const id = localStorage.getItem("id");
    const token = localStorage.getItem("authToken");
    console.log(`Deleting social with ID: ${socialId}`);
    try {
      const response = await axios.post(
        `${baseURL}/students/remove-sm`,
        {
          id: id,
          smId: socialId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        toast.success("Social link deleted successfully");
        setStudentData((prevData) => ({
          ...prevData,
          data: {
            ...prevData.data,
            social_media: prevData.data.social_media.filter(
              (social) => social._id !== socialId
            ),
          },
        }));
      }
    } catch (error) {
      // console.error("Unable to delete the social link. Please try again", errpr);
      toast.error("Unable to delete the social link. Please try again");
    }
  };

  // Handle Edit Social Links
  const handlEditSocialLinks = (event, socId) => {
    console.log(socId, "Social Id");
    event.preventDefault();

    const validate = () => {
      const newErrors = {};
      if (!formData.name) newErrors.name = "Social name is required";
      if (!formData.url) newErrors.url = "Url is required";
      return newErrors;
    };

    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    setLoading(true);
    console.log("Form Data:", formData);

    const baseURL = process.env.REACT_APP_BASE_URL;
    const token = localStorage.getItem("authToken");
    const id = localStorage.getItem("id");

    const config = {
      method: "post",
      url: `${baseURL}/students/update-sm`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        id: id,
        smId: socId,
        name: formData.name,
        url: formData.url,
        description: formData.description,
      },
    };

    console.log("Request Config Update:", config);

    axios
      .request(config)
      .then((response) => {
        toast.success("Social link edited successfully");
        // console.log("Social link edited successfully:", response.data);
        setLoading(false);
        closeSocialFormEdit(false);
        fetchData();
        fetchSocialDetails();
      })
      .catch((error) => {
        toast.error("Unable to edit the social link. Please try again");
        // console.error("Unable to edit the social link. Please try again:", error.message);
        if (error.response) {
          console.error("Error Response Data:", error.response.data);
          console.error("Error Response Status:", error.response.status);
          console.error("Error Response Headers:", error.response.headers);
        }
        setLoading(false);
        closeSocialFormEdit(false);
      });
  };

  // Handle Add Certificate
  const handleCertificate = (event) => {
    event.preventDefault();

    const validate = () => {
      const newErrors = {};
      if (!formData.name) newErrors.name = "certification name is required";
      if (!formData.provider) newErrors.provider = "Provider is required";
      if (!formData.start_date) {
        newErrors.start_date = "Start date is required";
        if (formData.end_date) {
          newErrors.end_date = "Please add the start date first";
        }
      }
      if (!formData.expiry) {
        if (!formData.end_date) {
          newErrors.end_date = "End date is required";
        } else if (formData.start_date) {
          const startDate = new Date(formData.start_date);
          const endDate = new Date(formData.end_date);
          // Check if start date is not less than end date
          if (startDate >= endDate) {
            newErrors.start_date = "Start date must be less than end date";
            newErrors.end_date = "End date must be greater than start date";
          }
        }
      }

      return newErrors;
    };

    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    setLoading(true);
    console.log("Form Data:", formData);

    const baseURL = process.env.REACT_APP_BASE_URL;
    const token = localStorage.getItem("authToken");
    const id = localStorage.getItem("id");

    if (!baseURL || !token || !id) {
      console.error("Missing required configuration values");
      return;
    }
    const {
      name,
      url,
      provider,
      cID,
      start_date,
      end_date,
      expiry,
      attachment,
    } = formData;
    const filteredData = {
      name,
      provider,
      cID,
      url,
      start_date,
      end_date: expiry ? null : end_date,
      expiry: !!expiry,
      attachment: attachment || null,
    };
    filteredData.id = id;
    const config = {
      method: "post",
      url: `${baseURL}/students/certification`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: filteredData,
    };
    console.log("Request Config Update:", config);
    axios
      .request(config)
      .then((response) => {
        toast.success("Certificate added successfully");
        // console.log("Certificate added successfully:", response.data);
        setLoading(false);
        setCertificationForm(false);
        fetchData();
        resetForm();
        setFile(null);
        setUploadFile(null);
      })
      .catch((error) => {
        toast.error("Unable to add the certificate. Please try again");
        // console.error("Unable to add the certificate. Please try again:", error.message);
        if (error.response) {
          console.error("Error Response Data:", error.response.data);
          console.error("Error Response Status:", error.response.status);
          console.error("Error Response Headers:", error.response.headers);
        }
        setLoading(false);
      });
    setErrors({});
    setCertificationForm(false);
    resetForm();
  };

  // Handle Delete Certificate
  const handleDeleteCertification = async (certificateId) => {
    const baseURL = process.env.REACT_APP_BASE_URL;
    const id = localStorage.getItem("id");
    const token = localStorage.getItem("authToken");
    console.log(`Deleting social with ID: ${certificateId}`);
    try {
      const response = await axios.post(
        `${baseURL}/students/remove-cert`,
        {
          id: id,
          cId: certificateId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        toast.success("Certificate deleted successfully");
        setStudentData((prevData) => ({
          ...prevData,
          data: {
            ...prevData.data,
            certifications: prevData.data.certifications.filter(
              (certificate) => certificate._id !== certificateId
            ),
          },
        }));
      }
    } catch (error) {
      // console.error("Unable to delete the certificate. Please try again", error);
      toast.error("Unable to delete the certificate. Please try again");
    }
  };

  // Handle Edit Certification
  const handleEditCertificate = (event, certiId) => {
    console.log(certiId, "Social Id");
    event.preventDefault();
    if (formData.expiry) {
      formData.end_date = null;
    }
    const validate = () => {
      const newErrors = {};
      if (!formData.name) newErrors.name = "certification name is required";
      if (!formData.provider) newErrors.provider = "Provider is required";
      if (!formData.start_date) {
        newErrors.start_date = "Start date is required";
        if (formData.end_date) {
          newErrors.end_date = "Please add the start date first";
        }
      }
      if (!formData.expiry) {
        if (!formData.end_date) {
          newErrors.end_date = "End date is required";
        } else if (formData.start_date) {
          const startDate = new Date(formData.start_date);
          const endDate = new Date(formData.end_date);
          // Check if start date is not less than end date
          if (startDate >= endDate) {
            newErrors.start_date = "Start date must be less than end date";
            newErrors.end_date = "End date must be greater than start date";
          }
        }
      }
      return newErrors;
    };

    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    setLoading(true);
    console.log("Form Data:", formData);

    const baseURL = process.env.REACT_APP_BASE_URL;
    const token = localStorage.getItem("authToken");
    const id = localStorage.getItem("id");

    const config = {
      method: "post",
      url: `${baseURL}/students/update-cert`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        id: id,
        cId: certiId,
        name: formData.name,
        url: formData.url,
        provider: formData.provider,
        cID: formData.cID,
        start_date: formData.start_date,
        end_date: formData.expiry ? null : formData.end_date,
        expiry: formData.expiry,
        attachment: formData.attachment,
      },
    };

    console.log("Request Config Update:", config);

    axios
      .request(config)
      .then((response) => {
        toast.success("Certificate details edited successfully");
        console.log("Certificate details edited successfully:", response.data);
        setLoading(false);
        closeCertificateEdit(false);
        fetchCertificateDetails();
        fetchData();
        setFile(null);
        setUploadFile(null);
      })
      .catch((error) => {
        toast.error("Unable to edit the certificate details. Please try again");
        console.error("Failed to Update certificate:", error.message);
        if (error.response) {
          console.error("Error Response Data:", error.response.data);
          console.error("Error Response Status:", error.response.status);
          console.error("Error Response Headers:", error.response.headers);
        }
        setLoading(false);
        closeCertificateEdit(false);
      });
  };

  // Handle Change Password
  const handleChangePass = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    const { password } = formData; // Assuming formData has a password and confirmPassword

    // Create an object to send the password in the request body
    const filteredData = {
      password,
    };

    const baseURL = process.env.REACT_APP_BASE_URL;
    const token = localStorage.getItem("authToken");
    setLoadingPass(true);

    try {
      const response = await axios.post(
        `${baseURL}/auth/student-change-password`,
        filteredData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      toast.success("Password changed successfully");
      console.log("Password changed successfully:", response.data);
      setFormData({
        password: "",
        confirmPassword: "",
      });
      setFormErrors({});
    } catch (error) {
      toast.error("Unable to change the password. Please try again");
      console.error(
        "Unable to change the password. Please try again:",
        error.response?.data || error.message
      );
    } finally {
      setLoadingPass(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const baseUrl = "https://secure-data-upload-new.s3.ap-south-1.amazonaws.com/";

  const handleRemoveAttachment = () => {
    setFormData({ ...formData, attachment: null });
  };

  return (
    <>
      <section id="student-profile" className="student-profile-sec">
        <div className="container mt-4">
          {loading ? (
            <div className="col-md-12">
              <div className="d-flex justify-content-center align-items-center">
                {" "}
                <Loader />
              </div>
            </div>
          ) : (
            <div className="row">
              {/* Left Sidebar: Profile Card */}
              <div className="col-md-4 sidebar">
                <div className="card text-center student-profile-card mb-3 student-profile-card-1">
                  <div className="profile-sub">
                    <div className="profile-sub-img">
                      <img
                        src={
                          studentData?.data?.profilePicture?.path
                            ? `${baseUrl}${studentData?.data?.profilePicture?.path}`
                            : img1
                        }
                        className="card-img-top rounded-circle mx-auto mt-3"
                        alt="img"
                      />
                    </div>
                    <div className="card-body profile-sub-info mb-4">
                      <h5 className="card-title">
                        {studentData?.data?.firstName}{" "}
                        {studentData?.data?.lastName}
                      </h5>
                      <p className="card-text1">
                        <i className="bi bi-envelope mx-1"></i>{" "}
                        {/* {studentData?.data?.email ? (
                        <span className="wrap">{studentData.data.email}</span>
                      ) : (
                        <div className="d-flex justify-content-center align-items-center">
                          <Loader />
                        </div>
                      )} */}
                        <span className="wrap">{studentData?.data?.email}</span>
                      </p>
                      <p className="card-text1">
                        {" "}
                        <i className="bi bi-phone mx-1"></i>
                        {studentData?.data?.phone}
                      </p>
                      {studentData?.data?.location?.cityName && (
                        <p className="card-text1">
                          <i className="bi bi-geo-alt mx-1"></i>
                          {studentData.data.location.cityName}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                {/* Quick Links */}
                <div className="card quick-links-main mb-4 pb-2 student-profile-card-1">
                  <h6 className="px-3 pt-3">
                    <strong>Quick links</strong>
                  </h6>
                  {/* <ul className="list-group list-group-flush px-3">
                    <li className="list-group-item">
                      <HashLink to="#profile-overview" className="links">
                        Profile Overview
                      </HashLink>
                    </li>
                    <li className="list-group-item">
                      <HashLink to="#personal-details" className="links">
                        Personal Details
                      </HashLink>
                    </li>
                    <li className="list-group-item">
                      <HashLink to="#education-details" className="links">
                        Education Details
                      </HashLink>
                    </li>
                    <li className="list-group-item">
                      <HashLink to="#skills-details" className="links">
                        Skills
                      </HashLink>
                    </li>
                    <li className="list-group-item">
                    <HashLink to="#certification-details" className="links">
                      Certification
                    </HashLink>
                  </li>
                  <li className="list-group-item">
                    <HashLink to="#socialmedia-details" className="links">
                      Social media profile
                    </HashLink>
                  </li>
                  </ul> */}
                  <ul className="list-group list-group-flush px-3">
                    <li
                      className={`list-group-item ${
                        activeSection === "profile-overview" ? "active" : ""
                      }`}
                      onClick={() => scrollToSection("profile-overview")}
                    >
                      <h6 className="links">Profile Overview</h6>
                    </li>
                    <li
                      className={`list-group-item ${
                        activeSection === "personal-details" ? "active" : ""
                      }`}
                      onClick={() => scrollToSection("personal-details")}
                    >
                      <h6 className="links">Personal Details</h6>
                    </li>
                    <li
                      className={`list-group-item ${
                        activeSection === "education-details" ? "active" : ""
                      }`}
                      onClick={() => scrollToSection("education-details")}
                    >
                      <h6 className="links">Education Details</h6>
                    </li>
                    <li
                      className={`list-group-item ${
                        activeSection === "skills-details" ? "active" : ""
                      }`}
                      onClick={() => scrollToSection("skills-details")}
                    >
                      <h6 className="links">Skills</h6>
                    </li>
                    <li
                      className={`list-group-item ${
                        activeSection === "certification-details"
                          ? "active"
                          : ""
                      }`}
                      onClick={() => scrollToSection("certification-details")}
                    >
                      <h6 className="links">Certification</h6>
                    </li>
                    <li
                      className={`list-group-item ${
                        activeSection === "socialmedia-details" ? "active" : ""
                      }`}
                      onClick={() => scrollToSection("socialmedia-details")}
                    >
                      <h6 className="links">Social Media Profile</h6>
                    </li>
                  </ul>
                </div>
              </div>

              {/* Right Side: Profile Details */}

              <div className="col-md-8 right-side-sect">
                {/* Profile Overview */}
                <div
                  id="profile-overview"
                  className="card card-pro-details mb-4 student-profile-card-2"
                >
                  <div className="card-body">
                    <div className="card-pro-details-title">
                      {" "}
                      <h5 className="card-title">Profile Overview</h5>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip-top">
                            Edit Profile Overview
                          </Tooltip>
                        }
                      >
                        <i
                          className="bi bi-pencil-fill student-details-icon"
                          onClick={openOverviewForm}
                        ></i>
                      </OverlayTrigger>
                    </div>
                    <p className="card-text">
                      {studentData?.data?.profile_overview?.trim() ? (
                        studentData.data.profile_overview
                      ) : (
                        <div className="card-text">
                          No Profile Overview Added
                        </div>
                      )}
                    </p>
                  </div>
                </div>

                {/* Personal Details */}
                <div
                  id="personal-details"
                  className="card card-pro-details mb-4 student-profile-card-2"
                >
                  <div className="card-body">
                    <div className="card-pro-details-title">
                      {" "}
                      <h5 className="card-title">Personal Details</h5>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip-top">
                            Edit Personal Details
                          </Tooltip>
                        }
                      >
                        <i
                          class="bi bi-pencil-fill student-details-icon"
                          onClick={openPersonlForm}
                        ></i>
                      </OverlayTrigger>
                    </div>
                    <p className="card-text">
                      <div class="row personal-details-row">
                        <div class="col-md-6">
                          <span className="sub-mini-title">Date of Birth</span>
                          <br />
                          <strong className="personal-sub-details">
                            {/* {studentData?.data?.dob || "N/A"} */}
                            {studentData?.data?.dob
                              ? new Date(
                                  studentData.data.dob
                                ).toLocaleDateString("en-GB")
                              : "N/A"}
                          </strong>
                        </div>
                        <div class="col-md-6">
                          <span className="sub-mini-title">Gender</span>
                          <br />
                          <strong className="personal-sub-details">
                            {studentData?.data?.gender || "N/A"}
                          </strong>
                        </div>
                      </div>
                      <div className="profile-address">
                        <span className="sub-mini-title">Address</span>
                        <br />
                        <strong className="personal-sub-details">
                          {studentData?.data?.address || "N/A"}
                        </strong>
                      </div>
                    </p>
                  </div>
                </div>

                {/* Education Details */}
                <div
                  id="education-details"
                  className="card card-pro-details mb-4 student-profile-card-2"
                >
                  <div className="card-body">
                    <div className="card-pro-details-title">
                      {" "}
                      <h5 className="card-title">Education Details</h5>
                      <div>
                        {/* <i className="bi bi-paperclip student-details-icon mx-3"></i> */}
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="tooltip-top">
                              Add Education Details
                            </Tooltip>
                          }
                        >
                          <i
                            class="bi bi-plus-circle-fill student-details-icon"
                            onClick={openPersonlForm1}
                          ></i>
                        </OverlayTrigger>
                      </div>
                    </div>

                    <div className="card-text">
                      {studentData?.data?.edu_details?.length > 0 ? (
                        studentData.data.edu_details.map((education, index) => (
                          <div
                            className="mb-2 d-flex justify-content-between"
                            key={index}
                          >
                            <div>
                              <p className="sub-mini-title">
                                {education?.education} | {education?.course}
                              </p>
                              <h6 className="personal-sub-details">
                                {education?.institute}
                              </h6>
                              <p className="personal-edu-sect certi-date">
                                {new Date(
                                  education?.start_date
                                ).getFullYear() ||
                                  new Date().getFullYear()}{" "}
                                to{" "}
                                {new Date(education?.end_date).getFullYear() ||
                                  new Date().getFullYear() + 4}{" "}
                                | {education?.marks} {education?.grading_system}
                              </p>
                            </div>
                            <div>
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id="tooltip-top">
                                    Edit Education Details
                                  </Tooltip>
                                }
                              >
                                <i
                                  className="bi bi-pencil-fill student-details-icon mx-3"
                                  onClick={() =>
                                    handleOpenEducationForm(education._id)
                                  }
                                ></i>
                              </OverlayTrigger>

                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id="tooltip-top">
                                    Delete Education Details
                                  </Tooltip>
                                }
                              >
                                <i
                                  className="bi bi-trash student-details-icon"
                                  onClick={() =>
                                    handleDeleteEducation(education._id)
                                  }
                                ></i>
                              </OverlayTrigger>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div>No Education Added</div>
                      )}
                    </div>
                  </div>
                </div>

                {/* Skills */}
                <div
                  id="skills-details"
                  className="card card-pro-details mb-4 student-profile-card-2"
                >
                  <div className="card-body">
                    <div className="card-pro-details-title">
                      <h5 className="card-title">Skills</h5>
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="tooltip-top">Add Skills</Tooltip>}
                      >
                        <i
                          className="bi bi-plus-circle-fill student-details-icon"
                          onClick={openPersonlForm2}
                        ></i>
                      </OverlayTrigger>
                    </div>
                    <div className="card-text">
                      <div>
                        {studentData?.data?.skills?.length > 0 ? (
                          <ul className="card-unorder-list">
                            {studentData.data.skills.map((skill, index) => (
                              <div
                                className="d-flex justify-content-between"
                                key={index}
                              >
                                <li>
                                  <p className="skill-list-text">
                                    {skill?.skillname}
                                  </p>
                                </li>
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id="tooltip-top">
                                      Delete Skill
                                    </Tooltip>
                                  }
                                >
                                  <i
                                    className="bi bi-trash student-details-icon"
                                    onClick={() => handleDeleteSkill(skill._id)}
                                  ></i>
                                </OverlayTrigger>
                              </div>
                            ))}
                          </ul>
                        ) : (
                          <div>No Skill Added</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {/* Projects */}
                {/* <div id="Projects-details" className="card card-pro-details ">
                 <div className="card-body">
                   <h5 className="card-title">Projects</h5>
                   <p className="card-text">
                      <span className="sub-mini-title">Title-1</span>
                     <div>
                       <strong className="personal-sub-details">
                         www.project-1.com
                       </strong>
                       <p>
                         Finished | Dec 2023-Feb 2024 <br />
                         <span>
                           Lorem ipsum dolor sit amet, consectetur adipiscing
                           elit. Vestibulum mollis nunc a molestie dictum.
                         </span>
                       </p>
                     </div>
                   </p>
                 </div>
               </div> */}

                {/* Certification */}
                <div
                  id="certification-details"
                  className="card card-pro-details mb-4 student-profile-card-2"
                >
                  <div className="card-body">
                    <div className="card-pro-details-title">
                      {" "}
                      <h5 className="card-title">Certification</h5>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip-top">Add Certificate</Tooltip>
                        }
                      >
                        <i
                          className="bi bi-plus-circle-fill student-details-icon"
                          onClick={openPersonlForm3}
                        ></i>
                      </OverlayTrigger>
                    </div>

                    {studentData?.data?.certifications?.length > 0 ? (
                      studentData.data.certifications.map((certn, index) => (
                        <div
                          className="card-text certificate-sub-div"
                          key={index}
                        >
                          <div>
                            <div className="d-flex justify-content-end">
                              <span>
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id="tooltip-top">
                                      Edit Certificate
                                    </Tooltip>
                                  }
                                >
                                  <i
                                    className="bi bi-pencil-fill student-details-icon mx-3"
                                    onClick={() =>
                                      handleCertificateFormEdit(certn._id)
                                    }
                                  ></i>
                                </OverlayTrigger>
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id="tooltip-top">
                                      Delete certificate
                                    </Tooltip>
                                  }
                                >
                                  <i
                                    className="bi bi-trash student-details-icon"
                                    onClick={() =>
                                      handleDeleteCertification(certn._id)
                                    }
                                  ></i>
                                </OverlayTrigger>
                              </span>
                            </div>
                            <h6 className="certi-name">{certn?.name}</h6>
                            <p className="certi-sub-name">{certn?.provider}</p>
                            <div>
                              {certn?.cID && (
                                <p className="certi-date">
                                  Certification ID: {certn.cID}
                                </p>
                              )}
                            </div>
                            <p className="certi-link">
                              <a
                                href={certn?.url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {certn?.url}
                              </a>
                            </p>
                            {certn?.start_date && (
                              <p className="certi-date">
                                {certn?.expiry
                                  ? `Valid from ${new Date(
                                      certn.start_date
                                    ).toLocaleString("default", {
                                      month: "short",
                                    })} '${new Date(certn.start_date)
                                      .getFullYear()
                                      .toString()
                                      .slice(-2)}'. Does not expire`
                                  : `Validity: ${new Date(
                                      certn.start_date
                                    ).toLocaleString("default", {
                                      month: "short",
                                    })} '${new Date(certn.start_date)
                                      .getFullYear()
                                      .toString()
                                      .slice(-2)}' to ${new Date(
                                      certn.end_date
                                    ).toLocaleString("default", {
                                      month: "short",
                                    })} '${new Date(certn.end_date)
                                      .getFullYear()
                                      .toString()
                                      .slice(-2)}'`}
                              </p>
                            )}
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="card-text">No Certificate Added</div>
                    )}
                  </div>
                </div>

                {/* SocialMedia */}
                <div
                  id="socialmedia-details"
                  className="card card-pro-details mb-4 student-profile-card-2"
                >
                  <div className="card-body">
                    <div className="card-pro-details-title">
                      {" "}
                      <h5 className="card-title">Social media profile</h5>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip-top">Add social links</Tooltip>
                        }
                      >
                        <i
                          class="bi bi-plus-circle-fill student-details-icon"
                          onClick={openPersonlForm4}
                        ></i>
                      </OverlayTrigger>
                    </div>
                    {studentData?.data?.social_media?.length > 0 ? (
                      studentData.data.social_media.map((socl, index) => (
                        <div className="card-text" key={index}>
                          <div>
                            <div className="d-flex justify-content-end">
                              <span>
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id="tooltip-top">
                                      Edit social links
                                    </Tooltip>
                                  }
                                >
                                  <i
                                    className="bi bi-pencil-fill student-details-icon mx-3"
                                    onClick={() =>
                                      handleSocialFormEdit(socl._id)
                                    }
                                  ></i>
                                </OverlayTrigger>
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id="tooltip-top">
                                      Delete social links
                                    </Tooltip>
                                  }
                                >
                                  <i
                                    className="bi bi-trash student-details-icon"
                                    onClick={() => handleDeleteSocial(socl._id)}
                                  ></i>
                                </OverlayTrigger>
                              </span>
                            </div>
                            <p className="certi-sub-name">{socl?.name}</p>
                            <p className="certi-link">
                              <a
                                href={socl?.url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {socl?.url}
                              </a>
                            </p>
                            <p className="socl-desc">{socl?.description}</p>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="card-text">No Social Links Added</div>
                    )}
                  </div>
                </div>

                {/* Languages */}
                {/* <div
                 id="languages-details"
                 className="card card-pro-details mb-4"
               >
                 <div className="card-body">
                   <h5 className="card-title">Languages</h5>
                   <p className="card-text">
                     <div>
                       <ul>
                         <li>
                           <strong className="personal-sub-details">
                             English
                           </strong>
                         </li>
                         <li>
                           <strong className="personal-sub-details">
                             Hindi
                           </strong>
                         </li>
                         <li>
                           <strong className="personal-sub-details">
                             Marathi
                           </strong>
                         </li>
                       </ul>
                     </div>
                   </p>
                 </div>
               </div> */}
              </div>
            </div>
          )}
        </div>
      </section>

      {/* Modals Form */}

      {/* OverviewForm */}
      {showOverviewForm && (
        <div
          className="modal show d-block"
          tabIndex="-1"
          style={{ backdropFilter: "brightness(0.5)" }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Overview</h5>
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={closeOverviewForm}
                ></button>
              </div>
              <div className="modal-body">
                <form onSubmit={handleSubmitOver}>
                  <div className="mb-3">
                    <label htmlFor="overview" className="form-label">
                      Profile Overview
                    </label>
                    <textarea
                      className="form-control form-modal"
                      id="profile_overview"
                      rows="4"
                      value={formData.profile_overview}
                      onChange={handleInputChange1}
                    ></textarea>
                    {error && (
                      <div className="error-message text-danger">{error}</div>
                    )}{" "}
                    {/* Error message */}
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-light student-profile-btn2"
                  onClick={closeOverviewForm}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary student-profile-btn1"
                  onClick={handleSubmitOver}
                  disabled={loading}
                >
                  {loading ? (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    "Save changes"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* PersonalDetailsForm */}
      {showPersonalForm && (
        <div
          className="modal show d-block bd-example-modal-lg"
          tabIndex="-1"
          style={{ backdropFilter: "brightness(0.5)" }}
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Personal Details</h5>
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={closePersonlForm}
                ></button>
              </div>
              <div className="modal-body">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-6 col-sm-12">
                      <div className="profile-upload-sect">
                        {image ? (
                          <img
                            src={image}
                            alt="Uploaded"
                            className="img-profile-upload"
                          />
                        ) : (
                          <img
                            src={
                              studentData?.data?.profilePicture?.path
                                ? `${baseUrl}${studentData?.data?.profilePicture?.path}`
                                : img1
                            }
                            alt="Default"
                            className="img-profile-upload"
                          />
                        )}
                        <div className="custom-btn-upload">
                          <input
                            type="file"
                            id="hiddenFileInput"
                            style={{ display: "none" }}
                            onChange={handleImageUpload}
                          />
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={triggerFileInput}
                          >
                            Upload Image
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <div className="mb-3">
                        <label htmlFor="firstName" className="form-label">
                          First Name
                        </label>
                        <input
                          type="text"
                          className="form-control form-modal"
                          id="firstName"
                          value={formData.firstName}
                          onChange={handleInputChange1}
                          onBlur={validateName}
                        />
                        {formErrors.firstName && (
                          <small className="text-danger">
                            {formErrors.firstName}
                          </small>
                        )}
                      </div>
                      <div className="mb-3">
                        <label htmlFor="lastName" className="form-label">
                          Last Name
                        </label>
                        <input
                          type="text"
                          className="form-control form-modal"
                          id="lastName"
                          value={formData.lastName}
                          onChange={handleInputChange1}
                          onBlur={validateName}
                        />
                        {formErrors.lastName && (
                          <small className="text-danger">
                            {formErrors.lastName}
                          </small>
                        )}
                      </div>
                      <div className="mb-3">
                        <label htmlFor="dob" className="form-label">
                          Date of Birth
                        </label>
                        <input
                          type="date"
                          className="form-control form-modal"
                          id="dob"
                          value={formData.dob}
                          onChange={handleInputChange1}
                          max={new Date().toISOString().split("T")[0]}
                          // max={new Date(Date.now() - 86400000).toISOString().split("T")[0]}
                        />
                        {formErrors.dob && (
                          <small className="text-danger">
                            {formErrors.dob}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      {" "}
                      <div className="mb-3">
                        <label htmlFor="gender" className="form-label">
                          Gender
                        </label>
                        <select
                          className="form-select"
                          id="gender"
                          value={formData.gender}
                          onChange={handleInputChange1}
                        >
                          <option value="None">Select Gender</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="Other">Other</option>
                        </select>

                        {formErrors.gender && (
                          <small className="text-danger">
                            {formErrors.gender}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      {" "}
                      <div className="mb-3">
                        <label htmlFor="location" className="form-label">
                          Location
                        </label>
                        <select
                          className="form-select"
                          id="location"
                          name="location"
                          value={formData.location || ""}
                          onChange={handleInputChange1}
                        >
                          <option value="">Select Location</option>
                          {locations.map((location) => (
                            <option key={location.id} value={location?._id}>
                              {location?.cityName}
                            </option>
                          ))}
                        </select>
                        {formErrors.location && (
                          <small className="text-danger">
                            {formErrors.location}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label htmlFor="address" className="form-label">
                          Address
                        </label>
                        <textarea
                          className="form-control form-modal"
                          id="address"
                          rows="3"
                          value={formData.address}
                          onChange={handleInputChange1}
                        ></textarea>

                        {formErrors.address && (
                          <small className="text-danger">
                            {formErrors.address}
                          </small>
                        )}
                      </div>
                    </div>
                  </div>
                </form>
                <hr />
                <h5 className="mb-3">Change Password</h5>
                <form onSubmit={handleChangePass}>
                  <div className="row">
                    <div className="col-md-6 col-sm-12">
                      <div className="mb-3">
                        <label htmlFor="password" className="form-label">
                          New Password
                        </label>
                        <input
                          type="password"
                          className="form-control form-modal"
                          id="password"
                          value={formData.password}
                          onChange={handleInputChange1}
                        />
                        {formErrors.password && (
                          <small className="text-danger">
                            {formErrors.password}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <div className="mb-3">
                        <label htmlFor="confirmPassword" className="form-label">
                          Confirm Password
                        </label>
                        <input
                          type="password"
                          className="form-control form-modal"
                          id="confirmPassword"
                          value={formData.confirmPassword}
                          onChange={handleInputChange1}
                        />
                        {formErrors.confirmPassword && (
                          <small className="text-danger">
                            {formErrors.confirmPassword}
                          </small>
                        )}
                      </div>
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary student-profile-btn1"
                    disabled={loadingpass}
                    onClick={handleChangePass}
                  >
                    {loadingpass ? (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      "Change Password"
                    )}
                  </button>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-light student-profile-btn2"
                  onClick={closePersonlForm}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary student-profile-btn1"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  {loading ? (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    "Save changes"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* EducationDetailsForm */}
      {showEducationForm && (
        <div
          className="modal show d-block bd-example-modal-lg"
          tabIndex="-1"
          style={{ backdropFilter: "brightness(0.5)" }}
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header custom-modal-header">
                <h5 className="modal-title">Education Details</h5>
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={closePersonlForm1}
                ></button>
                {/* <p>
                  Add your education details like course ,university and more
                </p> */}
              </div>
              <div className="modal-body">
                <form onSubmit={handleSubmit1}>
                  {/* <div className="mb-3">
                    <label htmlFor="education" className="form-label">
                      Education
                    </label>
                    <input
                      type="text"
                      className="form-control form-modal"
                      id="education"
                    />
                  </div> */}
                  <div className="mb-3">
                    <label htmlFor="education" className="form-label">
                      Education
                    </label>
                    <select
                      className="form-control form-modal"
                      id="education"
                      value={formData.education}
                      onChange={handleInputChange1}
                      required
                    >
                      <option value="">Select Education</option>
                      <option value="Graduation">Graduation</option>
                      <option value="12th Board">12th Board</option>
                      <option value="10th Board">10th Board</option>
                    </select>
                    {errors.education && (
                      <div className="error text-danger">
                        {errors.education}
                      </div>
                    )}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="university" className="form-label">
                      University/Institute
                    </label>
                    <select
                      className="form-control form-modal"
                      id="institute"
                      value={formData.institute}
                      onChange={handleInputChange1}
                      required
                    >
                      <option value="">Select University</option>
                      <option value="Rashtrasant Tukadoji Maharaj Nagpur University">
                        Rashtrasant Tukadoji Maharaj Nagpur University
                      </option>
                      <option value="Sant Gadge Baba Amravati University (SGBAU)">
                        Sant Gadge Baba Amravati University (SGBAU)
                      </option>
                      <option value="other">Other</option>
                    </select>
                    {errors.institute && (
                      <div className="error text-danger">
                        {errors.institute}
                      </div>
                    )}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="course" className="form-label">
                      Course
                    </label>
                    <select
                      className="form-control form-modal"
                      id="course"
                      value={formData.course}
                      onChange={handleInputChange1}
                      required
                    >
                      <option value="">Select Course</option>
                      <option value="Bachelor of Science (B.Sc)">
                        Bachelor of Science (B.Sc)
                      </option>
                      <option value="Bachelor of Arts (B.A)">
                        Bachelor of Arts (B.A)
                      </option>
                      <option value="Bachelor of Commerce (B.Com)">
                        Bachelor of Commerce (B.Com)
                      </option>
                      <option value="other">Other</option>
                    </select>
                    {errors.course && (
                      <div className="error text-danger">{errors.course}</div>
                    )}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="specialization" className="form-label">
                      Specialization
                    </label>
                    <select
                      className="form-control form-modal"
                      id="specialization"
                      value={formData.specialization}
                      onChange={handleInputChange1}
                      required
                    >
                      <option value="">Select Specialization</option>
                      <option value="CS">Computer Science</option>
                      <option value="math">Information Technology</option>
                      <option value="physics">Physics</option>
                      <option value="other">Other</option>
                    </select>
                    {errors.specialization && (
                      <div className="error text-danger">
                        {errors.specialization}
                      </div>
                    )}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="courseDuration" className="form-label">
                      Course Duration
                    </label>
                    <div className="row">
                      <div className="col">
                        <input
                          type="date"
                          className="form-control form-modal"
                          id="start_date"
                          value={formData.start_date}
                          onChange={handleInputChange1}
                          placeholder="Start Date"
                          required
                        />
                        {errors.start_date && (
                          <div className="error text-danger">
                            {errors.start_date}
                          </div>
                        )}
                      </div>
                      <div className="col">
                        <input
                          type="date"
                          className="form-control form-modal"
                          id="end_date"
                          value={formData.end_date}
                          onChange={handleInputChange1}
                          placeholder="End Date"
                          required
                        />
                        {errors.end_date && (
                          <div className="error text-danger">
                            {errors.end_date}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="mb-3">
                    <label htmlFor="gradingSystem" className="form-label">
                      Grading System
                    </label>
                    <select
                      className="form-control form-modal"
                      id="grading_system"
                      value={formData.grading_system}
                      onChange={handleInputChange1}
                      required
                    >
                      <option value="">Select Grading System</option>
                      <option value="Percentage">Percentage</option>
                      <option value="CGPA">CGPA</option>
                      <option value="Pass/Fail">Pass/Fail</option>
                    </select>
                    {errors.grading_system && (
                      <div className="error text-danger">
                        {errors.grading_system}
                      </div>
                    )}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="marks" className="form-label">
                      Marks
                    </label>
                    <input
                      type="text"
                      className="form-control form-modal"
                      id="marks"
                      value={formData.marks}
                      onChange={handleInputChange1}
                      placeholder="Enter Marks"
                      required
                    />
                    {errors.marks && (
                      <div className="error text-danger">{errors.marks}</div>
                    )}
                  </div>

                  <div className="mb-3">
                    {/* <div className="upload-btn-edu">
                      <input
                        type="file"
                        id="hiddenFileInput"
                        style={{ display: "none" }}
                        onChange={handleImageUpload}
                      />
                      <button
                        type="button"
                        className="btn upload-btn-file"
                        onClick={triggerFileInput}
                      >
                        Upload
                      </button>

                      <p>Upload hard copy of marksheet here</p>
                    </div> */}
                    <div>
                      <label htmlFor="attachment" className="form-label">
                        <h5>Documents</h5>
                      </label>
                      {isLoading ? (
                        <div>Loading...</div>
                      ) : (
                        file && (
                          <>
                            <div className="pb-2">
                              <a
                                href={file}
                                className="file-link text-decoration-none text-dark"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <h5 className="docul-name">
                                  {uploadFile?.originalname}
                                </h5>
                              </a>
                            </div>
                          </>
                        )
                      )}

                      <div className="upload-btn-edu">
                        <input
                          type="file"
                          id="hiddenFileInput"
                          style={{ display: "none" }}
                          onChange={handleFileUpload}
                        />
                        <button
                          type="button"
                          className="btn upload-btn-file"
                          onClick={triggerFileInput}
                        >
                          Upload Document
                        </button>

                        <p className="upl-text">
                          Supported Formats: doc, pdf, upto 2 MB
                        </p>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-light student-profile-btn2"
                  onClick={closePersonlForm1}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary student-profile-btn1"
                  onClick={handleSubmit1}
                  disabled={loading}
                >
                  {loading ? (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    "Add"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* showSkillsForm */}
      {showSkillsForm && (
        <div
          className="modal show d-block"
          tabIndex="-1"
          style={{ backdropFilter: "brightness(0.5)" }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Skills Details</h5>
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={closePersonlForm2}
                ></button>
              </div>
              <div className="modal-body">
                <form onSubmit={handleSubmitSkill}>
                  <div className="selected-skills">
                    {formData.selectedSkills.map((skill, index) => (
                      <div key={index} className="selected-skill">
                        <span className="selected-skill-group">
                          {skill.skillname}{" "}
                          <button
                            type="button"
                            className="btn btn-sm btn-skill-del"
                            onClick={() => handleRemoveSkill(skill)}
                          >
                            x
                          </button>
                        </span>
                      </div>
                    ))}
                   
                  </div>
                  <div className="mb-3">
                    <label htmlFor="location" className="form-label">
                      Select Skills
                    </label>

                    <select
                      className="form-select"
                      id="skills"
                      name="skills"
                      value={formData.selectedSkill}
                      onChange={handleInputChange1}
                    >
                      <option value="">Select Skills</option>
                      {skillsmain.map((skill) => (
                        <option key={skill.id} value={JSON.stringify(skill)}>
                          {skill.skillname}
                        </option>
                      ))}
                    </select>
                  </div>

                  {error && (
                    <div className="error-message text-danger">{error}</div>
                  )}
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-light student-profile-btn2"
                  onClick={closePersonlForm2}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary student-profile-btn1"
                  onClick={handleSubmitSkill}
                  disabled={loading}
                >
                  {loading ? (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    "Add"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* showCertificationForm */}
      {showCertificationForm && (
        <div
          className="modal show d-block"
          tabIndex="-1"
          aria-labelledby="myLargeModalLabel"
          style={{ backdropFilter: "brightness(0.5)" }}
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header custom-modal-header">
                <h5 className="modal-title">Certification</h5>
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={closePersonlForm3}
                ></button>
                <p>Add details of Certifications you have achieved/completed</p>
              </div>
              <div className="modal-body">
                <form>
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">
                      Certification name
                    </label>
                    <input
                      type="text"
                      className="form-control form-modal"
                      id="name"
                      value={formData.name}
                      onChange={handleInputChange1}
                      required
                    />
                    {errors.name && (
                      <div className="error text-danger">{errors.name}</div>
                    )}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="provider" className="form-label">
                      Certification provider
                    </label>
                    <input
                      type="text"
                      className="form-control form-modal"
                      id="provider"
                      value={formData.provider}
                      onChange={handleInputChange1}
                      required
                    />
                    {errors.provider && (
                      <div className="error text-danger">{errors.provider}</div>
                    )}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="cID" className="form-label">
                      Certification completion ID{" "}
                      <span className="opt">
                        <i>(optional)</i>
                      </span>
                    </label>
                    <input
                      type="text"
                      className="form-control form-modal"
                      id="cID"
                      value={formData.cID}
                      onChange={handleInputChange1}
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="url" className="form-label">
                      Certification URL
                    </label>
                    <input
                      type="text"
                      className="form-control form-modal"
                      id="url"
                      value={formData.url}
                      onChange={handleInputChange1}
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="courseDuration" className="form-label">
                      Certification validity
                    </label>
                    <div className="row">
                      <div className="col">
                        <input
                          type="date"
                          className="form-control form-modal"
                          id="start_date"
                          value={formData.start_date}
                          onChange={handleInputChange1}
                          placeholder="Start Date"
                        />
                        {errors.start_date && (
                          <div className="error text-danger">
                            {errors.start_date}
                          </div>
                        )}
                      </div>
                      <div className="col">
                        {!formData.expiry && (
                          <input
                            type="date"
                            className="form-control form-modal"
                            id="end_date"
                            value={formData.end_date}
                            onChange={handleInputChange1}
                            placeholder="End Date"
                          />
                        )}
                        {errors.end_date && (
                          <div className="error text-danger">
                            {errors.end_date}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="mb-3 form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="expiry"
                      checked={!!formData.expiry}
                      onChange={handleInputChange1}
                    />
                    <label className="form-check-label" htmlFor="expiry">
                      This certification does not expire
                    </label>
                  </div>

                  <div>
                    <label htmlFor="attachment" className="form-label">
                      <h5>Documents</h5>
                    </label>
                    {isLoading ? (
                      <div>Loading...</div>
                    ) : (
                      file && (
                        <>
                          <div className="pb-2">
                            <a
                              href={file}
                              className="file-link text-decoration-none text-dark"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <h5 className="docul-name">
                                {uploadFile?.originalname}
                              </h5>
                            </a>
                          </div>
                        </>
                      )
                    )}

                    <div className="upload-btn-edu">
                      <input
                        type="file"
                        id="hiddenFileInput"
                        style={{ display: "none" }}
                        onChange={handleFileUpload}
                      />
                      <button
                        type="button"
                        className="btn upload-btn-file"
                        onClick={triggerFileInput}
                      >
                        Upload Document
                      </button>

                      <p className="upl-text">
                        Supported Formats: doc, pdf, up to 2 MB
                      </p>
                    </div>
                  </div>

                  {/* <div className="mb-3">
                    <label htmlFor="description" className="form-label">
                      Certificate Description
                    </label>
                    <textarea
                      className="form-control form-modal"
                      id="description"
                      rows="3"
                    ></textarea>
                  </div> */}
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-light student-profile-btn2"
                  onClick={closePersonlForm3}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary student-profile-btn1"
                  onClick={handleCertificate}
                  disabled={loading}
                >
                  {loading ? (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    "Add"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* showSocialForm */}
      {showSocialForm && (
        <div
          className="modal show d-block"
          tabIndex="-1"
          style={{ backdropFilter: "brightness(0.5)" }}
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header custom-modal-header">
                <h5 className="modal-title">Online profile</h5>
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={closePersonlForm4}
                ></button>
                <p>
                  Add link to online professional profile (e.g LinkedIn, etc.)
                </p>
              </div>
              <div className="modal-body">
                <form>
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">
                      Social profile name
                    </label>
                    <input
                      type="text"
                      className="form-control form-modal"
                      id="name"
                      value={formData.name}
                      onChange={handleInputChange1}
                      required
                    />
                    {errors.name && (
                      <div className="error text-danger">{errors.name}</div>
                    )}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="url" className="form-label">
                      URL
                    </label>
                    <input
                      type="text"
                      className="form-control form-modal"
                      id="url"
                      value={formData.url}
                      onChange={handleInputChange1}
                      required
                    />
                    {errors.url && (
                      <div className="error text-danger">{errors.url}</div>
                    )}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="description" className="form-label">
                      Description{" "}
                      <span className="opt">
                        <i>(optional)</i>
                      </span>
                    </label>
                    <textarea
                      className="form-control form-modal"
                      id="description"
                      rows="4"
                      value={formData.description}
                      onChange={handleInputChange1}
                    ></textarea>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-light student-profile-btn2"
                  onClick={closePersonlForm4}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary student-profile-btn1"
                  onClick={handlesociallinks}
                  disabled={loading}
                >
                  {loading ? (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    "Add"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* For Edit Modal*/}

      {/* Edit Certification */}
      {showEditCertificationForm && (
        <div
          className="modal show d-block"
          tabIndex="-1"
          style={{ backdropFilter: "brightness(0.5)" }}
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Certification</h5>
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={closeCertificateEdit}
                ></button>
              </div>
              <div className="modal-body">
                <form
                  onSubmit={(event) =>
                    handleEditCertificate(event, formData.cId)
                  }
                >
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">
                      Certification name
                    </label>
                    <input
                      type="text"
                      className="form-control form-modal"
                      id="name"
                      value={formData.name}
                      onChange={handleInputChange1}
                      required
                    />
                    {errors.name && (
                      <div className="error text-danger">{errors.name}</div>
                    )}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="provider" className="form-label">
                      Certification provider
                    </label>
                    <input
                      type="text"
                      className="form-control form-modal"
                      id="provider"
                      value={formData.provider}
                      onChange={handleInputChange1}
                      required
                    />
                    {errors.provider && (
                      <div className="error text-danger">{errors.provider}</div>
                    )}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="cID" className="form-label">
                      Certification completion ID{" "}
                      <span className="opt">
                        <i>(optional)</i>
                      </span>
                    </label>
                    <input
                      type="text"
                      className="form-control form-modal"
                      id="cID"
                      value={formData.cID}
                      onChange={handleInputChange1}
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="url" className="form-label">
                      Certification URL
                    </label>
                    <input
                      type="text"
                      className="form-control form-modal"
                      id="url"
                      value={formData.url}
                      onChange={handleInputChange1}
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="courseDuration" className="form-label">
                      Certification validity
                    </label>
                    <div className="row">
                      <div className="col">
                        <input
                          type="date"
                          className="form-control form-modal"
                          id="start_date"
                          value={formData.start_date}
                          onChange={handleInputChange1}
                          placeholder="Start Date"
                        />
                      </div>
                      <div className="col">
                        {!formData.expiry && (
                          <input
                            type="date"
                            className="form-control form-modal"
                            id="end_date"
                            value={formData.end_date || null}
                            onChange={handleInputChange1}
                            placeholder="End Date"
                          />
                        )}
                        {/* {errors.end_date && (
                          <div className="error text-danger">
                            {errors.end_date}
                          </div>
                        )} */}
                      </div>
                    </div>
                  </div>
                  <div className="mb-3 form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="expiry"
                      checked={!!formData.expiry}
                      onChange={handleInputChange1}
                    />
                    <label className="form-check-label" htmlFor="expiry">
                      This certification does not expire
                    </label>
                  </div>

                  {/* <div className="mb-3">
                    <label htmlFor="description" className="form-label">
                      Certificate Description
                    </label>
                    <textarea
                      className="form-control form-modal"
                      id="description"
                      rows="3"
                    ></textarea>
                  </div> */}

                  <div className="mb-3">
                    <label htmlFor="attachment" className="form-label">
                      <h5>Documents</h5>
                    </label>
                    <div>
                      {formData.attachment &&
                      formData.attachment !== "No Data" &&
                      !file ? (
                        <div className="mb-3">
                          <img
                            src={formData.attachment.path}
                            className="mx-auto square-doc-img"
                            alt="No Attachment"
                          />
                          <button
                            className="btn mt-5 mx-2"
                            onClick={handleRemoveAttachment}
                          >
                            <i className="bi bi-trash icn"></i>
                          </button>
                        </div>
                      ) : (
                        <p></p>
                      )}
                    </div>
                    {isLoading ? (
                      <div>Loading...</div>
                    ) : (
                      file && (
                        <>
                          <div className="pb-2">
                            <a
                              href={file}
                              className="file-link text-decoration-none text-dark"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <div className="editEdu-filediv-icon">
                                <h5 className="docul-name">
                                  {uploadFile?.originalname}
                                </h5>
                                <div>
                                  {/* <button className="btn">
                            <i className="bi bi-download icn"></i>
                          </button> */}
                                  {/* <button
                            className="btn"
                            onClick={handleFileRemove}
                          >
                            <i className="bi bi-trash icn"></i>
                          </button> */}
                                </div>
                              </div>
                            </a>
                          </div>
                        </>
                      )
                    )}

                    <div className="upload-btn-edu">
                      <input
                        type="file"
                        id="hiddenFileInput"
                        style={{ display: "none" }}
                        onChange={handleFileUpload}
                      />
                      <button
                        type="button"
                        className="btn upload-btn-file"
                        onClick={triggerFileInput}
                      >
                        Update document
                      </button>

                      <p className="upl-text">
                        Supported Formats: doc, pdf, up to 2 MB
                      </p>
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-light student-profile-btn2"
                  onClick={closeCertificateEdit}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary student-profile-btn1"
                  onClick={(event) =>
                    handleEditCertificate(event, formData.cId)
                  }
                  disabled={loading}
                >
                  {loading ? (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    "Save changes"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Edit Social Media */}
      {showEditSocialForm && (
        <div
          className="modal show d-block"
          tabIndex="-1"
          style={{ backdropFilter: "brightness(0.5)" }}
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header custom-modal-header">
                <h5 className="modal-title">Online profile</h5>
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={closeSocialFormEdit}
                ></button>
                <p>
                  Add link to online professional profile (e.g LinkedIn, etc.)
                </p>
              </div>
              <div className="modal-body">
                <form
                  onSubmit={(event) =>
                    handlEditSocialLinks(event, formData.smId)
                  }
                >
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">
                      Social profile name
                    </label>
                    <input
                      type="text"
                      className="form-control form-modal"
                      id="name"
                      value={formData.name}
                      onChange={handleInputChange1}
                      required
                    />
                    {errors.name && (
                      <div className="error text-danger">{errors.name}</div>
                    )}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="url" className="form-label">
                      URL
                    </label>
                    <input
                      type="text"
                      className="form-control form-modal"
                      id="url"
                      value={formData.url}
                      onChange={handleInputChange1}
                      required
                    />
                    {errors.url && (
                      <div className="error text-danger">{errors.url}</div>
                    )}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="description" className="form-label">
                      Description{" "}
                      <span className="opt">
                        <i>(optional)</i>
                      </span>
                    </label>
                    <textarea
                      className="form-control form-modal"
                      id="description"
                      rows="4"
                      value={formData.description}
                      onChange={handleInputChange1}
                    ></textarea>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-light student-profile-btn2"
                  onClick={closeSocialFormEdit}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary student-profile-btn1"
                  onClick={(event) =>
                    handlEditSocialLinks(event, formData.smId)
                  }
                  disabled={loading}
                >
                  {loading ? (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    "Save changes"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* EducationDetailsForm */}
      {showEditEducationForm && (
        <div
          className="modal show d-block bd-example-modal-lg"
          tabIndex="-1"
          style={{ backdropFilter: "brightness(0.5)" }}
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header custom-modal-header">
                <h5 className="modal-title">Education Details</h5>
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={closePersonFormEdit}
                ></button>
                {/* <p>
                  Edit your education details like course, university, and more
                </p> */}
              </div>
              <div className="modal-body">
                <form
                  onSubmit={(event) => handleSubmit3(event, formData.eduId)}
                >
                  <div className="mb-3">
                    <label htmlFor="education" className="form-label">
                      Education
                    </label>
                    <select
                      className="form-control form-modal"
                      id="education"
                      value={formData.education}
                      onChange={handleInputChange1}
                      required
                    >
                      <option value="">Select Education</option>
                      <option value="Graduation">Graduation</option>
                      <option value="12th Board">12th Board</option>
                      <option value="10th Board">10th Board</option>
                    </select>
                    {errors.education && (
                      <div className="error text-danger">
                        {errors.education}
                      </div>
                    )}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="university" className="form-label">
                      University/Institute
                    </label>
                    <select
                      className="form-control form-modal"
                      id="institute"
                      value={formData.institute}
                      onChange={handleInputChange1}
                      required
                    >
                      <option value="">Select University</option>
                      <option value="Rashtrasant Tukadoji Maharaj Nagpur University">
                        Rashtrasant Tukadoji Maharaj Nagpur University
                      </option>
                      <option value="Sant Gadge Baba Amravati University (SGBAU)">
                        Sant Gadge Baba Amravati University (SGBAU)
                      </option>
                      <option value="other">Other</option>
                    </select>
                    {errors.institute && (
                      <div className="error text-danger">
                        {errors.institute}
                      </div>
                    )}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="course" className="form-label">
                      Course
                    </label>
                    <select
                      className="form-control form-modal"
                      id="course"
                      value={formData.course}
                      onChange={handleInputChange1}
                      required
                    >
                      <option value="">Select Course</option>
                      <option value="Bachelor of Science (B.Sc)">
                        Bachelor of Science (B.Sc)
                      </option>
                      <option value="Bachelor of Arts (B.A)">
                        Bachelor of Arts (B.A)
                      </option>
                      <option value="Bachelor of Commerce (B.Com)">
                        Bachelor of Commerce (B.Com)
                      </option>
                      <option value="other">Other</option>
                    </select>
                    {errors.course && (
                      <div className="error text-danger">{errors.course}</div>
                    )}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="specialization" className="form-label">
                      Specialization
                    </label>
                    <select
                      className="form-control form-modal"
                      id="specialization"
                      value={formData.specialization}
                      onChange={handleInputChange1}
                      required
                    >
                      <option value="">Select Specialization</option>
                      <option value="CS">Computer Science</option>
                      <option value="math">Information Technology</option>
                      <option value="physics">Physics</option>
                      <option value="other">Other</option>
                    </select>
                    {errors.specialization && (
                      <div className="error text-danger">
                        {errors.specialization}
                      </div>
                    )}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="courseDuration" className="form-label">
                      Course Duration
                    </label>
                    <div className="row">
                      <div className="col">
                        <input
                          type="date"
                          className="form-control form-modal"
                          id="start_date"
                          value={formData.start_date}
                          onChange={handleInputChange1}
                          placeholder="Start Date"
                          required
                        />
                        {errors.start_date && (
                          <div className="error text-danger">
                            {errors.start_date}
                          </div>
                        )}
                      </div>
                      <div className="col">
                        <input
                          type="date"
                          className="form-control form-modal"
                          id="end_date"
                          value={formData.end_date}
                          onChange={handleInputChange1}
                          placeholder="End Date"
                          required
                        />
                        {errors.end_date && (
                          <div className="error text-danger">
                            {errors.end_date}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="mb-3">
                    <label htmlFor="gradingSystem" className="form-label">
                      Grading System
                    </label>
                    <select
                      className="form-control form-modal"
                      id="grading_system"
                      value={formData.grading_system}
                      onChange={handleInputChange1}
                      required
                    >
                      <option value="">Select Grading System</option>
                      <option value="Percentage">Percentage</option>
                      <option value="CGPA">CGPA</option>
                      <option value="Pass/Fail">Pass/Fail</option>
                    </select>
                    {errors.grading_system && (
                      <div className="error text-danger">
                        {errors.grading_system}
                      </div>
                    )}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="marks" className="form-label">
                      Marks
                    </label>
                    <input
                      type="text"
                      className="form-control form-modal"
                      id="marks"
                      value={formData.marks}
                      onChange={handleInputChange1}
                      placeholder="Enter Marks"
                      required
                    />
                    {errors.marks && (
                      <div className="error text-danger">{errors.marks}</div>
                    )}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="attachment" className="form-label">
                      <h5>Documents</h5>
                    </label>
                    <div>
                      {formData.attachment &&
                      formData.attachment !== "No Data" &&
                      !file ? (
                        <div className="mb-3">
                          <img
                            src={formData.attachment.path}
                            className="mx-auto square-doc-img"
                            alt="No Attachment"
                          />
                          <button
                            className="btn mt-5 mx-2"
                            onClick={handleRemoveAttachment}
                          >
                            <i className="bi bi-trash icn"></i>
                          </button>
                        </div>
                      ) : (
                        <p></p>
                      )}
                    </div>
                    {isLoading ? (
                      <div>Loading...</div>
                    ) : (
                      file && (
                        <>
                          <div className="pb-2">
                            <a
                              href={file}
                              className="file-link text-decoration-none text-dark"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <div className="editEdu-filediv-icon">
                                <h5 className="docul-name">
                                  {uploadFile?.originalname}
                                </h5>
                                <div>
                                  {/* <button className="btn">
                            <i className="bi bi-download icn"></i>
                          </button> */}
                                  {/* <button
                            className="btn"
                            onClick={handleFileRemove}
                          >
                            <i className="bi bi-trash icn"></i>
                          </button> */}
                                </div>
                              </div>
                            </a>
                          </div>
                        </>
                      )
                    )}

                    <div className="upload-btn-edu">
                      <input
                        type="file"
                        id="hiddenFileInput"
                        style={{ display: "none" }}
                        onChange={handleFileUpload}
                      />
                      <button
                        type="button"
                        className="btn upload-btn-file"
                        onClick={triggerFileInput}
                      >
                        Update document
                      </button>

                      <p className="upl-text">
                        Supported Formats: doc, pdf, up to 2 MB
                      </p>
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-light student-profile-btn2"
                  onClick={closePersonFormEdit}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary student-profile-btn1"
                  onClick={(event) => handleSubmit3(event, formData.eduId)}
                  disabled={loading}
                >
                  {loading ? (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    "Save changes"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Studentprofile;
