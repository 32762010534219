import React, { useEffect, useState } from "react";
import "./index.css";
import { Spinner } from "react-bootstrap";
import { LuMapPin } from "react-icons/lu";
import { GiSquare } from "react-icons/gi";
import nodataimg from "../../assets/nodataimg.png";
import dummylogo from "../../assets/dummylogo.png";
import axios from "axios";

function index() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [visibleCards] = useState(8);
  const baseURL = "https://secure-data-upload-new.s3.ap-south-1.amazonaws.com/";
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [getLiveJobs, setLiveJobs] = useState([]);

  const baseUrl = process.env.REACT_APP_BASE_URL;
  
  // const getAllCompanies = async () => {
  //   setIsLoading(true);
  //   try {
  //     const response = await axios.get(
  //       "http://98.81.204.247/api/company-profiles/getAlllivejobs"
  //     );
  //     setLiveJobs(response?.data?.data);
  //     // console.log(response?.data?.data, "Live Jobs");
  //   } catch (err) {
  //     console.error(err.message);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const getAllCompanies = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${baseUrl}/company-profiles/getAlllivejobs`
      );
      setLiveJobs(response?.data?.data);
      // console.log(response?.data?.data, "Live Jobs");
    } catch (err) {
      console.error("API request failed. Displaying static data instead.", err.message);
  
      // Static data to display if API fails
      const staticData = [
        {
          id: 1,
          user: { company: { compname: "Static Company 1" } },
          designation: "Frontend Developer",
          location: { cityName: "New York" },
          primary_skill: [
            { skillname: "React.js" },
            { skillname: "JavaScript" },
          ],
        },
        {
          id: 2,
          user: { company: { compname: "Static Company 2" } },
          designation: "Backend Developer",
          location: { cityName: "San Francisco" },
          primary_skill: [
            { skillname: "Node.js" },
            { skillname: "MongoDB" },
          ],
        },
        {
          id: 3,
          user: { company: { compname: "Static Company 3" } },
          designation: "Full Stack Developer",
          location: { cityName: "Chicago" },
          primary_skill: [
            { skillname: "React.js" },
            { skillname: "Node.js" },
            { skillname: "MongoDB" },
          ],
        },
      ];
  
      setLiveJobs(staticData); // Set static data when API fails
    } finally {
      setIsLoading(false);
    }
  };
  
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    getAllCompanies();
  }, []);

  return (
    <>
      <section id="my-application" className="my-application container">
        <div className="my-application-title">
          <h2>Job Application Status</h2>
        </div>

        <div className="row skills-card">
          {isLoading ? (
            <div className="col-12 text-center">
              <Spinner animation="border" role="status"></Spinner>
            </div>
          ) : getLiveJobs.length === 0 ? (
            <div className="col-12 text-center">
              <img src={nodataimg} alt="No Data" className="nodata-img" />
              <div className="nodata-text d-flex justify-content-center mt-2">
                <h3>No data found</h3>
              </div>
            </div>
          ) : (
            getLiveJobs.slice(0, visibleCards).map((job, index) => {
              const logoURL =
                job.user && job.user?.logo
                  ? `${baseURL}${job?.user?.logo?.path}`
                  : null;
              return (
                <div
                  key={index}
                  className="col-lg-4 col-md-6 col-sm-12 skills-col-hero"
                >
                  <div className="card">
                    <div className="my-card-sub-div">
                      <div className="avatar">
                        <img
                          src={logoURL || dummylogo}
                          alt={job.user?.companyName?.compname}
                        />
                      </div>
                      <div className="company-description">
                        <h4 className="company-title">
                          {job.user?.company?.compname}
                        </h4>
                        <h6 className="company-designation">
                          {job.designation}
                        </h6>
                        <div className="company-destination">
                          <LuMapPin className="icon" />{" "}
                          <p className="loc-app-status">
                            {job?.location?.cityName}
                          </p>
                        </div>
                        <div className="primary-skills">
                          <GiSquare className="icon" />
                          <p>
                            <span className="skill-text-main">
                              {` Primary Skills: `}
                            </span>

                            {job?.primary_skill?.length > 0 &&
                              job.primary_skill.map((skill, index) => (
                                <span key={index} className="skill-text-span">
                                  {skill?.skillname} ,
                                </span>
                              ))}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="div-success">
                      <span className="d-flex">
                        <i className="bi bi-check-circle-fill div-success-icon"></i>
                        <h5 className="mx-2 div-success-text">
                          Application sent today
                        </h5>
                      </span>
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
        {getLiveJobs.length > 0 && (
          <div className="pagination pagination-listing">
            <button>
              <i className="bi bi-chevron-left"></i>
            </button>
            {Array.from({ length: 1 }, (_, index) => (
              <button key={index} className={1 === index + 1 ? "active" : ""}>
                {index + 1}
              </button>
            ))}
            <button disabled={1 === 1}>
              <i className="bi bi-chevron-right"></i>
            </button>
          </div>
        )}
      </section>
    </>
  );
}

export default index;
