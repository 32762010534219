/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { BiMenu } from "react-icons/bi";
import { HashLink } from "react-router-hash-link";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../../assets/logo/ownprep.png";
import { Button } from "react-bootstrap";
import img from "../../../assets/usergrey.png";
import logowhite from "../../../assets/logo/ownprepwhite.png";
import { useSelector } from "react-redux";
// import axios from "axios";
import "sweetalert2/dist/sweetalert2.min.css";
import Swal from "sweetalert2";
import { useAuth } from "../../../context/AuthContext";

const Header = () => {
  const { logout } = useAuth();
  // const studentData = useSelector((state) => state.user.user);
  const studentDetails = useSelector((state) => state.student.student);
  // const [studentDetails, setStudentDetails] = useState([]);
  // console.log(studentDetails, "studentHeaer");

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [activeLink, setActiveLink] = useState("Home");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const baseUrl = "https://secure-data-upload-new.s3.ap-south-1.amazonaws.com/";

  // const AllstudentDetails = async () => {
  //   const id = localStorage.getItem("id");
  //   const authToken = localStorage.getItem("authToken");
  //   try {
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_BASE_URL}/students/std/${id}`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${authToken}`,
  //         },
  //       }
  //     );
  //     // setStudentDetails(response?.data?.data || {});
  //   } catch (error) {
  //     console.error("Failed to fetch student details:", error.message);
  //   }
  // };
  // useEffect(() => {
  //   const id = localStorage.getItem("id");
  //   const authToken = localStorage.getItem("authToken");

  //   if (id && authToken) {
  //     AllstudentDetails();
  //   }
  // }, [localStorage.getItem("id"), localStorage.getItem("authToken")]);

  // For Profile View
  // useEffect(() => {
  //   const storedToken = localStorage.getItem("authToken");
  //   if (storedToken) {
  //     setToken(storedToken);
  //   }
  // }, []);

  const getToken = () => {
    return localStorage.getItem("authToken");
  };

  const token = getToken();

  useEffect(() => {
    // Update active link based on the current path
    const path = location.pathname;
    const hash = location.hash;
    const link = path + hash;
    setActiveLink(link);
  }, [location]);

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  // For LogOut
  const handleLogout = () => {
    logout();
    navigate("/studentlogin");
  };
  const showLogoutConfirmation = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to log out?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#304461",
      cancelButtonColor: "#DD6B55",
      confirmButtonText: "Yes, logout!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleLogout(); // Call handleLogout on confirmation
        Swal.fire({
          title: "Logged out!",
          text: "You have successfully logged out.",
          icon: "success",
        });
      }
    });
  };

  const toggleMobileMenu = () => setMobileMenuOpen(!mobileMenuOpen);

  const handleLinkClick = (linkName) => {
    setActiveLink(linkName);
    if (mobileMenuOpen) {
      toggleMobileMenu();
    }
    window.scrollTo(0, 0);
  };

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -150;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  useEffect(() => {
    const handleScroll = () => {
      const header = document.getElementById("header");
      if (window.scrollY > 0) {
        header.classList.add("header-blurred");
      } else {
        header.classList.remove("header-blurred");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <header
        id="header"
        className="fixed-top d-flex align-items-center header-transparent"
      >
        <div className="container d-flex align-items-center justify-content-between">
          <div className="logo1">
            <h1>
              <Link
                to="/"
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
              >
                <img src={logo} alt="OwnPrep" />
              </Link>
            </h1>
          </div>
          {/* <div className="mobile-view-profile">
           
          </div> */}
          <nav
            id="navbar"
            className={`navbar ${mobileMenuOpen ? "mobile-nav-open" : ""}`}
          >
            <ul>
              {mobileMenuOpen && (
                <>
                  <div className="logo">
                    <h1>
                      <Link
                        to="/"
                        onClick={() => {
                          window.scrollTo(0, 0);
                        }}
                      >
                        <img src={logowhite} alt="OwnPrep" />
                      </Link>
                    </h1>
                  </div>
                  <hr className="logoline" />
                </>
              )}
              <li>
                <HashLink
                  className={`nav-link scrollto ${
                    activeLink === "/" ? "active" : ""
                  }`}
                  smooth
                  to="/"
                  onClick={() => handleLinkClick("/")}
                >
                  Home
                </HashLink>
              </li>
              <li>
                <Link
                  className={`nav-link scrollto ${
                    activeLink === "/skillboost" ? "active" : ""
                  }`}
                  smooth
                  to="/skillboost"
                  onClick={() => handleLinkClick("/skillboost")}
                  scroll={(el) => scrollWithOffset(el)}
                >
                  Skill Boost
                </Link>
              </li>
              {/* <li>
                <Link
                  className={`nav-link scrollto ${
                    activeLink === "/course" || activeLink === "/coursedetails/:id" ? "active" : ""
                  }`}
                  to="/course"
                  onClick={() => handleLinkClick("/course")}
                >
                  Courses
                </Link>
              </li> */}
              <li>
                <Link
                  className={`nav-link scrollto ${
                    activeLink === "/course" ||
                    /^\/coursedetails\/\d+$/.test(activeLink)
                      ? "active"
                      : ""
                  }`}
                  to="/course"
                  onClick={() => handleLinkClick("/course")}
                >
                  Courses
                </Link>
              </li>

              <li>
                {token ? null : (
                  <>
                    {/* <HashLink
                  className={`nav-link scrollto ${
                    activeLink === "/#" ? "active" : ""
                  }`}
                  smooth
                  to="/aboutcompany"
                  onClick={() => handleLinkClick("/#about")}
                  scroll={(el) => scrollWithOffset(el)}
                >
                  About Us
                </HashLink> */}
                    <Link
                      className={`nav-link scrollto ${
                        activeLink === "/aboutcompany" ? "active" : ""
                      }`}
                      to="/aboutcompany"
                      onClick={() => handleLinkClick("/aboutcompany")}
                    >
                      Employer
                    </Link>
                  </>
                )}
              </li>

              {!token && (
                <li>
                  <Link
                    className={`nav-link scrollto ${
                      activeLink === "/studentlogin" ? "active" : ""
                    }`}
                    to="/studentlogin"
                    onClick={() => handleLinkClick("/studentlogin")}
                  >
                    Student
                  </Link>
                </li>
              )}
              {/* <li>
                <Link to="/free-bootcamp" className="nav-link">
                  <Button className="button">FREE BOOTCAMP</Button>
                </Link>
              </li> */}

              {/* <li>
                <HashLink
                  className={`nav-link scrollto ${
                    activeLink === "/#testimonials" ? "active" : ""
                  }`}
                  smooth
                  to="/#testimonials"
                  onClick={() => handleLinkClick("/#testimonials")}
                  scroll={(el) => scrollWithOffset(el)}
                >
                  Testimonials
                </HashLink>
              </li> */}

              {/* Profile Dropdown*/}
              {!mobileMenuOpen && (
                <>
                  <li>
                    <Link to="/studentlogin" className="nav-link">
                      <Button className="button">FREE BOOTCAMP</Button>
                    </Link>
                  </li>

                  {token && (
                    <li className="nav-item dropdown profile-icon-avtar">
                      <a
                        className="nav-link dropdown-toggle"
                        id="navbarDropdown"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded={dropdownOpen}
                        onClick={toggleDropdown}
                      >
                        <img
                          src={
                            studentDetails?.profilePicture?.path
                              ? `${baseUrl}${studentDetails?.profilePicture?.path}`
                              : img
                          }
                          alt="img"
                          className="rounded-circle"
                          width={60}
                          height={60}
                          style={{ objectFit: "cover" }}
                        />
                      </a>
                      <ul
                        className={`dropdown-menu dropdown-menu-end profile-dropdown ${
                          dropdownOpen ? "show" : ""
                        }`}
                        aria-labelledby="navbarDropdown"
                      >
                        <li
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-evenly",
                          }}
                          className="mx-2"
                        >
                          <img
                            src={
                              studentDetails?.profilePicture?.path
                                ? `${baseUrl}${studentDetails?.profilePicture?.path}`
                                : img
                            }
                            alt="img"
                            className="rounded-circle"
                            width={40}
                            height={40}
                            style={{
                              width: "40px",
                              height: "40px",
                              objectFit: "cover",
                            }}
                          />
                          <div>
                            <p style={{ margin: 0 }}>
                              {studentDetails?.firstName}{" "}
                              {studentDetails?.lastName}
                            </p>
                            <p
                              style={{
                                margin: 0,
                                fontSize: "12px",
                                color: "gray",
                              }}
                            >
                              {studentDetails?.email}
                            </p>
                          </div>
                        </li>

                        <li className="mx-2">
                          <hr className="dropdown-divider" />
                        </li>

                        <li className="mx-2">
                          <Link
                            to="/studentprofile"
                            className={`dropdown-item ${
                              activeLink === "/studentprofile" ? "active" : ""
                            }`}
                          >
                            Profile
                          </Link>
                        </li>
                        <li className="mx-2">
                          <Link
                            to="/mywishlist"
                            className={`dropdown-item ${
                              activeLink === "/mywishlist" ? "active" : ""
                            }`}
                          >
                            My Wishlist
                          </Link>
                        </li>
                        <li className="mx-2">
                          <Link
                            to="/myapplication"
                            className={`dropdown-item ${
                              activeLink === "/myapplication" ? "active" : ""
                            }`}
                          >
                            My Application
                          </Link>
                        </li>

                        <li className="mx-2">
                          <a
                            className="dropdown-item logout"
                            onClick={showLogoutConfirmation}
                          >
                            Logout
                          </a>
                        </li>
                      </ul>
                    </li>
                  )}
                </>
              )}
            </ul>
            <BiMenu className="mobile-nav-toggle" onClick={toggleMobileMenu} />
          </nav>

          <div className="profile-icon-header">
            <li>
              <Link to="/studentlogin" className="nav-link">
                <Button className="button">FREE BOOTCAMP</Button>
              </Link>
            </li>

            {/* Profile Dropdown */}
            {token && (
              <li className="nav-item dropdown profile-icon-avtar">
                <a
                  className="nav-link dropdown-toggle"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded={dropdownOpen}
                  onClick={toggleDropdown}
                >
                  <img
                    src={
                      studentDetails?.profilePicture?.path
                        ? `${baseUrl}${studentDetails?.profilePicture?.path}`
                        : img
                    }
                    alt="img"
                    className="rounded-circle"
                    width={60}
                    height={60}
                    style={{ objectFit: "cover" }}
                  />
                </a>
                <ul
                  className={`dropdown-menu dropdown-menu-end profile-dropdown ${
                    dropdownOpen ? "show" : ""
                  }`}
                  aria-labelledby="navbarDropdown"
                >
                  <li
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-evenly",
                    }}
                    className="mx-2"
                  >
                    <img
                      src={
                        studentDetails?.profilePicture?.path
                          ? `${baseUrl}${studentDetails?.profilePicture?.path}`
                          : img
                      }
                      alt="img"
                      className="rounded-circle"
                      width={40}
                      height={40}
                      style={{
                        width: "40px",
                        height: "40px",
                        objectFit: "cover",
                      }}
                    />
                    <div>
                      <p style={{ margin: 0 }}>
                        {studentDetails?.firstName} {studentDetails?.lastName}
                      </p>
                      <p
                        style={{
                          margin: 0,
                          fontSize: "12px",
                          color: "gray",
                        }}
                      >
                        {studentDetails?.email}
                      </p>
                    </div>
                  </li>

                  <li className="mx-2">
                    <hr className="dropdown-divider" />
                  </li>

                  <li className="mx-2">
                    <Link
                      to="/studentprofile"
                      className={`dropdown-item ${
                        activeLink === "/studentprofile" ? "active" : ""
                      }`}
                    >
                      Profile
                    </Link>
                  </li>

                  <li className="mx-2">
                    <Link
                      to="/mywishlist"
                      className={`dropdown-item ${
                        activeLink === "/mywishlist" ? "active" : ""
                      }`}
                    >
                      My Wishlist
                    </Link>
                  </li>
                  <li className="mx-2">
                    <Link
                      to="/myapplication"
                      className={`dropdown-item ${
                        activeLink === "/myapplication" ? "active" : ""
                      }`}
                    >
                      My Application
                    </Link>
                  </li>

                  <li className="mx-2">
                    <a
                      className="dropdown-item logout"
                      onClick={showLogoutConfirmation}
                    >
                      Logout
                    </a>
                  </li>
                </ul>
              </li>
            )}
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
