import React, { useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
// import img4 from "../../assets/company/capgemini.png";
// import img5 from "../../assets/company/infosys.png";
import { LuMapPin } from "react-icons/lu";
import { GiSquare } from "react-icons/gi";
import { IoIosArrowForward } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import axios from "axios";
import nodataimg from "../../assets/nodataimg.png";
import dummylogo from "../../assets/dummylogo.png";

function Skills() {
  const navigate = useNavigate();
  const baseURL = "https://secure-data-upload-new.s3.ap-south-1.amazonaws.com/";
  const [getLiveJobs, setLiveJobs] = useState([]);
  const [visibleCards] = useState(8);
  const [isLoading, setIsLoading] = useState(false);
  const baseURLAPI = process.env.REACT_APP_BASE_URL;

  // Live Jobs API
  const getAllCompanies = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${baseURLAPI}/company-profiles/getAlllivejobs`
      );
      setLiveJobs(response?.data?.data);
      // console.log(response?.data?.data, "Live Jobs");
    } catch (err) {
      console.error(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getAllCompanies();
  }, []);

  const handleEdit = (job) => {
    console.log(job._id);
    navigate("/aboutjob", { state: { id: job._id } });
  };

  return (
    <>
      <section id="skills" className="skills container">
        <div className="title mt-5">
          <h2>Your Opportunity Spotlight</h2>
        </div>

        <div className="row skills-card">
          {isLoading ? (
            <div className="col-12 text-center">
              <Spinner animation="border" role="status"></Spinner>
            </div>
          ) : getLiveJobs.length === 0 ? (
            <div className="col-12 text-center">
              <img src={nodataimg} alt="No Data" className="nodata-img" />
              <div className="nodata-text d-flex justify-content-center mt-2">
                <h3>No data found</h3>
              </div>
            </div>
          ) : (
            getLiveJobs.slice(0, visibleCards).map((job, index) => {
              const logoURL =
                job.user && job.user?.logo
                  ? `${baseURL}${job?.user?.logo?.path}`
                  : null;
              return (
                <div
                  key={index}
                  className="col-lg-3 col-md-4 col-sm-12 skills-col-hero"
                >
                  <div className="card">
                    <div className="avatar">
                      <img
                        src={logoURL || dummylogo}
                        alt={job.user?.companyName?.compname}
                      />
                    </div>
                    <div className="company-description">
                      <h4 className="company-title">
                        {job.user?.company?.compname}
                      </h4>
                      <h6 className="company-designation">{job.designation}</h6>
                      <div className="company-destination">
                        <LuMapPin className="icon" />{" "}
                        <p>{job?.location?.cityName}</p>
                      </div>
                      <div className="primary-skills">
                        <GiSquare className="icon" />
                        <p>
                          <span className="skill-text-main">
                            {` Primary Skills: `}
                          </span>

                          {(job?.primary_skill?.length > 0 ||
                            job?.secondary_skill?.length > 0 ||
                            job?.tertiary_skill?.length > 0) &&
                            [
                              ...(job.primary_skill || []),
                              ...(job.secondary_skill || []),
                              ...(job.tertiary_skill || []),
                            ].map((skill, index) => (
                              <span key={index} className="skill-text-span">
                                {skill?.skillname} ,
                              </span>
                            ))}
                          {/* {job.primary_skill?.map((skill, index) => (
                              <span key={index} className="mx-1">
                                {skill?.skillname},
                              </span>
                            ))} */}
                        </p>
                      </div>
                    </div>

                    <div className="btn-div">
                      {/* <Link to="/aboutjob">
                        <Button className="button" onClick={()=>handleEdit(job)}>
                          View details <IoIosArrowForward />
                        </Button>
                      </Link> */}
                      <Button
                        className="button"
                        onClick={() => handleEdit(job)}
                      >
                        View details <IoIosArrowForward />
                      </Button>
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>

        {getLiveJobs.length > 0 && (
          <div className="text-center mt-4 mb-4">
            <Link to="/joblisting">
              <Button className="toggle-button">
                View More
                <IoIosArrowForward />
              </Button>
            </Link>
          </div>
        )}
      </section>
    </>
  );
}

export default Skills;
