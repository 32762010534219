import React, { useState } from "react";
import "./index.css";
import Icon1 from "./img/octicon_person-24.png";
import Icon2 from "./img/carbon_email.png";
import Icon4 from "./img/hugeicons_call.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import OtpInput from "react-otp-input";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Login from "./login";
import axios from "axios";
import ChangePass from "./changePass";

function Index() {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [emailExists, setEmailExists] = useState(false);
  const [activeTab, setActiveTab] = useState("login");
  const [isChangePassword, setIsChangePassword] = useState(false);
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    formik.resetForm();
    setIsChangePassword(false);
    setIsSubmitted(false)
  };

  const handleChangePassword = () => {
    setIsChangePassword(true);
  };

  const checkEmailExists = async (email) => {
    try {
      const response = await axios.get(
        `${baseUrl}/users/company?email=${email}`
      );
      // console.log(response.data.exists);
      return response.data.exists;
    } catch (error) {
      console.error("Error checking email existence:", error);
      return false;
    }
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
    },

    validationSchema: Yup.object({
      firstName: Yup.string()
        .test(
          "no-leading-spaces",
          "First name cannot start with a space",
          (value) => !value || value.trimStart() === value
        )
        .min(2, "First name must be at least 2 characters")
        .max(50, "First name must be 50 characters or less")
        .matches(
          /^[A-Za-z\s]*$/,
          "First name can only contain letters and spaces"
        )
        .required("First name is required"),
      lastName: Yup.string()
        .test(
          "no-leading-spaces",
          "Last name cannot start with a space",
          (value) => !value || value.trimStart() === value
        )
        .min(2, "Last name must be at least 2 characters")
        .max(50, "Last name must be 50 characters or less")
        .matches(
          /^[A-Za-z\s]*$/,
          "Last name can only contain letters and spaces"
        )
        .required("Last name is required"),
      email: Yup.string()
        .email("Invalid email address")
        .matches(/^\S*$/, "Email should not contain spaces")
        .matches(
          /^[^@]+@[^@]+\.[a-zA-Z]{2,}$/,
          "Email must contain one '@', a dot ('.'), and at least two letters after the dot"
        )
        .test(
          "single-at-sign",
          "Email should not contain multiple '@' characters",
          (value) => (value.match(/@/g) || []).length <= 1
        )
        .required("Email is required"),
      phone: Yup.string()
        .matches(/^\d+$/, "Phone number must contain only numbers")
        .length(10, "Phone number must be exactly 10 digits")
        .required("Mobile number is required"),
    }),

    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      setEmailExists(false);
      try {
        console.log(values);
        const emailExists = await checkEmailExists(values.email);
        if (emailExists) {
          setEmailExists(true);
          toast.error("Email already exists. Please use a different email.");
          setIsLoading(false);
          return; // Ensure the function exits here if email exists
        }
        const response = await axios.post(
          `${baseUrl}/students/register`,
          values
        );

        // Check if the response indicates the email is already registered
        if (response?.data?.data === "Email is already registered") {
          toast.error(
            "Email is already registered. Please use a different email."
          );
          setIsLoading(false);
          return; // Exit the function if email is already registered
        }
        console.log(response?.data?.data);
        toast.success(response?.data?.data);
        setEmail(values.email);
        setIsSubmitted(true);
        // resetForm();
      } catch (error) {
        toast.error(error.message);
        console.log("There was an error submitting the form!", error);

        if (error.response.data && error.response.status === 409) {
          toast.error("Email already exists. Please use a different email.");
        } else if (
          error.response.data &&
          error.response.data.data === "Email is already registered"
        ) {
          toast.error(
            "Email is already registered. Please use a different email."
          );
        } else {
          const errorMessage =
            error.response?.data?.message ||
            "Error submitting form. Please try again.";
          // toast.error(errorMessage);
        }
      } finally {
        setIsLoading(false);
      }
    },
  });

  const handleOtpChange = (otp) => {
    setOtp(otp);
  };

  // Handle OTP submission
  const handleOtpSubmit = async () => {
    console.log("OTP Submitted:", otp);
    const valuesOtp = {
      email: email,
      otp: otp,
    };
    console.log("Values to be sent:", valuesOtp);
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${baseUrl}/students/verify`,
        valuesOtp
      );
      // console.log(response.data.data,'Login credentials send your mail id');
      toast.success(response.data.data, "Login credentials send your mail id");
      setIsSubmitted(false);
      setOtp("");

      formik.resetForm();
    } catch (error) {
      console.error("Error response:", error.response);
      console.log(error.response.data?.message, "Invalid OTP");
      toast.error(error.response.data?.message || "An error occurred");
      setOtp("");
    } finally {
      setIsLoading(false);
      setOtp("");
    }
  };

  const handleResendOtp = async (e) => {
    e.preventDefault(); 
    e.stopPropagation(); 
    try {
      // registration API also sends the OTP
      const { firstName, lastName, email, phone } = formik.values;
      const response = await axios.post(
        `${baseUrl}/students/register`,
        { 
          firstName,   
          lastName,    
          email,       
          phone      
        }
        
      );
      toast.success("OTP sent successfully. Please check your email.");
      console.log("Resend OTP Response:", response);
    } catch (error) {
      toast.error("Error resending OTP. Please try again.");
      console.error("Error resending OTP:", error);
    } 
  };

  return (
    <React.Fragment>
      <section id="AboutHero" className="AboutHero">
        <div className="container about-hero px-0 py-5">
          <div className="row no-gutters pb-5">
            <div className="col-lg-6 col-md-12 col-sm-12 left-section pt-5">
              <h1 className="px-4 hdl-ac">
                Empower Your Team with Interns from Ownprep
              </h1>
              <p className="pt-3 px-4 sub-ac">
                Connect with Skilled Students Ready to Contribute and Innovate
              </p>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 right-section pt-5">
              <div className="form-container mx-4  px-5">
                {isChangePassword ? (
                  <div>
                    <button
                      className="btn"
                      onClick={() => handleTabClick("login")}
                    >
                      <i class="bi bi-arrow-left"></i>
                    </button>
                    <ChangePass />
                  </div>
                ) : (
                  <>
                    <div className="tab-buttons">
                      <button
                        type="button"
                        className={`btn  switch-button ${
                          activeTab === "login" ? "btn-info" : "btn-light"
                        }`}
                        onClick={() => handleTabClick("login")}
                      >
                        Login
                      </button>
                      <button
                        type="button"
                        className={`btn  switch-button ${
                          activeTab === "register" ? "btn-info" : "btn-light"
                        }`}
                        onClick={() => handleTabClick("register")}
                      >
                        Register
                      </button>
                    </div>

                    {activeTab === "login" && (
                      <Login onChangePassword={handleChangePassword} />
                    )}
                    {activeTab === "register" && (
                      <div className="mt-4">
                        <>
                          {!isSubmitted ? (
                            <>
                              {/* <p className="py-2 form-headline">
                            Provide your contact information below. We'll get in
                            contact
                          </p> */}
                              <form onSubmit={formik.handleSubmit}>
                                <div className="mb-4 input-group input-group-border">
                                  <span className="input-group-text">
                                    <img
                                      src={Icon1}
                                      alt="Mobile Icon"
                                      className="icon-img"
                                    />
                                  </span>
                                  <input
                                    type="text"
                                    className="form-control no-border"
                                    id="firstName"
                                    placeholder="Enter your first name"
                                    value={formik.values.firstName}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    {...formik.getFieldProps("firstName")}
                                  />
                                </div>
                                {formik.touched.firstName &&
                                formik.errors.firstName ? (
                                  <div
                                    className="error mx-3 text-danger"
                                    style={{
                                      marginTop: "-20px",
                                      marginBottom: "5px",
                                    }}
                                  >
                                    {formik.errors.firstName}
                                  </div>
                                ) : null}
                                <div className="mb-4 input-group input-group-border">
                                  <span className="input-group-text">
                                    <img
                                      src={Icon1}
                                      alt="last Name Icon"
                                      className="icon-img"
                                    />
                                  </span>
                                  <input
                                    type="text"
                                    className="form-control no-border"
                                    id="lastName"
                                    placeholder="Enter your last name"
                                    value={formik.values.lastName}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    {...formik.getFieldProps("lastName")}
                                  />
                                </div>
                                {formik.touched.lastName &&
                                formik.errors.lastName ? (
                                  <div
                                    className="error mx-3 text-danger"
                                    style={{
                                      marginTop: "-20px",
                                      marginBottom: "5px",
                                    }}
                                  >
                                    {formik.errors.lastName}
                                  </div>
                                ) : null}
                                <div className="mb-4 input-group input-group-border">
                                  <span className="input-group-text">
                                    <img
                                      src={Icon2}
                                      alt="Email Icon"
                                      className="icon-img"
                                    />
                                  </span>
                                  <input
                                    type="email"
                                    className="form-control no-border"
                                    id="email"
                                    placeholder="Enter your email address"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    {...formik.getFieldProps("email")}
                                  />
                                </div>
                                {formik.touched.email && formik.errors.email ? (
                                  <div
                                    className="error mx-3 text-danger"
                                    style={{
                                      marginTop: "-20px",
                                      marginBottom: "5px",
                                    }}
                                  >
                                    {formik.errors.email}
                                  </div>
                                ) : null}
                                {emailExists && (
                                  <div className="error mx-3 mt-0 text-danger">
                                    Email already exists
                                  </div>
                                )}
                                <div className="mb-1 input-group input-group-border">
                                  <span className="input-group-text">
                                    <img
                                      src={Icon4}
                                      alt="Mobile Icon"
                                      className="icon-img"
                                    />
                                  </span>
                                  <input
                                    type="tel"
                                    className="form-control no-border"
                                    id="phone"
                                    maxLength="10"
                                    placeholder="Enter your mobile number"
                                    value={formik.values.phone}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    {...formik.getFieldProps("phone")}
                                  />
                                </div>
                                {formik.touched.phone && formik.errors.phone ? (
                                  <div className="error mx-3 text-danger">
                                    {formik.errors.phone}
                                  </div>
                                ) : null}
                                <div className="px-4">
                                  <button
                                    type="submit"
                                    className="btn btn-connect full-width-btn"
                                    disabled={isLoading}
                                  >
                                    {isLoading ? (
                                      <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                      ></span>
                                    ) : (
                                      <>
                                        <span>Lets connect</span>{" "}
                                        <i className="bi bi-chevron-compact-right"></i>
                                      </>
                                    )}
                                  </button>
                                </div>
                              </form>
                              
                            </>
                          ) : (
                            <div className="otp-validation">
                              <div>
                                <h4 className="otp-main-title">
                                  Confirm you're you
                                </h4>
                                <p className="otp-main-sub-title">
                                  We sent and email with a verification code to{" "}
                                  <span>
                                    <strong>{email}</strong>
                                  </span>
                                </p>
                              </div>
                              <div>
                                <p className="otp-main-sub-title">
                                  To continue, confirm your identify using the
                                  code below.
                                </p>
                              </div>
                              <div className="otp-title">
                                <h5>Verification Code</h5>
                              </div>
                              <div className="otp-input-container">
                                <OtpInput
                                  className="otp"
                                  value={otp}
                                  onChange={handleOtpChange}
                                  numInputs={4}
                                  renderSeparator={<span>-</span>}
                                  renderInput={(props, index) => (
                                    <input
                                      {...props}
                                      autoFocus={index === 0}
                                      style={{
                                        width: "50px",
                                        height: "50px",
                                        fontSize: "20px",
                                        textAlign: "center",
                                        margin: "0 5px",
                                        border: "2px solid #2D2F80",
                                        borderRadius: "5px",
                                      }}
                                    />
                                  )}
                                />
                              </div>
                              <div className="px-4">
                                <button
                                  type="button"
                                  className="btn btn-validate full-width-btn mb-2"
                                  onClick={handleOtpSubmit}
                                  disabled={isLoading || otp.length === 0} 
                                >
                                  {isLoading ? (
                                    <span>Loading...</span>
                                  ) : (
                                    <span>Verify and continue</span>
                                  )}
                                </button>
                              </div>
                              <button type="button" className="btn recode" onClick={handleResendOtp}>
                                Resend code
                              </button>
                              <hr />
                              <div className="verifi-descr">
                                <p>Didt't get code?</p>
                                <ul>
                                  <li>
                                    Codes can take up 2 minutes to arrive.
                                  </li>
                                  <li>Check your spam folder</li>
                                </ul>
                              </div>
                            </div>
                          )}
                        </>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default Index;
