import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

const AuthGuard = ({ element }) => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();

  console.log("AuthGuard - isAuthenticated:", isAuthenticated);

  // If not authenticated, redirect to login page, otherwise render the component
  return isAuthenticated ? element : <Navigate to="/studentlogin" state={{ from: location }} />;
};

export default AuthGuard;
