// tokenUtils.js
import {jwtDecode} from 'jwt-decode';

/**
 * Checks if the JWT token is expired.
 * @param {string} token - The JWT token.
 * @returns {boolean} - Returns true if the token is valid, false if expired.
 */
export const checkTokenExpiry = (token) => {
  try {
    // Decode the JWT token
    const decodedToken = jwtDecode(token);

    // Get the expiration time from the token
    const expirationTime = decodedToken.exp * 1000; // Convert to milliseconds

    // Get the current time
    const currentTime = Date.now();

    // Check if the token is expired
    if (currentTime >= expirationTime) {
      console.log("Token is expired");
      return false;
    } else {
      console.log("Token is still valid");
      return true;
    }
  } catch (error) {
    console.error("Error decoding token", error);
    return false;
  }
};
