import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext"; // Import AuthProvider
import Header from "./components/layouts/Header/index";
import Footer from "./components/layouts/Footer/index";
import Home from "./pages/Home/index";
import AboutJob from "./pages/AboutJob/index";
import AboutCompany from "./pages/AboutCompany/index";
import Course from "./pages/Course/index";
import SkillBoost from "./pages/SkillBoost/index";
import CourseDetails from "./pages/CourseDetails/index";
import FreeBootCamp from "./pages/FreeBootCamp/index";
import EnrollPage from "./pages/EnrollPage/index";
import PrivacyPolicy from "./pages/PrivacyPolicy/index";
import StudentLogin from "./pages/StudentLogin/index";
import StudentProfile from "./pages/StudentProfile/index";
import JobListing from "./pages/JobListing/index"; 
import MyWishlist from "./pages/MyWishlist/index";
import MyApplication from "./pages/MyApplication/index";
import AuthGuard from "./components/AuthGuard"; // Import your AuthGuard component


function App() {
 
  return (
    <div className="app-container">
      <AuthProvider>
        {/* Wrap your app with AuthProvider */}
        <Router>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/aboutjob" element={<AboutJob />} />
            <Route path="/aboutcompany" element={<AboutCompany />} />
            <Route path="/course" element={<Course />} />
            <Route path="/skillboost" element={<SkillBoost />} />
            <Route path="/free-bootcamp" element={<FreeBootCamp />} />
            <Route path="/coursedetails/:id" element={<CourseDetails />} />
            <Route path="/enrollpage" element={<EnrollPage />} />
            <Route path="/privacy_policy" element={<PrivacyPolicy />} />
            <Route path="/studentlogin" element={<StudentLogin />} />
            <Route path="/joblisting" element={<JobListing />} />
            {/* Protect routes with AuthGuard */}
            <Route
              path="/studentprofile"
              element={<AuthGuard element={<StudentProfile />} />} 
            />
            <Route
              path="/mywishlist"
              element={<AuthGuard element={<MyWishlist />} />}
            />
            <Route
              path="/myapplication"
              element={<AuthGuard element={<MyApplication />} />} 
            />
          </Routes>
          <Footer />
        </Router>
      </AuthProvider>
    </div>
  );
}

export default App;
