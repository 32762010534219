/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "./index.css";
import wishlist from "../../assets/wishlist.png";
import dummyimg from "../../assets/dummylogo.png";
import { useAuth } from "../../context/AuthContext";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import nodataimg from "../../assets/nodataimg.png";

function Wishlist() {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const [job, setJobs] = useState([]);
  const studentId = localStorage.getItem("id");
  const token = localStorage.getItem("authToken");
  const baseURLAPI = process.env.REACT_APP_BASE_URL;
  const baseURL = process.env.REACT_APP_BASE_URL_IMG;
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchJobs = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${baseURLAPI}/wishlists/${studentId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(response, "Wishlist Data");
        setJobs(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching jobs:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchJobs();
  }, []);

  const handleRemoveWishlist = async (jobId) => {
    try {
      console.log("Wishlist", jobId);
      const response = await axios.post(
        `${baseURLAPI}/wishlists/${studentId}/${jobId}`
      );
      toast.success("Job removed from wishlist successfully");
      setJobs((prevJobs) => prevJobs.filter((j) => j._id !== jobId));
      console.log(response, "Wishlist Data");
    } catch (error) {
      console.error(
        "Unable to remove the job from wishlist. Please try again:",
        error
      );
    }
  };

  const handleViewDetails = async (jobId) => {
    try {
      console.log("Wishlist", jobId);
      navigate("/aboutjob", { state: { id: jobId } });
      // const response = await axios.post(`${baseURLAPI}/wishlists/${studentId}/${jobId}`);
      // toast.success("Job removed from wishlist");
      // setJobs(prevJobs => prevJobs.filter(j => j._id !== jobId));
      // console.log(response, "Wishlist Data");
    } catch (error) {
      console.error("Error fetching jobs:", error);
    }
  };

  if (!isAuthenticated) {
    return (
      <div className="not-authenticated">
        <h2>Please log in to view your wishlist.</h2>
      </div>
    );
  }

  return (
    <>
      <section id="mywishlist" className="mywishlist container">
        <div className="wishlist-banner">
          <div className="card wishlist-banner-card">
            <div className="row wishlist-banner-row">
              <div className="col-md-4 col-sm-12">
                <div className="banner-image">
                  <img src={wishlist} alt="Wishlist Banner" />
                </div>
              </div>
              <div className="col-md-8 col-sm-12">
                <div className="banner-content">
                  <div className="banner-content-sub">
                    <p className="title-banner">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Vestibulum mollis nunc a molestie dictum.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="wishlist-job-list">
          <div className="wishlist-job-main-div">
            {isLoading ? (
              <div className="col-12 text-center">
                <Spinner animation="border" role="status"></Spinner>
              </div>
            ) : job.length === 0 ? (
              <div className="col-12 text-center">
                <img src={nodataimg} alt="No Data" className="nodata-img" />
                <div className="nodata-text d-flex justify-content-center mt-2">
                  <h3>No wishlist added</h3>
                </div>
              </div>
            ) : (
              job.map((jobs, index) => (
                <div className="card" key={index}>
                  <div className="card-header">
                    <div className="wishlist-job-image">
                      <img
                        src={
                          jobs?.user?.logo?.path
                            ? `${baseURL}${jobs.user.logo.path}`
                            : dummyimg
                        }
                        alt=""
                      />
                    </div>
                    <div>
                      <button
                        className="btn"
                        onClick={() => {
                          handleRemoveWishlist(jobs?._id);
                        }}
                      >
                        <i className="bi bi-trash button-trash"></i>
                      </button>
                    </div>
                  </div>
                  <div className="card-body1">
                    <div className="description">
                      <h5 className="comname">{jobs?.user?.companyName}</h5>
                      <h6 className="comtitle">{jobs?.designation}</h6>
                    </div>
                    <div className="description-sub row">
                      <div className="col-lg-8 col-md-6 col-sm-12">
                        <div className="loc-skill">
                          <div className="location-wish-main">
                            <i className="bi bi-geo-alt"></i>
                            <p className="location-wish mx-1">
                              {jobs?.location?.cityName}
                            </p>
                          </div>
                          <div className="skills-wish-main">
                            <i className="bi bi-square"></i>
                            <p className="skills-wish">
                              <span className="skill-wish-sub mx-1">
                                Primary skills:
                              </span>
                              <span className="skill-wish-sub-text">
                                {jobs?.primary_skill
                                  .map((skill) => skill.skillname)
                                  .join(", ")}
                              </span>
                            </p>
                          </div>
                          {/* <div className="skills-wish-main">
                          <i className="bi bi-square"></i>
                          <p className="skills-wish">
                            <span className="skill-wish-sub mx-1">
                              Secondary skills:
                            </span>
                            <span className="skill-wish-sub-text">
                            {jobs?.secondary_skill.map(skill => skill.skillname).join(", ")}
                            </span>
                          </p>
                        </div>
                        <div className="skills-wish-main">
                          <i className="bi bi-square"></i>
                          <p className="skills-wish">
                            <span className="skill-wish-sub mx-1">
                              Tertiary skills:
                            </span>
                            <span className="skill-wish-sub-text">
                            {jobs?.tertiary_skill.map(skill => skill.skillname).join(", ")}
                            </span>
                          </p>
                        </div>
                        <div className="skills-wish-main">
                          <i className="bi bi-square"></i>
                          <p className="skills-wish">
                            <span className="skill-wish-sub mx-1">
                              Addon skills:
                            </span>
                            <span className="skill-wish-sub-text">
                            {jobs?.addon_skill.map(skill => skill.skillname).join(", ")}
                            </span>
                          </p>
                        </div> */}
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="but-div">
                          <button
                            className="btn btn-primary btn1"
                            onClick={() => {
                              handleViewDetails(jobs?._id);
                            }}
                          >
                            View Details
                          </button>
                          <button className="btn btn-primary btn2">
                            Apply Now
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </section>
    </>
  );
}

export default Wishlist;
