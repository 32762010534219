import React from "react";
import { Button } from "react-bootstrap";
// import img from "../../../assets/company/raksoftech.png";
import { LuMapPin } from "react-icons/lu";
import { GiSquare } from "react-icons/gi";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "./index.css";
import JobDetails from "../../../JobDetails.json";

function Skills() {

  // console.log(JobDetails, "Job Details");

  return (
    <>
      <section id="featured-internship" className="skills container ">
        <div className="title">
          <h2>Featured Internships</h2>
        </div>

        {/* Fetatured Internship */}
        <div className="skills-card">
          <Swiper
            spaceBetween={10}
            slidesPerView={1}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            breakpoints={{
              640: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 50,
              },
            }}
          >
            {JobDetails.map((skill, index) => (
              <SwiperSlide key={index}>
                <div className="card">
                  <div className="avatar">
                    <img src={skill.imgUrl} alt="Avatar" />
                  </div>
                  <div className="company-description">
                    <h4 className="company-title">{skill.title}</h4>
                    <h6 className="company-designation">{skill.designation}</h6>
                    <div className="company-destination">
                      <LuMapPin className="icon" /><p>{skill.lacation}</p>
                    </div>
                    <div className="primary-skills">
                      <GiSquare className="icon" />
                      <p>
                        Primary skills:
                        <span className="mx-1">{skill.primaryskills}</span>
                      </p>
                    </div>
                  </div>
                  <div className="btn-div">
                    <Link to={`/aboutjob/${skill.id}`}>
                      <Button className="button">
                        View details <IoIosArrowForward />
                      </Button>
                    </Link>
                     {/* <Link to='/about'>
                      <Button className="button">
                        View details <IoIosArrowForward />
                      </Button>
                    </Link> */}
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </section>
    </>
  );
}

export default Skills;
