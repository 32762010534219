import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  student: null,
};

const studentdataSlice = createSlice({
  name: 'student',
  initialState,
  reducers: {
    saveStudentData: (state, action) => {
      state.student = action.payload;
    },
  },
});

export const { saveStudentData } = studentdataSlice.actions;

export default studentdataSlice.reducer;