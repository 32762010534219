import React from 'react'
import ApplicationStatus from '../../components/MyApplication/index'

function Myapplication() {
  return (
    <>
    <ApplicationStatus/>
    </>
  )
}

export default Myapplication