// import React, { createContext, useContext, useState, useEffect } from "react";
// // import { Navigate } from "react-router-dom";


// const AuthContext = createContext();

// export const AuthProvider = ({ children }) => {

//   const [isAuthenticated, setIsAuthenticated] = useState(false);
//   const [token, setToken] = useState(null);
//   const [studentId, setStudentId] = useState(null);

//   useEffect(() => {
//     const storedToken = localStorage.getItem("authToken");
//     const storedStudentId = localStorage.getItem("id");

//     // console.log("Stored Token:", storedToken);
//     // console.log("Stored Student ID:", storedStudentId);

//     if (storedToken && storedStudentId) {
//       setIsAuthenticated(true);
//       setToken(storedToken);
//       setStudentId(storedStudentId);
//     } else{
//       logout();
//     }
//   }, []);

//   const login = (authToken, id) => {
//     setIsAuthenticated(true);
//     setToken(authToken);
//     setStudentId(id);
//     localStorage.setItem("authToken", authToken);
//     localStorage.setItem("id", id);
//   };

//   const logout = () => {
//     setIsAuthenticated(false);
//     setToken(null);
//     setStudentId(null);
//     localStorage.removeItem("authToken");
//     localStorage.removeItem("id");
//   };

//   return (
//     <AuthContext.Provider value={{ isAuthenticated, token, studentId, login, logout }}>
//       {children}
//     </AuthContext.Provider>
//   );
// };

// export const useAuth = () => {
//   return useContext(AuthContext);
// };



// In This check Token also
import React, { createContext, useContext, useState, useEffect } from "react";
import { checkTokenExpiry } from './tokenUtils';  // Import the checkTokenExpiry function

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [token, setToken] = useState(null);
  const [studentId, setStudentId] = useState(null);


  useEffect(() => {
    const storedToken = localStorage.getItem("authToken");
    const storedStudentId = localStorage.getItem("id");

    if (storedToken && storedStudentId) {
      // Use the imported function to check if the token is expired
      if (checkTokenExpiry(storedToken)) {
        setIsAuthenticated(true);
        setToken(storedToken);
        setStudentId(storedStudentId);
      } else {
        logout(); // Log out if the token is expired
      }
    } else {
      logout(); // Log out if no token or studentId is found
    }
  }, []);

  const login = (authToken, id) => {
    setIsAuthenticated(true);
    setToken(authToken);
    setStudentId(id);
    localStorage.setItem("authToken", authToken);
    localStorage.setItem("id", id);
  };

  const logout = () => {
    setIsAuthenticated(false);
    setToken(null);
    setStudentId(null);
    localStorage.removeItem("authToken");
    localStorage.removeItem("id");
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, token, studentId, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
